import { HTMLAttributeAnchorTarget } from 'react'
import Image from '@/components/Image'
import Link from '@/components/Link'
import Icon from '@/components/Icon'
import { useDictionary } from '@/providers'
import { Card, CardHeader, CardBody, Button } from '@material-tailwind/react'

type Props = {
  href: string
  title: string
  target: HTMLAttributeAnchorTarget
  mediaUrl: string
  mediaAlt: string
  className?: string
  overlap?: boolean
  withoutText?: boolean
  withButton?: boolean
}

export default function CategoryCard({
  href,
  title,
  target,
  mediaUrl,
  mediaAlt,
  className = '',
  overlap = false,
  withoutText = false,
  withButton = false,
}: Props) {
  const d = useDictionary()
  return (
    <Card
      color='transparent'
      shadow={false}
      className={`grow rounded-none ${className}`}
    >
      <CardHeader
        floated={false}
        shadow={false}
        color='transparent'
        className={`m-0 rounded-none ${(overlap && 'brightness-50 lg:hover:brightness-100') || ''}`}
      >
        <Link className='block' href={href} title={title} target={target}>
          <Image
            className={`category-card-image w-full ${withoutText ? 'object-contain' : 'object-cover'} object-center`}
            style={{ height: overlap || withoutText ? '150px' : '230px' }}
            src={mediaUrl}
            alt={mediaAlt}
            width={330}
            height={330}
          />
        </Link>
      </CardHeader>
      {!withoutText && (
        <CardBody
          className={`mt-2 p-0 ${
            overlap &&
            'pointer-events-none absolute inset-x-0 top-2/4 mx-auto -translate-y-2/4'
          }`}
        >
          <Link
            href={href}
            title={title}
            target={target}
            className={`line-clamp-2 block w-full text-center text-2xl text-primary ${overlap && 'text-white'} ${
              (withButton && 'mt-2 text-lg font-normal') || 'font-semibold'
            }`}
          >
            {title}
          </Link>
          {withButton && (
            <Button
              className='mx-auto mt-3 flex items-center px-0 py-2 text-lg font-medium capitalize text-primary'
              variant='text'
              aria-label={d('shop_now_btn')}
            >
              {d('shop_now_btn')}
              <Icon size='13px' icon='arrow-right' className='ml-2.5' />
            </Button>
          )}
        </CardBody>
      )}
    </Card>
  )
}
