import Image from '@/components/Image'
import { Button, Typography } from '@material-tailwind/react'
import { FooterComponent } from '@/types'
import Icon from '@/components/Icon'
import IconSvg from '@/components/IconSvg'
import Link from '@/components/Link'
import FakeLink from '@/components/FakeLink'
import { useDictionary, usePage } from '@/providers'
import tailwindConfig from '@/utils/tailwind-config'

type Props = {
  config: FooterComponent.Config
}

export default function Footer({ config }: Props) {
  const { channel, pixelImage } = usePage()
  const d = useDictionary('footer')

  return (
    <footer className='bg-footer-bg text-white'>
      <div className='container flex flex-col justify-center gap-8 py-8 text-center md:flex-row md:flex-nowrap md:justify-between md:py-16 md:text-left xl:gap-16'>
        <div className='flex-1'>
          <div className='relative h-11 w-40'>
            <Image
              className='max-w-none object-contain object-top'
              src={channel?.logoFooter ?? pixelImage}
              alt={d('logo_alt')}
              fill
              sizes={tailwindConfig.theme.width[40]}
            />
          </div>
        </div>
        <div className='flex flex-1 basis-auto flex-wrap justify-between gap-8 xl:gap-16'>
          {config.menuItems.map((menuItem, menuItemKey) => {
            return (
              <div className='flex-1 basis-44' key={menuItemKey}>
                <FakeLink
                  as={'p'}
                  className='mb-2.5 whitespace-nowrap text-2xl font-semibold underline decoration-2 underline-offset-4'
                  href={menuItem.url}
                >
                  {menuItem.title}
                </FakeLink>
                <div className={'flex w-full flex-col gap-y-2.5'}>
                  {menuItem.items.map((item, itemKey) => {
                    const LinkComponent =
                      menuItem.type === 'quickLinks' ? Link : FakeLink
                    return (
                      <LinkComponent
                        key={itemKey}
                        href={item.link}
                        className='text-lg text-secondary hover:text-white'
                        target={item.target}
                      >
                        {item.label}
                      </LinkComponent>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
        <div className='flex-1'>
          <div>
            <Typography
              variant='paragraph'
              className='mb-2.5 text-2xl font-semibold'
            >
              {d('portals_title')}
            </Typography>
            <div className='flex flex-wrap justify-center gap-4 md:grid md:grid-cols-footer-icons md:justify-start'>
              {config.otherPortalUrls.map((portal, portalKey) => (
                <Link
                  key={portalKey}
                  href={portal.url}
                  target='_blank'
                  title={portal.countryName}
                  aria-label={portal.countryName}
                  nofollow={portal.noFollow}
                  noopener={portal.noFollow}
                >
                  <IconSvg
                    icon={portal.countryCode}
                    className='mx-auto h-[27px]'
                    fallback={
                      <Button
                        variant='outlined'
                        color='white'
                        size='sm'
                        className={`h-[27px] px-2 py-0`}
                      >
                        {portal.countryCode}
                      </Button>
                    }
                  />
                </Link>
              ))}
            </div>
          </div>
          <div className='mt-8'>
            <Typography
              variant='paragraph'
              className='mb-2.5 text-lg font-semibold'
            >
              {d('social_media_title')}
            </Typography>
            <div className='flex flex-wrap justify-center gap-x-4 md:grid md:grid-cols-footer-icons md:justify-start'>
              {config.socialFb && (
                <Link
                  href={config.socialFb}
                  target='_blank'
                  aria-label='Facebbok'
                >
                  <Icon icon={'facebook2'} size={'26px'} />
                </Link>
              )}
              {config.socialInstagram && (
                <Link
                  href={config.socialInstagram}
                  target='_blank'
                  aria-label='Instagram'
                >
                  <Icon icon={'instagram'} size={'26px'} />
                </Link>
              )}
              {config.socialPinterest && (
                <Link
                  href={config.socialPinterest}
                  target='_blank'
                  aria-label='Pinterest'
                >
                  <Icon icon={'pinterest'} size={'26px'} />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={`bg-primary p-2.5 text-center`}>
        <span className={'text-[11px] font-normal text-white/[.81]'}>
          {config.copyrightText}
        </span>
      </div>
    </footer>
  )
}
