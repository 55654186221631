import { Properties as PropertiesType } from '@/types'
import FakeLink from '@/components/FakeLink'
import { usePage } from '@/providers'

type Props = {
  options: Array<PropertiesType.PropertyOptions>
}

export default function Color({ options }: Props) {
  const { pages } = usePage()

  return (
    <div className='flex flex-wrap gap-3'>
      {options.map((option, index) => (
        <FakeLink
          href={`/${pages.products}/${option.slug}`}
          key={index}
          className={`block h-6 w-6 border border-light-gray-color${
            option.gradient ? ` bg-gradient-${option.gradient}` : ''
          }`}
          style={{
            backgroundColor: !option.gradient ? option.colorHex : undefined,
          }}
        />
      ))}
    </div>
  )
}
