import {
  Button,
  Dialog as TailwindDialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
  Typography,
} from '@material-tailwind/react'
import Alert from '@/components/Alert'

type Props = {
  title: string
  open: boolean
  loading: boolean
  success: boolean
  description: string
  errors: string
  confirmButtonLabel: string
  cancelButtonLabel: string
  confirmationText: string
  deleteAccount: () => void
  handleOpen: () => void
}

export default function Dialog({
  title,
  open,
  handleOpen,
  description,
  loading,
  success,
  errors,
  confirmButtonLabel,
  cancelButtonLabel,
  confirmationText,
  deleteAccount,
}: Props) {
  const ConfirmationText = ({ title }: { title: string }) => {
    return (
      <Alert state='success' className='mb-6'>
        <Typography variant='paragraph' className='text-lg font-medium'>
          {title}
        </Typography>
      </Alert>
    )
  }

  const OrdinaryText = () => {
    return (
      <div className='mb-6'>
        <Typography variant='paragraph'>{description}</Typography>
        <Typography
          variant='paragraph'
          className='my-4 text-sm font-normal text-tertiary-red'
        >
          {errors}
        </Typography>
      </div>
    )
  }

  return (
    <TailwindDialog open={open} handler={handleOpen} className='rounded-none'>
      <DialogHeader>{title}</DialogHeader>
      <DialogBody>
        {success ? (
          <ConfirmationText title={confirmationText} />
        ) : (
          <OrdinaryText />
        )}
      </DialogBody>
      <DialogFooter>
        <Button
          aria-label={cancelButtonLabel}
          onClick={handleOpen}
          className='mr-1 border border-border-color px-6 py-2 text-base font-medium capitalize text-primary shadow-none'
        >
          {cancelButtonLabel}
        </Button>
        <Button
          aria-label={confirmButtonLabel}
          className='px-6 py-2 text-base font-medium capitalize text-tertiary-red shadow-none'
          onClick={deleteAccount}
          loading={loading}
          disabled={loading}
        >
          {confirmButtonLabel}
        </Button>
      </DialogFooter>
    </TailwindDialog>
  )
}
