import Icon from '@/components/Icon'
import {
  FacebookShareButton,
  PinterestShareButton,
  WhatsappShareButton,
} from 'next-share'

type Props = {
  url?: string
  description?: string
  mediaUrl?: string
}

export default function ShareButtons({
  url,
  description = '',
  mediaUrl = '',
}: Props) {
  const currentUrl =
    (url ?? typeof window !== 'undefined') ? window.location.href : ''

  return (
    <>
      <FacebookShareButton url={currentUrl} quote={description}>
        <Icon className='block' icon='facebook2' size='24px' />
      </FacebookShareButton>
      <PinterestShareButton
        url={currentUrl}
        media={mediaUrl}
        description={description}
      >
        <Icon className='block' icon='pinterest2' size='24px' />
      </PinterestShareButton>
      <WhatsappShareButton url={currentUrl} title={description}>
        <Icon className='block' icon='whatsapp' size='25px' />
      </WhatsappShareButton>
    </>
  )
}
