'use client'

import { Filters } from '@/types'
import Accordion from '@/components/Accordion'
import SearchInput from '@/components/SearchInput'
import { Button, Typography } from '@material-tailwind/react'

import { useDictionary } from '@/providers'
import { OffCanvas } from './OffCanvas'
import FilterLink from './FilterLink'
import { useFilterSearch } from '../use-filter-search'
import { useEffect, useCallback, useState } from 'react'
import HorizontalSelect from '@/components/Filters/components/HorizontalSelect'
import Loader from '@/components/Loader'
import { CustomList, CustomListItem } from '@/components/CustomList'

type Props = {
  title: string
  name: string
  onFilter: Filters.onFilter
  onResetFilter: Filters.onResetFilter
  onShowMoreFilters: Filters.onShowMoreFilters
  mainContentFilter: boolean
  filter: Filters.SelectedData
  items: Array<Filters.FilterItemListItem>
  mobile?: boolean
  horizontal?: boolean
  searchable?: boolean
  urlPosition?: number | null
  selected?: Array<string>
  group?: Filters.Group
}

export default function List({
  title,
  items,
  filter,
  group,
  urlPosition = null,
  name,
  onFilter,
  onShowMoreFilters,
  searchable,
  mobile,
  horizontal = false,
  mainContentFilter = false,
  selected = [],
}: Props) {
  const d = useDictionary('filters')
  const [listItems, setListItems] = useState<Array<Filters.FilterItemListItem>>(
    items || []
  )
  const [loading, setLoading] = useState({
    search: false,
    button: false,
    loadMore: false,
  })
  const [getListItemsSuccess, setGetListItemsSuccess] = useState(false)

  const { filteredData, applyFilterSearch } = useFilterSearch(listItems)
  const [selectedItem, setSelectedItem] = useState<Array<string>>(
    filter?.selected || selected || []
  )
  const [offset, setOffset] = useState(1)

  const _filteredData =
    (horizontal || mobile) && offset * 50 > filteredData.length
      ? filteredData
      : filteredData.slice(0, offset * 50)

  useEffect(() => {
    if (!Object.keys(filter)?.length && selectedItem?.length) {
      setSelectedItem([])
    }
  }, [filter?.selected])

  const handleSelectItem = useCallback(
    (item: Filters.FilterItemListItem) => {
      setSelectedItem([item?.slug])

      onFilter(
        name,
        {
          urlPosition: urlPosition,
          title,
          selected: [item],
        },
        mobile,
        group
      )
    },
    [name, onFilter, urlPosition]
  )

  const handleScroll = (event: any) => {
    const target = event.currentTarget as HTMLDivElement
    const mobileCoef = mobile ? 1 : 0

    if (
      target.scrollHeight - target.scrollTop - mobileCoef <=
      target.clientHeight
    ) {
      const _offset = offset + 1
      setOffset(_offset)
    }
  }

  const handleShowMore = async (type: string) => {
    setLoading({
      ...loading,
      [type]: true,
    })

    const newListItems = await onShowMoreFilters(name)

    const filteredNewListItems = newListItems.filter(
      (item: Filters.FilterItemListItem) =>
        !listItems.some((listItem) => listItem.slug === item.slug)
    )

    const updatedListItems = [...listItems, ...filteredNewListItems]

    setListItems(updatedListItems)
    setGetListItemsSuccess(true)
    setLoading({
      ...loading,
      [type]: false,
    })

    return true
  }

  const handleSearch = async (value: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    mainContentFilter && !getListItemsSuccess
      ? await handleShowMore('search')
      : () => {}
    applyFilterSearch(value)
  }

  const showMore =
    mainContentFilter && listItems?.length <= 0 ? handleShowMore : () => {}

  const renderContent = (
    <div className='relative'>
      {searchable && (
        <div className='mb-3'>
          <SearchInput
            onChange={handleSearch}
            ariaLabel={d('hidden')}
            searchIconSize='12px'
            inputClassName='text-md !px-7 !py-1 mb-3'
            searchIconClassName='!left-1'
            cleaButtonClassName='h-5 w-5'
            disabled={loading.search}
            containerProps={{
              className: 'h-7',
            }}
          />
        </div>
      )}

      <div
        className={`border-t ${!mobile && !horizontal ? 'max-h-[33.375rem] overflow-y-auto' : ''}`}
      >
        {!_filteredData.length ? (
          <Typography variant='lead' className='p-2'>
            {d('not_found')}
          </Typography>
        ) : (
          <CustomList data-nosnippet className='p-0'>
            {_filteredData.map((el, index) => (
              <CustomListItem
                data-nosnippet
                selected={selectedItem?.includes(el.slug)}
                title={el.title || el.value}
                aria-label={el.title || el.value}
                key={index}
                className={`rounded-none px-0.5 py-2 text-primary`}
                onClick={() => handleSelectItem(el)}
              >
                <FilterLink
                  fake={!el.indexable}
                  href={el.url}
                  title={el?.title || el?.value}
                  ariaLabel={el?.title || el?.value}
                  className='pointer-events-none'
                >
                  {el.value || el.title}
                </FilterLink>
              </CustomListItem>
            ))}
          </CustomList>
        )}
      </div>

      {mainContentFilter && !getListItemsSuccess && (
        <Button
          loading={loading.button}
          className='mt-4 flex h-8 w-full items-center justify-center border px-2 py-1 text-center text-sm font-medium capitalize text-secondary !shadow-none'
          onClick={() => handleShowMore('button')}
          aria-label={d('more_filters')}
        >
          {d('more_filters')}
        </Button>
      )}

      <Loader
        loading={loading.search}
        className='absolute bg-transparent backdrop-blur-sm'
        iconClassName='!fill-black'
      />
    </div>
  )

  if (mobile) {
    return (
      <OffCanvas
        title={title}
        isSelected={!!selectedItem?.length}
        onClick={() => showMore('loadMore')}
        loading={loading.loadMore}
      >
        {renderContent}
      </OffCanvas>
    )
  }

  if (horizontal) {
    return (
      <HorizontalSelect
        title={title}
        isSelected={!!selectedItem?.length}
        handleScroll={handleScroll}
        onClick={() => showMore('loadMore')}
        loading={loading.loadMore}
      >
        {renderContent}
      </HorizontalSelect>
    )
  }

  return (
    <Accordion
      active={!!selectedItem?.length || !!items?.length}
      title={title}
      icon='plus'
      collapsedIcon='minus'
      className='m-0 border px-3'
      headerClassName='text-base border-0'
      onClick={() => showMore('loadMore')}
      loading={loading.loadMore}
    >
      {renderContent}
    </Accordion>
  )
}
