import { BlogListingComponent } from '@/types'
import BlogListing from '@/containers/sections/blog-listing'

export default function generate({
  component,
}: {
  component: BlogListingComponent.Component
}) {
  const { blogs, count, limit, page, displayPagination, breadcrumb } =
    component.config

  return (
    <BlogListing
      blogs={blogs}
      count={count}
      limit={limit}
      page={page}
      displayPagination={displayPagination}
      breadcrumb={breadcrumb}
    />
  )
}
