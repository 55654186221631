import { VerifyAccountComponent } from '@/types'
import VerifyAccount from '@/containers/sections/verify-account'

export default function generate({
  component,
}: {
  component: VerifyAccountComponent.Component
}) {
  return <VerifyAccount errors={component.config.errors} />
}
