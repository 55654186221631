'use client'

import React, { useState } from 'react'
import { Button } from '@material-tailwind/react'

import Alert from '@/components/Alert'
import { useDictionary } from '@/providers'
import FormProvider, {
  RHFTextarea,
  RHFTextField,
  useForm,
  Yup,
  yupResolver,
} from '@/components/HookForm'
import SuccessMessage from '@/components/Auth/SuccessMessage'
import { sendContactUs } from '@/actions/contact-us'

export default function ContactForm() {
  const d = useDictionary('contact_form')

  const [errorMsg, setErrorMsg] = useState('')

  const [success, setSuccess] = useState<boolean>(false)

  const ContactSchema = Yup.object().shape({
    name: Yup.string().required(d('name_required')),
    email: Yup.string().required(d('email_required')).email(d('email_invalid')),
    subject: Yup.string().required(d('subject_required')),
    message: Yup.string().required(d('message_required')),
  })

  const defaultValues = {
    name: '',
    email: '',
    subject: '',
    message: '',
  }

  const methods = useForm({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = handleSubmit(async (data) => {
    const res = await sendContactUs(data)

    if (res?.success) {
      reset()
      setSuccess(true)
      setErrorMsg('')
    } else {
      setErrorMsg(res?.data?.errors[0])
      setSuccess(false)
    }
  })

  if (success) {
    return (
      <SuccessMessage
        icon='envelope-open'
        title={d('success_title')}
        description={d('success_description')}
        className='w-full'
      />
    )
  }

  return (
    <div className='w-full'>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        {errorMsg && (
          <Alert state='error' className='mb-4'>
            {errorMsg}
          </Alert>
        )}
        <div className='grid gap-4'>
          <RHFTextField
            name='name'
            variant='static'
            placeholder={d('name_input_placeholder')}
            label={d('name_input')}
          />
          <RHFTextField
            name='email'
            variant='static'
            placeholder={d('email_input_placeholder')}
            label={d('email_input')}
          />
          <RHFTextField
            name='subject'
            variant='static'
            placeholder={d('subject_input_placeholder')}
            label={d('subject_input')}
          />
          <RHFTextarea
            name='message'
            variant='static'
            placeholder={d('message_input_placeholder')}
            label={d('message_input')}
          />
        </div>
        <div className='flex justify-end'>
          <Button
            fullWidth
            type='submit'
            loading={isSubmitting}
            className='mb-2 mt-4 flex w-auto items-center justify-center bg-primary p-2 px-4 text-lg font-medium capitalize text-white !shadow-none'
          >
            {d('send_btn')}
          </Button>
        </div>
      </FormProvider>
    </div>
  )
}
