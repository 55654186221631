import { InternalLinkComponent } from '@/types'
import InternalLink from '@/containers/sections/internal-link'

export default function generate({
  component,
}: {
  component: InternalLinkComponent.Component
}) {
  return (
    <InternalLink
      title={component.config.title}
      links={component.config.links}
    />
  )
}
