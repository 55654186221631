import { Typography } from '@material-tailwind/react'
import { Filters as FilterTypes } from '@/types'
import Filters from '@/components/Filters'
import CategoryModal from '@/components/Filters/components/category-modal'

type Props = {
  components: Array<FilterTypes.CombinationFilterItem>
  onFilter: FilterTypes.onFilter
  filters: Record<string, any>
  filterCount: FilterTypes.FilterCount
  onApplyFilters?: () => void
  onResetFilter?: FilterTypes.onResetFilter
  onResetAllFilter?: FilterTypes.onResetAllFilter
  onShowMoreFilters?: FilterTypes.onShowMoreFilters
  className?: string
}

export function Combination({
  components,
  onFilter,
  filters,
  filterCount,
  onResetFilter = () => {},
  onApplyFilters = () => {},
  onResetAllFilter = () => {},
  onShowMoreFilters = () => {},
}: Props) {
  return (
    <>
      {components?.map((item, key) => {
        const mobileFilters = item?.filters?.filter(
          (el) => el?.type !== FilterTypes.Type.CATEGORY_MODAL
        )
        const mobileCategory = item?.filters?.filter(
          (el) => el?.type === FilterTypes.Type.CATEGORY_MODAL
        )
        const firstMobileCategory =
          mobileCategory && mobileCategory.length > 0 ? mobileCategory[0] : null

        const selectedFilters =
          item.group && item.group?.name ? filters[item.group?.name] || {} : {}
        const selectedFiltersCount =
          item.group && item.group?.name
            ? filterCount.groups[item.group?.name]
            : 0

        return (
          <div key={key} className='mb-2 w-full'>
            <Typography
              as='p'
              variant='h6'
              className='mb-2 mt-4 text-left text-xl font-semibold text-primary'
            >
              {item.title}
            </Typography>
            <div className='flex items-center gap-4'>
              <Filters
                variant='horizontal'
                group={item.group}
                components={item?.filters || []}
                filters={selectedFilters}
                filterCount={selectedFiltersCount}
                onFilter={onFilter}
                onResetFilter={onResetFilter}
                onResetAllFilter={onResetAllFilter}
                onShowMoreFilters={onShowMoreFilters}
                className='hidden lg:flex'
              />
            </div>
            <div className='flex items-center justify-between lg:hidden'>
              {firstMobileCategory && (
                <CategoryModal
                  title={firstMobileCategory?.title as string}
                  name={firstMobileCategory?.name as string}
                  selected={firstMobileCategory?.selected}
                  filter={selectedFilters[firstMobileCategory?.name as string]}
                  onFilter={onFilter}
                  urlPosition={firstMobileCategory.urlPosition as number}
                  onResetFilter={onResetFilter}
                  group={item.group}
                  mobile
                />
              )}

              <Filters
                variant='mobile'
                components={mobileFilters || []}
                group={item.group}
                filters={selectedFilters}
                filterCount={selectedFiltersCount}
                onFilter={onFilter}
                onApplyFilters={onApplyFilters}
                onResetFilter={onResetFilter}
                onResetAllFilter={onResetAllFilter}
                onShowMoreFilters={onShowMoreFilters}
                className='lg:hidden'
              />
            </div>
          </div>
        )
      })}
    </>
  )
}
