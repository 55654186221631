import Icon from '@/components/Icon'
import { Typography } from '@material-tailwind/react'
import { usePage } from '@/providers'

type Props = {
  text: string
  title: string
}

export default function Error404({ text, title }: Props) {
  const { errorSlugs } = usePage()

  return (
    <section className='container my-12'>
      {errorSlugs && (
        <input
          type='hidden'
          id='error-slugs'
          value={JSON.stringify(errorSlugs)}
        />
      )}
      <Icon className='block' size='120px' icon='page-not-found' />
      <div className='my-4'>
        <Typography as='p' variant='h1'>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </section>
  )
}
