import { StaticTextComponent } from '@/types'

export default function StaticDescription({
  content,
}: StaticTextComponent.Config) {
  return (
    <section
      className={`static-description container my-12 text-left`}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}
