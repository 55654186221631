'use client'

import { signIn } from 'next-auth/react'

import { useDictionary } from '@/providers'
import ButtonWithIcon from '@/components/ButtonWithIcon'
import { useState } from 'react'
import Loader from '@/components/Loader'

export default function Social() {
  const d = useDictionary('auth')
  const [loading, setLoading] = useState(false)

  const login = async (provider: string) => {
    setLoading(true)
    await signIn(provider, { callbackUrl: '/' })
  }

  return (
    <div className='grid gap-4'>
      <Loader
        className='z-[999] bg-transparent backdrop-blur-sm'
        iconClassName='!fill-black'
        loading={loading}
      />
      <ButtonWithIcon
        onClick={() => login('google')}
        label={d('sign_in_google_btn')}
        icon='google'
      />
      <ButtonWithIcon
        onClick={() => login('facebook')}
        label={d('sign_in_fb_btn')}
        icon='facebook'
      />
    </div>
  )
}
