import { StaticPageComponent } from '@/types'
import Sidebar from '@/components/Sidebar'

type Props = {
  content: string
  sidebar: Array<StaticPageComponent.SidebarItem>
}

export default function StaticPage({ content, sidebar }: Props) {
  return (
    <section className='container mb-12 gap-20 md:flex md:flex-row'>
      {sidebar?.length > 0 && <Sidebar elements={sidebar} />}
      <div
        dangerouslySetInnerHTML={{ __html: content }}
        className='break-words text-left'
      />
    </section>
  )
}
