import { CombinationFilterComponent } from '@/types'
import CombinationFilter from '@/containers/sections/combination-filter'

export default function generate({
  component,
}: {
  component: CombinationFilterComponent.Component
}) {
  return (
    <CombinationFilter
      componentId={component.id}
      combination={component.config.combination}
      combinationLevel2={component.config.combinationLevel2}
      facets={component.config.filter}
    />
  )
}
