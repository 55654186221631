import { ReactNode } from 'react'

import FakeLink from '@/components/FakeLink'
import Link from '@/components/Link'

type Props = {
  fake: boolean
  href: string
  title: string
  ariaLabel: string
  children: ReactNode
  className?: string
}

export default function FilterLink({
  fake,
  href,
  title,
  children,
  ariaLabel,
  className = '',
}: Props) {
  const LinkComponent = fake ? FakeLink : Link

  return (
    <LinkComponent
      href={href}
      title={title}
      aria-label={ariaLabel}
      className={className}
    >
      {children}
    </LinkComponent>
  )
}
