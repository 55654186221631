import { OutfitDetailComponent } from '@/types'
import OutfitDetail from '@/containers/sections/outfit-detail'

export default function generate({
  component,
}: {
  component: OutfitDetailComponent.Component
}) {
  return (
    <OutfitDetail
      outfit={component.config.outfit}
      similarOutfits={component.config.similarOutfits}
      breadcrumb={component.config.breadcrumb}
      lastSeenLimit={component.config.lastSeenLimit}
    />
  )
}
