import { ProductDetailComponent } from '@/types'
import ProductDetail from '@/containers/sections/product-detail'

export default function generate({
  component,
}: {
  component: ProductDetailComponent.Component
}) {
  return (
    <ProductDetail
      product={component.config.product}
      similarProducts={component.config.similarProducts}
      outfits={component.config.outfits}
      breadcrumb={component.config.breadcrumb}
      lastSeenLimit={component.config.lastSeenLimit}
    />
  )
}
