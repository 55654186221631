import { Filters } from '@/types'
import Checkbox from '@/components/Filters/components/checkbox'

type Props = {
  component: Filters.FilterItem
  onFilter: Filters.onFilter
  filter: Filters.SelectedData
  group?: Filters.Group
  onResetFilter: Filters.onResetFilter
  mobile?: boolean
  horizontal?: boolean
}

export default function generate({
  component,
  onFilter,
  onResetFilter,
  filter,
  mobile = false,
  group,
}: Props) {
  return (
    <Checkbox
      title={component.title}
      name={component.name}
      selected={component.selected}
      filter={filter}
      onFilter={onFilter}
      onResetFilter={onResetFilter}
      mobile={mobile}
      group={group}
    />
  )
}
