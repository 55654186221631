import { Filters } from '@/types'
import CategoryModal from '@/components/Filters/components/category-modal'

export default function generate({
  component,
  onFilter,
  onResetFilter,
  filter,
  mobile = false,
  group,
}: {
  component: Filters.FilterItem
  onFilter: Filters.onFilter
  filter: Filters.SelectedData
  group?: Filters.Group
  onResetFilter: Filters.onResetFilter
  mobile?: boolean
  horizontal?: boolean
}) {
  return (
    <CategoryModal
      title={component.title}
      name={component.name}
      selected={component.selected}
      filter={filter}
      onFilter={onFilter}
      onResetFilter={onResetFilter}
      urlPosition={component.urlPosition}
      mobile={mobile}
      group={group}
    />
  )
}
