import { Fragment } from 'react'
import { preload } from 'react-dom'
import { Typography } from '@material-tailwind/react'
import {
  Outfit,
  Breadcrumb,
  Filters as Facets,
  Suggestions as SuggestionsType,
  Thumbnails,
  PictureSources,
  Media,
} from '@/types'
import { usePage, useDictionary } from '@/providers'
import Breadcrumbs from '@/components/Breadcrumbs'
import OutfitCard from '@/components/OutfitCard'
import {
  CombinationFilter,
  useFilters,
  generateFilterInitialStateByGroups,
} from '@/components/Filters'
import Suggestions from '@/components/Suggestions'
import Pagination from '@/components/Pagination'
import StaticDescription from '@/components/StaticDescription'

type Props = {
  componentId: number
  combination: string
  combinationLevel2: string
  combinations: Array<Outfit.Outfit>
  count: number
  limit: number
  displayFilter: boolean
  displayPagination: boolean
  breadcrumb: Array<Breadcrumb>
  facets: Facets.Facets
  mainContentSuggestions: Array<SuggestionsType.Suggestions>
  suggestions: SuggestionsType.Suggestions
  page?: number
  description?: string
}

export default function CombinationListing({
  componentId,
  combination,
  combinationLevel2,
  combinations,
  count,
  limit,
  page,
  displayPagination,
  displayFilter,
  breadcrumb,
  facets,
  suggestions,
  mainContentSuggestions,
  description,
}: Props) {
  const d = useDictionary('combination_listing')
  const { pages, metaInformation } = usePage()
  const initialFilterState = generateFilterInitialStateByGroups({
    [combination]: facets?.level1,
    [combinationLevel2]: facets?.level2,
  })

  const {
    filters,
    onFilter,
    resetFilter,
    resetAllFilters,
    applyFilters,
    filterCount,
    showMoreFilters,
  } = useFilters(
    initialFilterState,
    componentId,
    pages,
    [combination, combinationLevel2],
    {
      [combination]: 0,
      [combinationLevel2]: 1,
    }
  )

  const getSuggestionIndex = (i: number): number => Math.ceil((i + 1) / 6) - 1

  const buildMediaUrl = (media?: Media): string => {
    if (media === undefined) {
      return ''
    }

    if (
      media.thumbnails === undefined ||
      !('750x750' in media.thumbnails) ||
      !('490x490' in media.thumbnails) ||
      !('330x330' in media.thumbnails)
    ) {
      return media.imageUrl
    }

    return media.thumbnails['490x490']
  }

  const buildMediaSources = (
    thumbnails?: Thumbnails
  ): PictureSources | undefined => {
    if (
      thumbnails === undefined ||
      !('750x750' in thumbnails) ||
      !('490x490' in thumbnails) ||
      !('330x330' in thumbnails)
    ) {
      return
    }

    return {
      '420': {
        src: thumbnails['330x330'],
        size: 330,
      },
      '520': {
        src: thumbnails['490x490'],
        size: 490,
      },
      '767': {
        src: thumbnails['750x750'],
        size: 750,
      },
    }
  }

  if (combinations?.length > 0) {
    const firstImageUrl = buildMediaUrl(combinations[0].media)
    if (firstImageUrl !== '') {
      preload(firstImageUrl, {
        as: 'image',
        fetchPriority: 'high',
      })
    }
  }

  return (
    <section className='container mb-8 overflow-hidden'>
      <Breadcrumbs breadcrumbs={breadcrumb} />
      <Typography
        variant='h1'
        className='mb-2 mt-4 text-left text-2xl font-semibold text-primary md:mb-8'
      >
        {metaInformation?.h1 || d('title')}
      </Typography>
      <div className='mb-2 flex items-center justify-between'>
        <p
          id='listing-result-count'
          data-value={count}
          className='py-1.5 text-left text-sm text-secondary'
        >
          {d('items', { count: `${count}` })}
        </p>
      </div>
      {suggestions?.links?.length > 0 && (
        <Suggestions list={suggestions.links} title={suggestions.groupTitle} />
      )}
      <div className='w-full'>
        {displayFilter && (
          <CombinationFilter
            components={[
              {
                title: d('filter_group.level_1_title'),
                filters: facets?.level1,
                group: {
                  name: combination,
                  position: 0,
                  categoryPopupTitle: d('category_popup_title.level_1'),
                },
              },
              {
                title: d('filter_group.level_2_title'),
                filters: facets?.level2,
                group: {
                  name: combinationLevel2,
                  position: 1,
                  categoryPopupTitle: d('category_popup_title.level_2'),
                },
              },
            ]}
            filters={filters}
            filterCount={filterCount}
            onFilter={onFilter}
            onResetFilter={resetFilter}
            onResetAllFilter={resetAllFilters}
            onApplyFilters={applyFilters}
            onShowMoreFilters={showMoreFilters}
          />
        )}
        <div className='mt-4 grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3'>
          {(combinations?.length > 0 &&
            combinations.map((item: Outfit.Outfit, index: number) => (
              <Fragment key={index}>
                <OutfitCard
                  key={index}
                  id={item.id}
                  mediaUrl={buildMediaUrl(item.media)}
                  mediaAlt={item.generatedDescription ?? item.name}
                  mediaBucket={item.media?.bucket}
                  mediaKey={item.media?.key}
                  title={item.generatedDescription ?? item.name}
                  products={item.products}
                  badges={item?.badges}
                  slug={item.slug}
                  subType={item.subType}
                  className='max-w-full'
                  imageProps={{
                    priority: index === 0,
                    loading: index === 0 ? 'eager' : 'lazy',
                    sources: buildMediaSources(item.media?.thumbnails),
                  }}
                />
                {((index + 1) % 6 === 0 || index === combinations.length - 1) &&
                  mainContentSuggestions[getSuggestionIndex(index)] !==
                    undefined && (
                    <Suggestions
                      mainContent={true}
                      list={
                        mainContentSuggestions[getSuggestionIndex(index)]?.links
                      }
                      title={
                        mainContentSuggestions[getSuggestionIndex(index)]
                          ?.groupTitle
                      }
                      className='col-span-1 md:col-span-2 lg:col-span-3'
                    />
                  )}
              </Fragment>
            ))) ||
            d('no_combination_found')}
        </div>
        {displayPagination && (
          <Pagination
            activePage={page ?? 1}
            totalItemCount={count}
            itemsPerPage={limit}
          />
        )}
      </div>
      {description && <StaticDescription content={description} />}
    </section>
  )
}
