import { Button, Typography } from '@material-tailwind/react'
import Alert from '@/components/Alert'
import { useDictionary } from '@/providers'
import FormProvider, {
  RHFPasswordField,
  useForm,
  Yup,
  yupResolver,
  PASSWORD_REG_EXP,
} from '@/components/HookForm'

type Params = {
  password: string
  newPassword: string
  confirmNewPassword: string
}

type Props = {
  loading: boolean
  error: string
  formFunction: (params: Params) => void
}

export default function LoginForm({ formFunction, loading, error }: Props) {
  const d = useDictionary('profile.settings')

  const EditProfileSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required(d('password_required'))
      .matches(PASSWORD_REG_EXP, d('invalid_password')),
    newPassword: Yup.string()
      .required(d('password_required'))
      .matches(PASSWORD_REG_EXP, d('invalid_password')),
    confirmPassword: Yup.string()
      .required(d('confirm_password_required'))
      .oneOf([Yup.ref('newPassword')], d('invalid_confirm_password')),
  })

  const defaultValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  }

  const methods = useForm({
    resolver: yupResolver(EditProfileSchema),
    defaultValues,
  })

  const { reset, handleSubmit } = methods

  const onSubmit = handleSubmit((data) => {
    const params = {
      password: data.currentPassword,
      newPassword: data.newPassword,
      confirmNewPassword: data.confirmPassword,
    }
    formFunction(params)
    reset()
  })

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Typography
        variant='paragraph'
        className='mb-6 ml-4 mt-1 mt-12 text-left text-lg font-semibold text-primary'
      >
        {d('password_change')}
      </Typography>
      {error && (
        <Alert state='error' className='mb-4 mt-2'>
          {error}
        </Alert>
      )}
      <div className='grid gap-4'>
        <RHFPasswordField
          name='currentPassword'
          variant='static'
          placeholder='********'
          label={d('current_pass_label')}
        />
        <RHFPasswordField
          name='newPassword'
          variant='static'
          showStrength
          placeholder='********'
          label={d('new_pass_label')}
          showStrengthClassName='mb-0'
        />
        <RHFPasswordField
          name='confirmPassword'
          variant='static'
          showStrength
          placeholder='********'
          label={d('repeat_new_pass_label')}
          showStrengthClassName='mb-0'
        />
      </div>
      <Button
        fullWidth
        type='submit'
        loading={loading}
        className='mb-2 mt-4 flex w-full items-center justify-center bg-primary p-2 text-lg font-medium capitalize text-white !shadow-none'
      >
        {d('save_changes')}
      </Button>
    </FormProvider>
  )
}
