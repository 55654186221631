import { ProductListingComponent } from '@/types'
import ProductListing from '@/containers/sections/product-listing'

export default function generate({
  component,
}: {
  component: ProductListingComponent.Component
}) {
  const {
    products,
    count,
    limit,
    page,
    displayFilter,
    displayPagination,
    breadcrumb,
    facets,
    suggestions,
    mainContent,
    mainContentOnTop,
    description,
  } = component.config

  return (
    <ProductListing
      componentId={component.id}
      products={products}
      count={count}
      limit={limit}
      page={page}
      displayFilter={displayFilter}
      displayPagination={displayPagination}
      breadcrumb={breadcrumb}
      suggestions={suggestions}
      mainContentSuggestions={mainContent}
      facets={facets}
      mainContentOnTop={mainContentOnTop || []}
      description={description || ''}
    />
  )
}
