'use client'

import React, { useState, useEffect } from 'react'
import { SliderProps } from '@/components/Slider'
import SimilarProducts from '@/components/SimilarProducts'
import { fetchSimilarProducts } from '@/utils/actions/product'
import { breakpoints } from '@/components/Slider'
import { Product } from '@/types'
import { TrackingExternalUrlSpecificData } from '@/components/NgTracker'

export type Props = {
  id: number
  componentTrackingSpecificData?: TrackingExternalUrlSpecificData | undefined
}

export default function Similars({
  id,
  componentTrackingSpecificData = {},
}: Props) {
  const sliderProps: SliderProps = {
    slidesPerView: 2.5,
    breakpoints: {
      [breakpoints.sm]: {
        slidesPerView: 2.5,
      },
      [breakpoints.md]: {
        slidesPerView: 4.2,
      },
      [breakpoints.lg]: {
        slidesPerView: 4.5,
      },
      [breakpoints.xl]: {
        slidesPerView: 4.5,
      },
      [breakpoints.xxl]: {
        slidesPerView: 4.5,
      },
    },
  }

  const [similarProducts, setSimilarProducts] =
    useState<Product.SimilarProducts | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const getSimilarProducts = async () => {
    setLoading(true)

    try {
      const { data } = await fetchSimilarProducts(id)
      setSimilarProducts(data)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      /* empty */
    }
    setLoading(false)
  }

  useEffect(() => {
    getSimilarProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SimilarProducts
      loading={loading}
      count={similarProducts?.count}
      products={similarProducts?.products}
      sliderProps={sliderProps}
      componentTrackingSpecificData={componentTrackingSpecificData}
    />
  )
}
