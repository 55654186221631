'use client'

import { usePage, useDictionary } from '@/providers'
import FavoriteCard from '@/components/FavoriteCard'
import { FavoriteComponent } from '@/types'
import { Typography } from '@material-tailwind/react'

type Props = {
  outfits: FavoriteComponent.CardDetail
  products: FavoriteComponent.CardDetail
}

export default function Favorite({ outfits, products }: Props) {
  const d = useDictionary('favorite')

  const { pages } = usePage()

  return (
    <section className='container py-4 2xl:py-8'>
      <Typography
        variant='paragraph'
        className='text-left text-2xl font-semibold'
      >
        {d('title')}
      </Typography>
      <div className='mt-4 grid grid-cols-1 gap-8 p-0 md:grid-cols-2'>
        <FavoriteCard
          name={d('outfit_card.name')}
          date={outfits.updatedAt}
          title={d('outfit_card.title')}
          description={d('outfit_card.description')}
          emptyDescription={d('outfit_card.empty_description')}
          headlineImage={outfits.image}
          sliderItems={outfits.entries}
          headlineImageAlt={d('outfit_card.image_alt')}
          emptyUrl={`/${pages.outfits}`}
          url={`/${pages.favoriteOutfits}`}
        />
        <FavoriteCard
          name={d('product_card.name')}
          date={products.updatedAt}
          title={d('product_card.title')}
          description={d('product_card.description')}
          emptyDescription={d('product_card.empty_description')}
          headlineImage={products.image}
          sliderItems={products.entries}
          headlineImageAlt={d('product_card.image_alt')}
          emptyUrl={`${pages.products}`}
          url={`/${pages.favoriteProducts}`}
        />
      </div>
    </section>
  )
}
