import { CreativeSliderOutfitSliderComponent } from '@/types'
import OutfitSlider from '@/containers/sections/outfit-slider'

export default function generate({
  component,
}: {
  component: CreativeSliderOutfitSliderComponent.Component
}) {
  return (
    <OutfitSlider
      title={component.config.headline}
      sliderItems={component.config.outfits}
    />
  )
}
