import { ComponentProps } from 'react'
// import styles from './styles.module.scss'

export interface IconProps extends ComponentProps<'i'> {
  icon: string
  className?: string
  size?: string
}

export default function Icon({
  icon,
  size,
  className = '',
  ...props
}: IconProps) {
  const iconName = `icon-${icon}`

  // if (!(iconName in styles)) {
  //   return <></>
  // }

  return (
    <i
      // className={`${styles.icon} ${styles[iconName]} pointer-events-none select-none ${className}`}
      className={`icon ${iconName} pointer-events-none select-none ${className}`}
      style={size ? { fontSize: size } : undefined}
      aria-hidden='true'
      {...props}
    />
  )
}
