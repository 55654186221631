import { NewsletterComponent } from '@/types'
import Newsletter from '@/containers/sections/newsletter'

export default function generate({
  component,
}: {
  component: NewsletterComponent.Component
}) {
  return <Newsletter text={component.config.text} />
}
