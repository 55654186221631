import { ContactPageComponent } from '@/types'
import Sidebar from '@/components/Sidebar'
import ContactForm from '@/components/ContactForm'

type Props = {
  content: string
  sidebar: Array<ContactPageComponent.SidebarItem>
}

export default function ContactPage({ content, sidebar }: Props) {
  return (
    <section className='container mb-12 gap-20 md:flex md:flex-row'>
      {sidebar.length > 0 && <Sidebar elements={sidebar} />}
      <div className='block'>
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className='break-words text-left'
        />
        <div className='flex justify-center'>
          <ContactForm />
        </div>
      </div>
    </section>
  )
}
