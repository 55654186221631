type Params = string | number | boolean | null | undefined

export interface HttpRequestParams {
  [key: string]: Params | Params[] | HttpRequestParams | HttpRequestParams[]
}

export interface HttpResponse<T> {
  success: boolean
  status: number
  data: T
  statusText: string
}

export const request = async <T>(
  url: string,
  params?: HttpRequestParams,
  headers?: HeadersInit
): Promise<HttpResponse<T>> => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json; charset=UTF-8',
      ...headers,
    },
    body: params ? JSON.stringify(params) : null,
    next: {
      revalidate: 3600 * 24 * 30,
    },
  } as RequestInit)

  return {
    success: response.ok,
    status: response.status,
    statusText: response.statusText,
    data: (await response.json()) as unknown as T,
  }
}
