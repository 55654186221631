import type { Config } from 'tailwindcss'
import withMT from '@material-tailwind/react/utils/withMT'
import { fontFamily } from 'tailwindcss/defaultTheme'

const config: Config = {
  content: [
    './src/**/*.{js,ts,jsx,tsx,mdx}',
    './node_modules/@material-tailwind/react/components/**/*.{js,ts,jsx,tsx}',
    './node_modules/@material-tailwind/react/theme/components/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    extend: {
      fontFamily: {
        sans: ['var(--font-poppins)', ...fontFamily.sans],
        poppins: ['var(--font-poppins)', ...fontFamily.sans],
        icon: ['var(--font-icons)'],
      },
      colors: {
        primary: 'rgb(var(--color-primary) / <alpha-value>)',
        secondary: 'rgb(var(--color-secondary) / <alpha-value>)',
        'body-bg': 'rgb(white / <alpha-value>)',
        'body-bg-secondary': 'rgb(var(--color-bg-secondary) / <alpha-value>)',
        'footer-bg': 'rgb(var(--color-footer-bg) / <alpha-value>)',
        'buy-button-bg': 'rgb(var(--color-buy-button-bg) / <alpha-value>)',
        'border-color': 'rgb(var(--color-border) / <alpha-value>)',
        'disabled-bg': 'rgb(var(--color-disabled-bg) / <alpha-value>)',
        'light-silver-color': 'rgb(var(--color-light-silver) / <alpha-value>)',
        'light-gray-color': 'rgb(var(--color-light-gray) / <alpha-value>)',
        'light-cyan-color': 'rgb(var(--color-light-cyan) / <alpha-value>)',
        tertiary: {
          red: 'rgb(var(--color-tertiary-red)/ <alpha-value>)',
          green: 'rgb(var(--color-tertiary-green)/ <alpha-value>)',
          amber: 'rgb(var(--color-tertiary-amber)/ <alpha-value>)',
          orange: 'rgb(var(--color-tertiary-orange)/ <alpha-value>)',
        },
      },
      backgroundImage: {
        'gradient-lilac':
          'linear-gradient(to right, #D4A5A5 0%, #D4A5D8 25%, #A5A5D4 50%, #A5D8D4 75%, #D8D4A5 100%)',
        'gradient-multi':
          'linear-gradient(to right, #FF5733 0%, #FFC300 25%, #33FF57 50%, #335BFF 75%, #CB33FF 100%)',
        'gradient-pastel':
          'linear-gradient(to right, #FFD6B3 0%, #FFEDD1 25%, #B3E0FF 50%, #FFCCF7 75%, #E0C3FF 100%)',
        'gradient-neon':
          'linear-gradient(to right, #FF00FF 0%, #00FFFF 25%, #FF00FF 50%, #00FFFF 75%, #FF00FF 100%)',
      },
      borderRadius: {
        DEFAULT: '0',
      },
      screens: {
        sm: '480px',
        md: '768px',
        lg: '1024px',
        'lg-max': { max: '1024px' },
        xl: '1280px',
        '2xl': '1440px',
      },
      container: {
        center: true,
        padding: {
          DEFAULT: '1rem',
          '2xl': '2rem',
        },
        screens: {
          '2xl': '1440px',
        },
      },
      letterSpacing: {
        wide: '0.015625rem',
        wider: '0.03125rem',
      },
      width: {
        '120': '30rem',
      },
      height: {
        '120': '30rem',
      },
      size: {
        '120': '30rem',
      },
      spacing: {
        '120': '30rem',
      },
      fontSize: {
        xs: ['0.6875rem', { lineHeight: '130%' }], //11px
        sm: ['0.75rem', { lineHeight: '120%' }], //12px
        md: ['0.8125rem', { lineHeight: '120%' }], //13px
        base: ['0.875rem', { lineHeight: '150%' }], //14px
        lg: ['1rem', { lineHeight: '160%' }], //16px
        xl: ['1.125rem', { lineHeight: '160%' }], //18px
        '2xl': ['1.25rem', { lineHeight: '150%' }], //20px
        '3xl': ['1.5rem', { lineHeight: '130%' }], //24px
        '4xl': ['1.75rem', { lineHeight: '130%' }], //28px
        '5xl': ['2rem', { lineHeight: '130%' }], //32px
        '6xl': ['2.25rem', { lineHeight: '130%' }], //36px
      },
      flexBasis: {
        '1/9': '11.1111111%',
        '2/9': '22.2222222%',
        '3/9': '33.3333333%',
        '4/9': '44.4444444%',
        '5/9': '55.5555555%',
        '6/9': '66.6666666%',
        '7/9': '77.7777777%',
        '8/9': '88.8888888%',
      },
      boxShadow: {
        DEFAULT: '0 2px 4px 0 rgb(0 0 0 / 0.25)',
      },
      gridTemplateColumns: {
        'footer-icons': 'repeat(3, min-content)',
        'filter-category':
          'repeat(auto-fit, minmax(var(--min-column-width, 82px), 1fr))',
      },
    },
  },
  plugins: [],
}

export default withMT(config)
