import React, { HTMLAttributes, ReactNode } from 'react'

interface CustomListItemProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  selected?: boolean
}

const CustomListItem: React.FC<CustomListItemProps> = ({
  children,
  selected = false,
  ...rest
}) => {
  const { className = '', ...otherRest } = rest // Fixed: className instead of clasName

  return (
    <div
      tabIndex={0}
      className={`flex w-full cursor-pointer items-center text-start leading-tight outline-none transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900 ${selected ? 'bg-blue-gray-50/50' : ''} ${className}`}
      {...otherRest} // Spread otherRest to ensure other props are passed
    >
      {children}
    </div>
  )
}

export default CustomListItem
