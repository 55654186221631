import React, { ReactNode, HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  className?: string
}

export const CustomList: React.FC<Props> = ({
  children,
  className = '',
  ...rest
}) => {
  return (
    <div
      className={`flex min-w-[240px] flex-col gap-1 p-0 font-sans text-base font-normal text-blue-gray-700 ${className}`}
      {...rest}
    >
      {children}
    </div>
  )
}

export { default as CustomListItem } from './custom-list-item'
