'use client'

import React, { useState } from 'react'
import { signIn } from 'next-auth/react'
import { Button, Typography } from '@material-tailwind/react'
import Alert from '@/components/Alert'
import Link from '@/components/Link'
import { useDictionary } from '@/providers'
import FormProvider, {
  RHFPasswordField,
  RHFTextField,
  useForm,
  Yup,
  yupResolver,
} from '@/components/HookForm'

export default function LoginForm() {
  const d = useDictionary('auth')

  const [errorMsg, setErrorMsg] = useState('')

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required(d('email_required')).email(d('email_invalid')),
    password: Yup.string().required(d('password_required')),
  })

  const defaultValues = {
    email: '',
    password: '',
  }

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = handleSubmit(async (data) => {
    const result = await signIn('credentials', {
      email: data.email,
      password: data.password,
      redirect: false,
    })

    if (!result?.ok) {
      reset()
      setErrorMsg(result?.error || '')
      return
    }

    window.location.reload()
  })

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {errorMsg && (
        <Alert state='error' className='mb-4'>
          {errorMsg}
        </Alert>
      )}
      <div className='grid gap-4'>
        <RHFTextField
          name='email'
          variant='static'
          placeholder='example@gmail.com'
          label={d('email_input')}
        />

        <RHFPasswordField
          name='password'
          variant='static'
          placeholder='********'
          label={d('password_input')}
        />
      </div>

      <Button
        fullWidth
        type='submit'
        loading={isSubmitting}
        className='mb-2 mt-4 flex w-full items-center justify-center bg-primary p-2 text-lg font-medium capitalize text-white !shadow-none'
      >
        {d('sign_in_btn')}
      </Button>
      <Typography
        variant='paragraph'
        className='mt-2.5 text-left text-base font-normal text-secondary'
      >
        <Link href='/forgot-password'>{d('forgot_password')}</Link>
      </Typography>
    </FormProvider>
  )
}
