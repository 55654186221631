import { User, NextAuthOptions } from 'next-auth'
import CredentialsProvider from 'next-auth/providers/credentials'
import GoogleProvider from 'next-auth/providers/google'
import FacebookProvider from 'next-auth/providers/facebook'

import { checkUserExist, signInRequest, signUpRequest } from '@/actions/auth'
import { Auth } from '@/types'

export const AuthOptions: NextAuthOptions = {
  secret: process.env.NEXTAUTH_SECRET,
  providers: [
    CredentialsProvider({
      name: 'credentials',
      credentials: {
        email: {
          label: 'Email',
          type: 'email ',
          placeholder: 'example@gmail.com',
          required: true,
        },
        password: { label: 'Password', type: 'password', required: true },
      },
      async authorize(
        credentials: Record<'email' | 'password', string> | undefined
      ): Promise<User | null> {
        const { data, success } = await signInRequest({
          email: credentials?.email,
          password: credentials?.password,
        })

        if (!success) {
          throw new Error(data[0])
        }

        return { email: credentials?.email, token: data?.token } as User
      },
    }),
    GoogleProvider({
      clientId: process.env.GOOGLE_CLIENT_ID as string,
      clientSecret: process.env.GOOGLE_CLIENT_SECRET as string,
    }),
    FacebookProvider({
      clientId: process.env.FACEBOOK_CLIENT_ID as string,
      clientSecret: process.env.FACEBOOK_CLIENT_SECRET as string,
    }),
  ],
  callbacks: {
    async signIn({ user, account, profile }) {
      if (account?.provider === Auth.Providers.CREDENTIALS.name) {
        return true
      }

      if (user && user.email) {
        const { success: userExist, data } = await checkUserExist({
          email: user.email,
        })

        if (userExist) {
          if (data.token) {
            user.token = data.token
          }

          return true
        }

        if (account?.provider === Auth.Providers.FACEBOOK.name) {
          const nameParts = profile?.name ? profile.name.split(' ') : ['']
          const firstName = nameParts[0] || ''
          const lastName = nameParts.slice(1).join(' ')

          const profileImage =
            profile?.picture && typeof profile.picture === 'object'
              ? (profile.picture as { data?: { url?: string } })?.data?.url
              : undefined

          const { success, data: res } = await signUpRequest({
            firstName,
            lastName,
            email: profile?.email,
            profileImage,
            provider: Auth.Providers.FACEBOOK.id,
          })

          if (success) {
            user.token = res.token
          }
        }

        if (account?.provider === Auth.Providers.GOOGLE.name) {
          const { success, data: res } = await signUpRequest({
            firstName: profile?.given_name,
            lastName: profile?.family_name,
            email: profile?.email,
            profileImage: profile?.picture,
            provider: Auth.Providers.GOOGLE.id,
          })

          if (success) {
            user.token = res.token
          }
        }
      }

      return true
    },
    async jwt({ token, user }) {
      if (user && user.token) {
        token.accessToken = user.token
      }
      return token
    },
    async session({ session, token }) {
      session.user = {
        email: session?.user?.email,
        accessToken: token?.accessToken,
      }
      return session
    },
  },
  session: {
    strategy: 'jwt',
    maxAge: 7000,
  },
  pages: {
    signIn: '/sign-in',
    signOut: '/sign-out',
  },
}
