import mitt, { Emitter } from 'mitt'

export const DeleteFavoriteProductEvent = 'DeleteFavoriteProduct'
export const DeleteFavoriteOutfitEvent = 'DeleteFavoriteOutfit'

export type Events = {
  [DeleteFavoriteProductEvent]: number
  [DeleteFavoriteOutfitEvent]: number
}

const emitter: Emitter<Events> = mitt<Events>()

export default emitter
