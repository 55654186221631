'use client'

import React, { useState, useEffect } from 'react'
import { Filters } from '@/types'
import { Button } from '@material-tailwind/react'
import HorizontalSelect from '@/components/Filters/components/HorizontalSelect'
import { OffCanvas } from '@/components/Filters/components/OffCanvas'
import Accordion from '@/components/Accordion'
import { useDictionary } from '@/providers'
import FakeCheckbox from '@/components/FakeCheckbox'

type Props = {
  title: string
  name: string
  items: Array<Filters.Color>
  onFilter: Filters.onFilter
  onResetFilter: Filters.onResetFilter
  onShowMoreFilters: Filters.onShowMoreFilters
  filter: Filters.SelectedData
  mainContentFilter: boolean
  mobile?: boolean
  vertical?: boolean
  className?: string
  urlPosition?: number | null
  selected?: Array<string>
  group?: Filters.Group
}

export default function Color({
  title,
  name,
  items,
  onFilter,
  onResetFilter,
  onShowMoreFilters,
  filter,
  mainContentFilter,
  mobile = false,
  vertical = false,
  className = '',
  urlPosition = null,
  selected = [],
  group,
}: Props) {
  const d = useDictionary('filters')

  const [colorItems, setColorItems] = useState<Array<Filters.Color>>(
    items || []
  )
  const [loading, setLoading] = useState({
    button: false,
    loadMore: false,
  })
  const [getColorItemsSuccess, setGetColorItemsSuccess] = useState(false)

  const [checkedItems, setCheckedItems] = useState<Array<Filters.Color>>(
    filter?.selected || []
  )
  const [checkedSlugs, setCheckedSlugs] = useState<Array<string>>(
    selected || []
  )

  useEffect(() => {
    if (!Object.keys(filter)?.length && checkedItems?.length) {
      setCheckedItems([])
      setCheckedSlugs([])
    } else if (
      Object.keys(filter)?.length &&
      filter?.selected?.length < checkedItems?.length
    ) {
      setCheckedItems(filter?.selected)
      const slags = filter?.selected?.map(
        (item: Filters.FilterItemCheckboxListItem) => item.slug
      )
      setCheckedSlugs(slags)
    }
  }, [filter?.selected])

  const handleChange = (value: boolean, item: Filters.Color) => {
    let array = [...checkedItems]
    let slugs = [...checkedSlugs]
    if (value) {
      array.push(item)
      slugs.push(item.slug)
    } else {
      array = array.filter((i: Filters.Color) => {
        return i.id !== item.id
      })
      slugs = slugs.filter((i: string) => {
        return i !== item.slug
      })
    }

    setCheckedItems(array)
    setCheckedSlugs(slugs)
    if (array?.length) {
      onFilter(
        name,
        {
          urlPosition: urlPosition || null,
          title,
          selected: array,
        },
        mobile,
        group
      )
    } else {
      onResetFilter(name, !mobile, group)
    }
  }

  const handleShowMore = async (type: string) => {
    setLoading({
      ...loading,
      [type]: true,
    })

    const newColorItems = await onShowMoreFilters(name)

    const filteredColorItems = newColorItems.filter(
      (item: Filters.Color) =>
        !colorItems.some((colorItem) => colorItem.slug === item.slug)
    )

    const updatedColorItems = [...colorItems, ...filteredColorItems]

    setColorItems(updatedColorItems)
    setGetColorItemsSuccess(true)
    setLoading({
      ...loading,
      [type]: false,
    })

    return true
  }

  const getColorOfTick = (color: string) => {
    const hex = color?.slice(1)
    const bigint = parseInt(hex, 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255
    const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b
    return brightness >= 128 ? 'text-black' : 'text-white'
  }

  const showMore =
    mainContentFilter && colorItems?.length <= 0 ? handleShowMore : () => {}

  const renderContent = (
    <>
      <div
        data-nosnippet
        className={`${className} grid w-auto focus-visible:outline-none ${mobile ? 'grid-cols-4 gap-2' : 'grid-cols-2'}`}
      >
        {colorItems.length > 0 &&
          colorItems.map((item, index) => (
            <div
              key={index}
              data-nosnippet
              className={`flex items-center ${mobile ? 'flex-col' : 'flex-row p-2'}`}
            >
              <FakeCheckbox
                checked={checkedSlugs?.includes(item.slug)}
                className={`${mobile ? 'flex-col p-5' : 'flex-row p-4'} rounded-none ${item.colorHex === '#ffffff' ? '' : 'border-none'}`}
                labelProps={{
                  className: `font-medium text-primary ${mobile ? 'pl-0 pt-1 text-xs' : 'pl-2 pt-0 text-sm'} `,
                }}
                inputProps={{
                  style: { backgroundColor: item.colorHex },
                  className: `!mr-0 ${mobile ? 'p-5' : 'p-4'} rounded-none ${item.colorHex === '#ffffff' ? '' : 'border-none'}`,
                }}
                iconProps={{
                  className: getColorOfTick(item.colorHex),
                }}
                ripple={false}
                onChange={(checked) => handleChange(checked, item)}
                label={item.value}
              />
            </div>
          ))}
      </div>

      {mainContentFilter && !getColorItemsSuccess && (
        <Button
          loading={loading.button}
          className='mt-4 flex h-8 w-full items-center justify-center border px-2 py-1 text-center text-sm font-medium capitalize text-secondary !shadow-none'
          onClick={() => handleShowMore('button')}
          aria-label={d('more_filters')}
        >
          {d('more_filters')}
        </Button>
      )}
    </>
  )

  if (mobile) {
    return (
      <OffCanvas
        title={title}
        filterCount={checkedSlugs?.length}
        onClick={() => showMore('loadMore')}
        loading={loading.loadMore}
      >
        {renderContent}
      </OffCanvas>
    )
  }

  if (vertical) {
    return (
      <Accordion
        title={title}
        active={!!checkedSlugs?.length || !!items?.length}
        icon='plus'
        collapsedIcon='minus'
        className='m-0 border px-3'
        headerClassName='text-base border-0'
        onClick={() => showMore('loadMore')}
        loading={loading.loadMore}
      >
        {renderContent}
      </Accordion>
    )
  }

  return (
    <HorizontalSelect
      title={title}
      filterCount={checkedSlugs.length}
      onClick={() => showMore('loadMore')}
      loading={loading.loadMore}
    >
      {renderContent}
    </HorizontalSelect>
  )
}
