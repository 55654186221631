import { Typography, Button } from '@material-tailwind/react'

export default function Skeleton() {
  return [...Array(5)].map((i, index) => (
    <div key={index} className='flex animate-pulse flex-col gap-6'>
      <Typography
        as='p'
        variant='h1'
        className='h-8 w-20 rounded-full bg-gray-300'
      >
        &nbsp;
      </Typography>

      <div className='grid grid-cols-1 gap-x-16 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
        {[...Array(16)].map((i, count) => (
          <div
            key={count}
            className='flex flex-row items-center justify-between gap-2.5'
          >
            <Typography
              as='p'
              variant='h1'
              className='h-3 flex-auto rounded-full bg-gray-300'
            >
              &nbsp;
            </Typography>
            <Button
              disabled
              tabIndex={-1}
              className='h-8 w-20 bg-gray-300 shadow-none hover:shadow-none'
            >
              &nbsp;
            </Button>
          </div>
        ))}
      </div>
    </div>
  ))
}
