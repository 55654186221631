'use client'

import { useState } from 'react'
import { Navbar, Menu, MenuHandler, MenuItem } from '@material-tailwind/react'
import { HeaderComponent } from '@/types'
import NavMenu from '@/components/AdvancedMenu/NavMenu'
import FakeLink from '@/components/FakeLink'
import Link from '@/components/Link'
import Slider, { breakpoints, SliderItem } from '@/components/Slider'
import { useDictionary, usePage } from '@/providers'
import { usePathname } from 'next/navigation'

type Props = {
  menuItems: Array<HeaderComponent.MenuItem>
}

export default function AdvancedMenu({ menuItems }: Props) {
  const { pages } = usePage()
  const d = useDictionary('header')
  const pathname = usePathname()
  const sliderProps = {
    slidesPerView: 'auto' as const,
  }
  const [activeMenu, setActiveMenu] = useState<string>(menuItems[0].type)
  const onMouseOver = (item: HeaderComponent.MenuItem) => {
    if (window.innerWidth < breakpoints.md || !item.advancedMenu?.length) {
      setActiveMenu(menuItems[0].type)
      return
    }
    setActiveMenu(item.type)
  }

  return (
    <Navbar
      className='w-full gap-6 p-0 py-2 text-end shadow-none lg:py-6'
      role='menubar'
    >
      <Slider
        className='overflow-visible'
        {...sliderProps}
        navPrevProps={{ role: 'menuitem' }}
        navNextProps={{ role: 'menuitem' }}
      >
        {menuItems.map((item, key) => (
          <SliderItem key={key} className='!w-auto'>
            {item.advancedMenu?.length ? (
              <Menu
                offset={{ mainAxis: 25 }}
                placement='bottom'
                allowHover={true}
              >
                <MenuHandler
                  onMouseOver={() => {
                    onMouseOver(item)
                  }}
                >
                  <MenuItem className='p-0'>
                    <FakeLink
                      href={item.url}
                      className={`px-2 py-3 text-gray-900 focus-visible:outline-none ${
                        pathname === item.url ? 'font-bold' : 'font-medium'
                      }`}
                    >
                      {item.label}
                    </FakeLink>
                  </MenuItem>
                </MenuHandler>
                {activeMenu === item.type && (
                  <NavMenu items={item.advancedMenu} />
                )}
              </Menu>
            ) : (
              <FakeLink
                as={MenuItem}
                href={item.url}
                className={`text-gray-900 focus-visible:outline-none ${
                  pathname === item.url ? 'font-bold' : 'font-medium'
                }`}
              >
                {item.label}
              </FakeLink>
            )}
          </SliderItem>
        ))}
      </Slider>
      <Link href={`/${pages.sitemap}`} className='hidden'>
        {d('sitemap')}
      </Link>
    </Navbar>
  )
}
