import { BannerTextLinksComponent } from '@/types'
import BannerTextLinks from '@/containers/sections/banner-text-links'

export default function generate({
  component,
}: {
  component: BannerTextLinksComponent.Component
}) {
  return (
    <BannerTextLinks
      image={component.config.deviceDepended}
      text={component.config.text}
      urls={component.config.urls}
    />
  )
}
