export interface CheckUserExist {
  token: string
}

export interface ForgotPassword {
  success: boolean
  errors: Array<string>
}

export interface ResetPassword {
  success: boolean
  errors: Array<string>
}

export interface ResendEmail {
  success: boolean
  errors: Array<string>
}

export interface Provider {
  id: number
  name: string
}

export const Providers: { [key: string]: Provider } = {
  CREDENTIALS: {
    id: 1,
    name: 'credentials',
  },
  GOOGLE: {
    id: 2,
    name: 'google',
  },
  FACEBOOK: {
    id: 3,
    name: 'facebook',
  },
}
