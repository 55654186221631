import { breakpoints } from '@/components/Slider/index'
import tailwindConfig from '@/utils/tailwind-config'
import remToPxToNum from '@/utils/rem-to-px-to-num'

interface SlideConfig {
  [key: string]: any
}

type BreakpointsType = keyof typeof breakpoints

const getBreakpointSpacing = (
  padding: Record<string, string>,
  size: string
): number => remToPxToNum(padding[size] || '0')

export const getSwiperSlideStyles = (
  slidesPerViewConfig: Record<string, SlideConfig> = {},
  gap = 0,
  xsSlidesPerView = 1
) => {
  const padding = tailwindConfig.theme.container.padding as Record<
    string,
    string
  >
  const defaultSpacing = getBreakpointSpacing(padding, 'DEFAULT')

  const breakpointsSpacing: Record<BreakpointsType, number> = {
    sm: defaultSpacing,
    md: defaultSpacing,
    lg: defaultSpacing,
    xl: defaultSpacing,
    xxl: getBreakpointSpacing(padding, '2xl'),
  }

  const calculateWidthPercent = (containerWidth: number, items: number) =>
    (100 - (((Math.ceil(items) - 1) * gap) / containerWidth) * 100) / items

  const widthXSPercent = calculateWidthPercent(
    320 - defaultSpacing,
    xsSlidesPerView
  )

  let styles: { [key: string]: string } = {
    '--xs-gap': `${gap}px`,
    '--xs-width':
      typeof xsSlidesPerView === 'string'
        ? xsSlidesPerView
        : `${widthXSPercent.toFixed(1)}%`,
  }

  Object.entries(slidesPerViewConfig).forEach(([breakpoint, item]) => {
    if (!item?.slidesPerView) return

    const foundKey = Object.entries(breakpoints).find(
      ([, value]) => value === Number(breakpoint)
    )?.[0] as BreakpointsType | undefined
    if (!foundKey) return

    const containerWidth =
      Number(breakpoint) - (breakpointsSpacing[foundKey] || 0)
    const widthPercent = calculateWidthPercent(
      containerWidth,
      item.slidesPerView
    )

    styles = {
      ...styles,
      [`--${foundKey}-gap`]: `${item.spaceBetween || gap}px`,
      [`--${foundKey}-width`]:
        typeof item.slidesPerView === 'string'
          ? item.slidesPerView
          : `${widthPercent.toFixed(1)}%`,
    }
  })

  return styles
}
