'use client'

import { useState } from 'react'
import { Filters } from '@/types'
import generators from '../generators'
import { useDictionary } from '@/providers'
import SelectedFilters from '../components/SelectedFilters'
import { Button } from '@material-tailwind/react'
import Icon from '@/components/Icon'

type Props = {
  components: Array<Filters.FilterItem>
  onFilter: Filters.onFilter
  filters: Record<string, any>
  className?: string
  onResetFilter?: Filters.onResetFilter
  onResetAllFilter?: Filters.onResetAllFilter
  onShowMoreFilters?: Filters.onShowMoreFilters
  group?: Filters.Group
}

export function Horizontal({
  components,
  onFilter,
  filters,
  className = '',
  onResetFilter = () => {},
  onResetAllFilter = () => {},
  onShowMoreFilters = () => {},
  group,
}: Props) {
  const d = useDictionary('filters')
  const defaultShowFiltersCount = 6

  const [filterComponents, setFilterComponents] = useState(
    components?.slice(0, defaultShowFiltersCount) || []
  )
  const [showMoreButton, setShowMoreButton] = useState(
    defaultShowFiltersCount < components?.length
  )

  const handleMoreFiltersClick = () => {
    setFilterComponents(components)
    setShowMoreButton(false)
  }

  return (
    <div className={`${className} flex-col items-start gap-2`}>
      <div className='flex-wrap lg:!flex lg:gap-2'>
        {filterComponents?.map((component, key) => {
          const Generator = generators[component.type]
          if (!Generator) {
            return null
          }
          return (
            <Generator
              key={key}
              component={component}
              onFilter={onFilter}
              filter={
                filters?.hasOwnProperty(component?.name)
                  ? filters[component?.name]
                  : {}
              }
              onResetFilter={onResetFilter}
              onShowMoreFilters={onShowMoreFilters}
              horizontal
              group={group}
            />
          )
        })}
        {showMoreButton && (
          <Button
            className={`flex h-8 items-center justify-between border-l px-2 py-1 text-left text-sm font-medium capitalize text-secondary !shadow-none`}
            aria-label={d('more_filters')}
            onClick={handleMoreFiltersClick}
          >
            {d('more_filters')}
            <Icon
              className={`ml-2 block text-border-color`}
              size='12px'
              icon='filters'
            />
          </Button>
        )}
      </div>
      {filters && Object.keys(filters).length > 0 && (
        <SelectedFilters
          onFilter={onFilter}
          onResetAllFilter={onResetAllFilter}
          onResetFilter={onResetFilter}
          selectedItems={filters}
          group={group}
        />
      )}
    </div>
  )
}
