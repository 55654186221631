import { ComponentType } from 'react'
import { Filters } from '@/types'
import Tree from './tree'
import Range from './range'
import Color from './color'
import List from './list'
import CheckboxList from './checkbox-list'
import Checkbox from './checkbox'
import Order from './order'
import CategoryModal from './category-modal'

export type FilterGenerators = {
  // eslint-disable-next-line
  [key in Filters.Type]?: ComponentType<any>
}

const filterGenerators: FilterGenerators = {
  [Filters.Type.TREE]: Tree,
  [Filters.Type.RANGE]: Range,
  [Filters.Type.COLOR]: Color,
  [Filters.Type.LIST]: List,
  [Filters.Type.CHECKBOX_LIST]: CheckboxList,
  [Filters.Type.CHECKBOX]: Checkbox,
  [Filters.Type.ORDER]: Order,
  [Filters.Type.CATEGORY_MODAL]: CategoryModal,
}

export default filterGenerators
