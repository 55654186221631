import { FavoriteComponent } from '@/types'
import FavoriteProductsListing from '@/containers/sections/favorite-products-listing'
import { FAVORITE_PRODUCT_CODE } from '@/utils/api'

export default function generate({
  component,
}: {
  component: FavoriteComponent.ProductsComponent
}) {
  return (
    <FavoriteProductsListing
      products={component.config.entries}
      count={component.config.count}
      typeCode={FAVORITE_PRODUCT_CODE}
    />
  )
}
