import { VerifyAccountComponent } from '@/types'
import ResetPassword from '@/containers/sections/reset-password'

export default function generate({
  component,
}: {
  component: VerifyAccountComponent.Component
}) {
  return <ResetPassword errors={component.config.errors} />
}
