import Link from '@/components/Link'
import { useDictionary } from '@/providers'
import Social from '@/components/Auth/Social'
import LoginForm from '@/components/Auth/LoginForm'
import { Button, Typography } from '@material-tailwind/react'

type Props = {
  className?: string
}

export default function SignInSection({ className = '' }: Props) {
  const d = useDictionary('sign_in')

  return (
    <section className={`bg-white px-4 py-8 md:px-0 md:py-16 ${className}`}>
      <div className='container flex max-w-7xl flex-col gap-24 md:flex-row md:gap-32 md:text-left'>
        <div className='w-full md:w-1/2'>
          <Typography as='p' variant='h2' className='3xl mb-2'>
            {d('sing_up_title')}
          </Typography>
          <Typography variant='paragraph' className='mb-8 text-lg'>
            {d('sing_up_description')}
          </Typography>
          <Link
            as={Button}
            href='/sign-up'
            className='mb-2 mt-4 flex w-full items-center justify-center bg-primary p-2 text-lg font-medium capitalize text-white !shadow-none'
          >
            {d('sing_up_btn')}
          </Link>
        </div>
        <div className='w-full md:w-1/2'>
          <Typography as='p' variant='h2' className='3xl mb-2'>
            {d('title')}
          </Typography>
          <Typography variant='paragraph' className='mb-8 text-lg'>
            {d('description')}
          </Typography>

          <LoginForm />

          <div className='my-8 flex items-center'>
            <div className='flex-1 border-t-2 border-light-gray-color' />
            <span className='bg-white px-2 text-base text-primary'>
              {d('or')}
            </span>
            <div className='flex-1 border-t-2 border-light-gray-color' />
          </div>

          <Social />
        </div>
      </div>
    </section>
  )
}
