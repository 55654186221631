import {
  request,
  HttpRequestParams,
  API_SIMILAR_OUTFITS,
} from '@/utils/http-request'
import { Outfit } from '@/types'

interface SimilarOutfitsRequestParams extends HttpRequestParams {
  id: number
  offset: number
  categoryId?: number
  limit?: number
}

export const fetchSimilarOutfits = (
  id: number,
  offset: number,
  limit?: number,
  categoryId?: number
) => {
  const requestParams: SimilarOutfitsRequestParams = {
    id,
    offset,
  }

  if (categoryId !== undefined) {
    requestParams.categoryId = categoryId
  }
  if (limit !== undefined) {
    requestParams.limit = limit
  }

  return request<Outfit.SimilarOutfits>(API_SIMILAR_OUTFITS, requestParams)
}
