import { Error500Component } from '@/types'
import Error500 from '@/containers/sections/error500'

export default function generate({
  component,
}: {
  component: Error500Component.Component
}) {
  return (
    <Error500 text={component.config.text} title={component.config.title} />
  )
}
