'use client'

import React, { useState, useEffect } from 'react'
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import Icon from '@/components/Icon'
import General from '@/components/Profile/General'
import Settings from '@/components/Profile/Settings'
import Help from '@/components/Profile/Help'
import { ProfileComponent } from '@/types'
import { useSearchParams, useRouter } from 'next/navigation'

type Props = {
  firstName: string
  lastName: string
  email: string
  faq: Array<ProfileComponent.FAQ>
  profileImage: string
  provider: number
}

export default function Profile({
  firstName,
  lastName,
  email,
  faq,
  profileImage,
  provider,
}: Props) {
  const d = useDictionary('profile')
  const [loading, setLoading] = useState<boolean>(false)
  const [newProfileImage, setNewProfileImage] = useState<string>(profileImage)
  const [newFirstName, setNewFirstName] = useState<string>(firstName)
  const [newLastName, setNewLastName] = useState<string>(lastName)
  const searchParams = useSearchParams()
  const router = useRouter()

  const onClickTab = (value: string) => {
    setLoading(true)
    router.push(`?tab=${value}`)
  }

  const getActiveTab = () => {
    return searchParams.get('tab') || 'general'
  }

  const [tab, setTab] = useState<string>(getActiveTab())

  useEffect(() => {
    setTab(getActiveTab())
    setLoading(false)
  }, [searchParams])

  const data = [
    {
      label: d('general.title'),
      value: 'general',
      icon: 'user',
      desc: (
        <General
          firstName={newFirstName}
          lastName={newLastName}
          profileImage={newProfileImage}
          settingNewProfileImage={(_picture) => setNewProfileImage(_picture)}
        />
      ),
    },
    {
      label: d('settings.title'),
      value: 'settings',
      icon: 'gear',
      desc: (
        <Settings
          firstName={newFirstName}
          profileImage={newProfileImage}
          lastName={newLastName}
          email={email}
          settingNewProfileImage={(_picture) => setNewProfileImage(_picture)}
          settingFirstName={(_name) => setNewFirstName(_name)}
          settingLastName={(_name) => setNewLastName(_name)}
          provider={provider}
        />
      ),
    },
    {
      label: d('help.title'),
      value: 'help',
      icon: 'help',
      desc: <Help faq={faq} />,
    },
  ]

  const getTabs = (isMobile: boolean) => {
    return (
      <Tabs
        value={tab}
        key={tab}
        orientation={isMobile ? 'horizontal' : 'vertical'}
      >
        <TabsHeader className='mb-16 w-full md:mb-0 md:w-48'>
          {data.map(({ label, value, icon }) => (
            <Tab
              key={value}
              value={value}
              className={`place-items-start ${isMobile ? '' : 'mb-4 h-9'}`}
              onClick={() => onClickTab(value)}
            >
              <div className='flex items-center gap-2'>
                <Icon icon={icon} />
                {label}
              </div>
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody className='md:mx-16 lg:mx-32'>
          {!loading &&
            data.map(({ value, desc }) => (
              <TabPanel key={value} value={value} className='py-0'>
                {desc}
              </TabPanel>
            ))}
        </TabsBody>
      </Tabs>
    )
  }

  return (
    <section className='my-14 md:mx-16'>
      <div className='block md:hidden'>{getTabs(true)}</div>
      <div className='hidden md:block'>{getTabs(false)}</div>
    </section>
  )
}
