import { Button } from '@material-tailwind/react'
import IconSvg from '@/components/IconSvg'

type Props = {
  label: string
  icon: string
  onClick: () => void
}

export default function ButtonWithIcon({ label, icon, onClick }: Props) {
  return (
    <Button
      onClick={onClick}
      onTouchStart={onClick}
      className='flex w-full items-center border border-primary bg-white p-2 py-3 font-normal capitalize text-primary !shadow-none'
    >
      <IconSvg className={'mx-2 h-6 w-6 fill-white'} icon={icon} />
      <span className='mx-auto text-base'>{label}</span>
    </Button>
  )
}
