import { Typography } from '@material-tailwind/react'
import Link from '@/components/Link'
import Icon from '@/components/Icon'
import Image from '@/components/Image'
import { Search } from '@/types'
import { useDictionary } from '@/providers'

type Props = {
  searchResult: Array<Search.SearchEntry>
}

export default function SearchResult({ searchResult }: Props) {
  const d = useDictionary('search')

  if (!searchResult.length) {
    return (
      <div className='items-center p-5 text-center'>{d('resultNotFound')}</div>
    )
  }

  return (
    <>
      <Typography as='p' variant='h2' className='mb-2 px-2 font-medium'>
        {d('resultHeadline')}
      </Typography>
      {searchResult.map((item, key: number) => (
        <Link
          key={key}
          onClick={() => {
            sessionStorage.setItem('ngSearchItemSelect', '1')
          }}
          href={item.url}
          className='flex w-full flex-nowrap items-center justify-start gap-x-3 p-2 text-start text-lg font-normal capitalize md:transition-all md:hover:bg-blue-gray-50 md:hover:bg-opacity-80'
        >
          {!!item.media && (
            <Image
              className='size-10 object-cover object-center'
              src={item.media}
              alt={item.name}
              height={40}
              width={40}
            />
          )}
          {item.name}
          <Icon className='ml-auto block' size='13px' icon='arrow-right' />
        </Link>
      ))}
    </>
  )
}
