'use client'

import React, { useState } from 'react'
import { useDictionary } from '@/providers'
import ForgotPasswordForm from '@/components/Auth/ForgotPasswordForm'
import SuccessMessage from '@/components/Auth/SuccessMessage'
import { Typography } from '@material-tailwind/react'

export default function ForgotPassword() {
  const d = useDictionary('forgot_password')

  const [success, setSuccess] = useState<boolean>(false)

  if (success) {
    return (
      <SuccessMessage
        icon='envelope-open'
        title={d('success_title')}
        description={d('success_description')}
        btn_text={d('back_to_home_btn')}
        link_to='/'
      />
    )
  }

  return (
    <section className='container bg-white py-4 2xl:py-8'>
      <div className='sm:m-auto sm:w-3/4 md:w-2/5 lg:w-1/4'>
        <Typography
          as='p'
          variant='h2'
          className='mb-16 text-2xl font-semibold'
        >
          {d('title')}
        </Typography>
        <Typography variant='paragraph' className='mb-4 text-left text-lg'>
          {d('description')}
        </Typography>
        <ForgotPasswordForm setSuccess={setSuccess} />
      </div>
    </section>
  )
}
