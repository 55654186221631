'use client'

import React, { useState } from 'react'
import Link from '@/components/Link'
import { useDictionary } from '@/providers'
import Social from '@/components/Auth/Social'
import SuccessMessage from '@/components/Auth/SuccessMessage'
import RegisterForm from '@/components/Auth/RegisterForm'
import { Typography } from '@material-tailwind/react'

type Props = {
  className?: string
}

export default function SignUpSection({ className = '' }: Props) {
  const d = useDictionary('sign_up')

  const [success, setSuccess] = useState<boolean>(false)

  if (success) {
    return (
      <SuccessMessage
        icon='envelope-open'
        title={d('success_title')}
        description={d('success_description')}
        btn_text={d('back_to_home_btn')}
        link_to='/'
        className={className}
      />
    )
  }

  return (
    <section className={`bg-white px-4 py-8 md:px-0 md:py-16 ${className}`}>
      <div className='sm:m-auto sm:w-3/4 md:w-2/5 lg:w-1/3'>
        <div className='mb-16 text-2xl font-semibold'>{d('title')}</div>

        <RegisterForm setSuccess={setSuccess} />

        <div className='my-8 flex items-center'>
          <div className='flex-1 border-t-2 border-light-gray-color' />
          <span className='bg-white px-2 text-base text-primary'>
            {d('or')}
          </span>
          <div className='flex-1 border-t-2 border-light-gray-color' />
        </div>

        <Social />

        <Typography
          variant='paragraph'
          className='mt-4 text-left text-base font-normal'
        >
          {d('agreement')}{' '}
          <Link href='/' target={'_blank'} className='underline'>
            {d('terms')}
          </Link>{' '}
          {d('and')}{' '}
          <Link href='/' target={'_blank'} className='underline'>
            {d('privacy')}
          </Link>
        </Typography>
      </div>
    </section>
  )
}
