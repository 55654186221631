import { getAccessToken } from '@/utils/auth'

type Params = string | number | boolean | null | undefined

export interface ApiRequestParams {
  [key: string]: Params | Params[] | ApiRequestParams | ApiRequestParams[]
}

export interface ApiResponse<T> {
  success: boolean
  status: number
  data: T
  statusText: string
}

export const request = async <T>(
  url: string,
  params?: ApiRequestParams,
  headers?: HeadersInit
): Promise<ApiResponse<T>> => {
  const token = await getAccessToken()

  const fetchHeaders: HeadersInit = {
    Accept: 'application/json',
    'Content-type': 'application/json; charset=UTF-8',
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    ...headers,
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: fetchHeaders,
    body: params ? JSON.stringify(params) : null,
    next: url.includes('/similar') ? { revalidate: 3600 * 24 * 30 } : undefined, // Set caching only for URLs with "/similar"
  } as RequestInit)

  return {
    success: response.ok,
    status: response.status,
    statusText: response.statusText,
    data: (await response.json()) as unknown as T,
  }
}

export const requestProfileImage = async <T>(
  url: string,
  params?: FormData,
  headers?: HeadersInit
): Promise<ApiResponse<T>> => {
  const token = await getAccessToken()

  const fetchHeaders: HeadersInit = {
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    ...headers,
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: fetchHeaders,
    body: params,
    next: {
      revalidate: 3600 * 24 * 30,
    },
  } as RequestInit)

  return {
    success: response.ok,
    status: response.status,
    statusText: response.statusText,
    data: (await response.json()) as unknown as T,
  }
}
