'use client'

import React, { CSSProperties } from 'react'
import Image from '@/components/Image'
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  CardFooter,
} from '@material-tailwind/react'
import Link from '@/components/Link'
import { useDictionary, usePage } from '@/providers'
import htmlToText from '@/utils/html-to-text'
import { usePathname } from 'next/navigation'

export type Props = {
  id: number
  slug: string
  title: string
  description: string
  createdAt: string
  mediaUrl: string
  mediaAlt: string
  mediaBucket: string | unknown
  mediaKey: string | unknown
  className?: string
  style?: CSSProperties
  imageProps?: object
  urlRef?: boolean
}

export default function BlogCard({
  id,
  slug,
  title,
  createdAt,
  description,
  mediaAlt,
  mediaUrl,
  mediaBucket,
  mediaKey,
  className = '',
  style = {},
  imageProps = {},
  urlRef = false,
}: Props) {
  const d = useDictionary('blog_card')

  const { pages } = usePage()

  const pathname = usePathname()
  const url = `/${pages.blog}/${slug}${urlRef ? `?ref=${pathname.slice(1)}` : ''}`

  return (
    <Card
      color='transparent'
      shadow={false}
      className={`w-full max-w-[26rem] rounded-none ${className}`}
      style={style}
    >
      <CardHeader
        floated={false}
        shadow={false}
        color='transparent'
        className='m-0 rounded-none'
      >
        <Link href={url} className='block h-64' title={htmlToText(title)}>
          <Image
            data-key={mediaKey}
            data-bucket={mediaBucket}
            data-id={id}
            className='outfit-card-image absolute inset-x-0 inset-y-0 size-full object-cover object-center'
            src={mediaUrl}
            alt={htmlToText(mediaAlt)}
            width={750}
            height={750}
            {...imageProps}
          />
        </Link>
      </CardHeader>
      <CardBody className='mt-2 p-0'>
        {createdAt && (
          <Typography
            variant='small'
            className='mb-2 !line-clamp-2 block overflow-hidden text-ellipsis px-2 pt-0 text-left text-sm font-normal text-secondary'
          >
            {createdAt}
          </Typography>
        )}
        {title && (
          <Typography variant='h3'>
            <Link
              href={url}
              nofollow
              noopener
              title={htmlToText(title)}
              className='mb-2 !line-clamp-2 block overflow-hidden text-ellipsis px-2 pt-0 text-left text-md font-normal text-primary'
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Typography>
        )}

        {description && (
          <Typography
            variant='paragraph'
            className='!line-clamp-3 overflow-hidden text-ellipsis px-2 pt-0 text-left text-sm font-normal leading-normal text-secondary'
          >
            {description}
          </Typography>
        )}
      </CardBody>
      <CardFooter className='mt-2 p-0 text-right'>
        <Link
          href={url}
          nofollow
          noopener
          title={htmlToText(title)}
          className='px-2 pt-0 hover:underline'
        >
          {d('read_more')}
        </Link>
      </CardFooter>
    </Card>
  )
}
