import { SitemapComponent } from '@/types'
import Sitemap from '@/containers/sections/sitemap/'

export default function generate({
  component,
}: {
  component: SitemapComponent.Component
}) {
  return (
    <Sitemap pages={component.config.pages} footer={component.config.footer} />
  )
}
