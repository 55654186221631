import { Filters } from '@/types'
import Range from '@/components/Filters/components/Range'

export default function generate({
  component,
  filter,
  group,
  onFilter,
  onResetFilter,
  mobile = false,
}: {
  component: Filters.FilterItemRange
  filter: Filters.SelectedData
  group?: Filters.Group
  onFilter: Filters.onFilter
  onResetFilter: Filters.onResetFilter
  mobile?: boolean
}) {
  const _selected =
    component.selected &&
    Object.keys(component?.selected)?.length === 1 &&
    component.selected?.hasOwnProperty('maxPrice')
      ? { minPrice: 0, maxPrice: component.selected.maxPrice }
      : component.selected

  return (
    <Range
      title={component.title}
      name={component.name}
      items={component.values}
      filter={filter}
      selected={_selected}
      onFilter={onFilter}
      onResetFilter={onResetFilter}
      mobile={mobile}
      group={group}
    />
  )
}
