import IconSvg from '@/components/IconSvg'

type Props = {
  loading: boolean
  className?: string
  iconClassName?: string
}

export default function Loader({
  loading = false,
  className = '',
  iconClassName = '',
}: Props) {
  if (!loading) {
    return <></>
  }

  return (
    <div
      className={`fixed left-0 top-0 z-50 flex size-full items-center justify-center bg-black/90 ${className}`}
    >
      <IconSvg
        className={`size-11 fill-white ${iconClassName}`}
        icon='loading'
      />
    </div>
  )
}
