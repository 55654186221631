import Image from '@/components/Image'
import Link from '@/components/Link'
import { useDictionary, usePage } from '@/providers'

export function SitemapHeader() {
  const { channel, pixelImage } = usePage()
  const d = useDictionary('header')

  return (
    <div className='bg-gray-200 py-2 text-center'>
      <Link
        className='relative block inline-block h-8 w-36 md:h-11 md:w-52'
        href='/'
      >
        <Image
          src={channel?.logo ?? pixelImage}
          alt={d('logo_alt')}
          fill
          priority={true}
        />
      </Link>
    </div>
  )
}
