import { BrandListingComponent } from '@/types'
import BrandListingSection from '@/containers/sections/brand-listing'

export default function generate({
  component,
}: {
  component: BrandListingComponent.Component
}) {
  return (
    <BrandListingSection
      count={component.config.count}
      symbols={component.config.symbols}
      brands={component.config.brands}
    />
  )
}
