'use client'

import { Filters } from '@/types'
import React, { useState } from 'react'
import { Button, Drawer } from '@material-tailwind/react'

import Image from '@/components/Image'
import Icon from '@/components/Icon'
import SearchInput from '@/components/SearchInput'
import IconSvg from '@/components/IconSvg'

import { useDictionary } from '@/providers'
import { fetchCategories, fetchCategoriesChildren } from '@/actions/combination'

type SelectedObject = {
  value: string
  slug: string
}

type Props = {
  title: string
  name: string
  onFilter: Filters.onFilter
  onResetFilter: Filters.onResetFilter
  filter: Filters.SelectedData
  mobile?: boolean
  urlPosition?: number | null
  selected?: Array<SelectedObject>
  group?: Filters.Group
}

export default function CategoryModal({
  title,
  name,
  // mobile = false,
  onFilter,
  // onResetFilter,
  urlPosition = null,
  // filter,
  selected = [],
  group = {},
}: Props) {
  const d = useDictionary('filters')

  const DEFAULT_SHOW_MORE_COUNT = 20
  const [offset, setOffset] = useState(1)

  const [mobilePopupOpen, setMobilePopupOpen] = useState(false)
  // using for offCanvas content hide after close canvas
  const [showFilters, setShowFilters] = useState(false)

  const [categories, setCategories] = useState<Array<Filters.Categories>>([])
  const [filteredCategories, setFilteredCategories] = useState<
    Array<Filters.Categories>
  >([])
  const [categoryLoader, setCategoryLoader] = useState(false)

  const [categoryChildren, setCategoryChildren] = useState<
    Array<Filters.Categories>
  >([])
  const [childrenShortArray, setChildrenShortArray] = useState<
    Array<Filters.Categories>
  >([])
  const [categoryChildrenLoader, setCategoryChildrenLoader] = useState(false)
  const [isChildrenFiltered, setIsChildrenFiltered] = useState(false)

  const [selectedChildName, setSelectedChildName] = useState('')
  const [selectedChildImage, setSelectedChildImage] = useState('')

  const [categorySelected, setCategorySelected] = useState('')
  const [categorySelectedObject, setCategorySelectedObject] = useState({})
  const [selectedParentSlug, setSelectedParentSlug] = useState('')

  const [mobileChildrenOpen, setMobileChildrenOpen] = useState(false)

  const toggleOffCanvas = async () => {
    try {
      if (mobilePopupOpen) {
        setMobilePopupOpen(false)
        setTimeout(() => setShowFilters(false), 500)
        return
      }

      setCategoryLoader(true)
      const { data } = await fetchCategories()
      if (data.categories?.length) {
        setCategories(data.categories)
        setFilteredCategories(data.categories)
        setShowFilters(true)
        setTimeout(() => setMobilePopupOpen(true), 500)
      }
      setCategoryLoader(false)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      setCategoryLoader(false)
    }
  }

  const searchCategory = (value: string) => {
    const lowerCaseValue = value.toLowerCase()

    const filterCategories = (
      categories: Array<Filters.Categories>
    ): Array<Filters.Categories> => {
      return categories
        .map((category): Filters.Categories | null => {
          const filteredChildren: Array<Filters.Categories> = category.children
            ? filterCategories(category.children)
            : []

          const matchesCategory = category.label
            .toLowerCase()
            .includes(lowerCaseValue)
          const hasMatchingChildren = filteredChildren.length > 0

          if (matchesCategory || hasMatchingChildren) {
            return {
              ...category,
              children: filteredChildren,
            }
          }
          return null
        })
        .filter((category): category is Filters.Categories => category !== null)
    }

    const filteredCategories = filterCategories(categories)
    setFilteredCategories(filteredCategories)
  }

  const searchChildCategory = (value: string) => {
    if (value) {
      const filteredChildCategories = categoryChildren.filter((item) =>
        item?.label.toLowerCase().includes(value)
      )
      setChildrenShortArray(filteredChildCategories)
      setIsChildrenFiltered(true)
      return
    }

    setChildrenShortArray(categoryChildren.slice(0, DEFAULT_SHOW_MORE_COUNT))
    setIsChildrenFiltered(false)
    setOffset(2)
  }

  const onShowMore = () => {
    setChildrenShortArray(
      categoryChildren.slice(0, DEFAULT_SHOW_MORE_COUNT * offset)
    )
    setOffset(offset + 1)
  }

  const generateLink = (slug: string, label: string) => {
    onFilter(
      name,
      {
        urlPosition,
        title,
        selected: [
          {
            title: label,
            slug,
          },
        ],
      },
      false,
      group
    )
  }

  const callFilter = (selectedObject: any) => {
    Object.keys(selectedObject).map((key) => {
      onFilter(
        key,
        {
          urlPosition: selectedObject[key][0]?.urlPosition,
          title: selectedObject[key][0]?.value,
          selected: selectedObject[key],
        },
        false,
        group
      )
    })
  }

  const callChildren = async (
    slug: string,
    selectedSlug: string,
    selectedObject: any,
    label = '',
    parentSlug = '',
    image = ''
  ) => {
    setCategorySelected(selectedSlug)
    if (slug) {
      try {
        setCategoryChildrenLoader(true)
        setCategorySelected(selectedSlug)
        setCategorySelectedObject(selectedObject)
        setSelectedChildName(label)
        setSelectedChildImage(image)

        const { data } = await fetchCategoriesChildren(slug)
        setSelectedParentSlug(parentSlug)

        if (data.childCategories.length === 0) {
          setChildrenShortArray([])
          setCategoryChildren([])
          setCategoryChildrenLoader(false)
          setMobileChildrenOpen(false)
          callFilter(selectedObject)
          return
        }

        setMobileChildrenOpen(true)

        if (data.childCategories.length > DEFAULT_SHOW_MORE_COUNT) {
          setChildrenShortArray(
            data.childCategories.slice(0, DEFAULT_SHOW_MORE_COUNT)
          )
          setOffset(offset + 1)
          setCategoryChildren(data.childCategories)
          setCategoryChildrenLoader(false)
          return
        }

        setChildrenShortArray(data.childCategories)
        setCategoryChildren(data.childCategories)
        setCategoryChildrenLoader(false)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        setCategoryChildrenLoader(false)
      }
      return
    }
    setSelectedChildName(label)
    setChildrenShortArray([])
    setCategoryChildren([])
    callFilter(selectedObject)
  }

  const getChildren = (categoryChildrenLoader && (
    <IconSvg className='mx-auto my-4 size-8' icon='loading' />
  )) || (
    <div className='mx-6'>
      <div className='my-4 lg:w-3/4'>
        {mobileChildrenOpen && (
          <Image
            className='mx-auto block h-28 w-auto grow object-contain py-0.5 md:hidden'
            src={selectedChildImage || ''}
            alt={selectedChildName}
            height={50}
            width={50}
          />
        )}
        <div className='mb-3 text-center text-lg md:text-left'>
          {selectedChildName}
        </div>
        <SearchInput
          onChange={(value: string) => searchChildCategory(value)}
          searchIconSize='12px'
          inputClassName='text-md !px-7 !py-1 mb-3'
          searchIconClassName='!left-1'
          cleaButtonClassName='h-5 w-5'
          containerProps={{
            className: 'h-7',
          }}
        />
      </div>
      <div className='grid grid-cols-3 gap-2 md:grid-cols-filter-category'>
        <Button
          onClick={() => callFilter(categorySelectedObject)}
          className={`background-white flex h-28 flex-col items-center p-0 capitalize !shadow-none`}
        >
          <Image
            className='h-2/3 w-auto grow object-contain py-0.5'
            src={
              (categoryChildren.length > 0 && categoryChildren[0].image) || ''
            }
            alt={selectedChildName}
            height={50}
            width={50}
          />
          <div
            className='w-full overflow-hidden text-ellipsis px-2 py-1.5 text-center text-xs font-normal'
            aria-label={selectedChildName}
          >
            {d('all_items', { category: `${selectedChildName}` })}
          </div>
        </Button>
        {childrenShortArray.map((category, index: number) => (
          <Button
            key={index}
            onClick={() => {
              setChildrenShortArray([])
              setCategoryChildren([])
              setCategorySelected('')
              setSelectedChildName('')
              callFilter(category.selected)
            }}
            className={`background-white flex h-28 flex-col items-center p-0 capitalize !shadow-none ${categorySelected === category.slug ? 'border-primary' : ''}`}
          >
            <Image
              className='h-2/3 w-auto grow object-contain py-0.5'
              src={category.image || ''}
              alt={category.label}
              height={50}
              width={50}
            />
            <div
              className='w-full overflow-hidden text-ellipsis px-2 py-1.5 text-center text-xs font-normal'
              aria-label={category.label}
            >
              {category.label}
            </div>
          </Button>
        ))}
      </div>
      {childrenShortArray.length < categoryChildren.length &&
        !isChildrenFiltered && (
          <Button
            variant='outlined'
            className='mx-auto mt-4 block border border-secondary leading-[150%] text-primary'
            onClick={onShowMore}
          >
            {d('show_more_btn')}
          </Button>
        )}
    </div>
  )

  const renderContent = (
    <div>
      <div className='mt-4'>
        <div className='mb-3 text-left text-lg'>
          {group?.categoryPopupTitle}
        </div>
        <SearchInput
          onChange={(value: string) => searchCategory(value)}
          searchIconSize='12px'
          inputClassName='text-md !px-7 !py-1 mb-3'
          searchIconClassName='!left-1'
          cleaButtonClassName='h-5 w-5'
          containerProps={{
            className: 'h-7',
          }}
        />
      </div>
      <div className='grid w-full gap-4'>
        {(filteredCategories?.length > 0 &&
          filteredCategories.map((item, index: number) => (
            <div key={index} className={`bg-white`}>
              <Button
                className={`background-white my-4 p-0 capitalize !shadow-none`}
                onClick={() =>
                  generateLink(
                    item?.selected[name][0]?.slug || item.slug,
                    item.label
                  )
                }
              >
                <div
                  className='px-2 py-1.5 text-left text-xl font-bold'
                  aria-label={item.label}
                >
                  {item.label}
                </div>
              </Button>
              <div className='grid grid-cols-filter-category gap-2'>
                <Button
                  onClick={() =>
                    generateLink(
                      item?.selected[name][0]?.slug || item.slug,
                      item.label
                    )
                  }
                  className={`background-white flex h-28 flex-col items-center p-0 capitalize !shadow-none`}
                >
                  <Image
                    className='h-2/3 w-auto grow object-contain py-0.5'
                    src={
                      (item.children.length > 0 && item.children[0].image) || ''
                    }
                    alt={item.label}
                    height={50}
                    width={50}
                  />
                  <div
                    className='w-full overflow-hidden text-ellipsis px-2 py-1.5 text-center text-xs font-normal'
                    aria-label={item.label}
                  >
                    {d('all_items', { category: `${item.label}` })}
                  </div>
                </Button>
                {item.children.map((category, index: number) => (
                  <Button
                    key={index}
                    onClick={() => {
                      setChildrenShortArray([])
                      callChildren(
                        category.slug,
                        category.selected[name][0]?.slug || category.slug,
                        category.selected,
                        category.label,
                        item.slug,
                        category.image
                      )
                    }}
                    className={`background-white flex h-28 flex-col items-center p-0 capitalize !shadow-none ${categorySelected === category.slug ? 'border border-primary' : ''}`}
                  >
                    <div key={index} className='relative h-2/3 w-auto'>
                      {categoryChildrenLoader &&
                        categorySelected === category.slug && (
                          <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
                            <IconSvg
                              className='mx-auto my-4 size-4'
                              icon='loading'
                            />
                          </div>
                        )}
                      <Image
                        className={`h-full w-full grow object-contain py-0.5 ${categoryChildrenLoader && categorySelected === category.slug ? 'opacity-50' : 'opacity-100'}`}
                        src={category.image || ''}
                        alt={category.label}
                        height={50}
                        width={50}
                      />
                    </div>
                    <div
                      className='w-full overflow-hidden text-ellipsis px-2 py-1.5 text-center text-xs font-normal'
                      aria-label={category.label}
                    >
                      {category.label}
                    </div>
                  </Button>
                ))}
              </div>
              {categoryChildren.length > 0 &&
                item.slug === selectedParentSlug && (
                  <>
                    <div className='hidden md:block'>{getChildren}</div>
                    <Drawer
                      open={mobileChildrenOpen}
                      onClose={() => setMobileChildrenOpen(!mobileChildrenOpen)}
                      className='block md:hidden'
                      overlayProps={{
                        className: '!fixed block md:hidden',
                      }}
                    >
                      <div className='max-h-full overflow-y-auto p-2'>
                        <Button
                          className='mb-3 flex w-full items-center justify-start gap-4 border-0 border-b px-0 py-3 text-left text-lg font-normal capitalize !shadow-none'
                          onClick={() => {
                            setMobileChildrenOpen(false)
                            setSelectedChildName('')
                            setSelectedChildImage('')
                            setCategorySelected('')
                            setCategorySelectedObject({})
                          }}
                          aria-label={d('filter_mobile_btn_close_aria_label')}
                        >
                          <Icon
                            className='block text-black'
                            size='15px'
                            icon='arrow-left'
                          />
                          {selectedChildName}
                        </Button>
                        {mobileChildrenOpen && getChildren}
                      </div>
                    </Drawer>
                  </>
                )}
            </div>
          ))) || <div>{d('no_categories')}</div>}
      </div>
    </div>
  )

  return (
    <>
      <Button
        aria-label={title}
        size='sm'
        onClick={toggleOffCanvas}
        loading={categoryLoader}
        className={`mr-4 flex h-8 max-w-none items-center justify-between gap-2 !overflow-visible border text-sm font-medium md:mr-3 md:gap-6 ${selected?.length > 0 ? 'border-primary' : 'border-border-color'} px-2 py-1 text-left capitalize !shadow-none`}
      >
        <span
          className={`whitespace-nowrap ${selected?.length > 0 ? 'text-primary' : 'text-secondary'}`}
        >
          {title}
        </span>
        <Icon
          className={`block ${selected?.length > 0 ? 'text-primary' : 'text-border-color'}`}
          size='12px'
          icon='arrow-down'
        />
      </Button>

      {showFilters && (
        <Drawer
          open={mobilePopupOpen}
          onClose={toggleOffCanvas}
          className='!max-w-xs md:!max-w-2xl lg:!max-w-5xl'
          overlayProps={{
            className: '!fixed',
          }}
        >
          <div className='max-h-full overflow-y-auto p-2'>
            <Button
              className='mb-3 flex w-full items-center justify-start gap-4 border-0 border-b px-0 py-3 text-left text-lg font-normal capitalize !shadow-none'
              onClick={toggleOffCanvas}
              aria-label={d('filter_mobile_btn_close_aria_label')}
            >
              <Icon
                className='block text-black'
                size='15px'
                icon='arrow-left'
              />
              {title}
            </Button>

            {mobilePopupOpen && renderContent}
          </div>
        </Drawer>
      )}
    </>
  )
}
