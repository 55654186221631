import { Component } from '@/types'
import { RequestBody, TrackingExternalUrlSpecificData } from './index'

export const getParameter = (
  urlKey: string,
  sessionKey: string,
  urlParams: URLSearchParams
): string => {
  if (undefined === sessionStorage) {
    return ''
  }
  const sessionValue = sessionStorage.getItem(sessionKey)
  if (sessionValue !== null) {
    return sessionValue
  }

  const urlValue = urlParams.get(urlKey)
  if (urlValue) {
    sessionStorage.setItem(sessionKey, urlValue)
    return urlValue
  }

  return ''
}

export const getSearchEngineItem = () => {
  let searchEngineItem = sessionStorage.getItem('ngSearchEngineItem')

  if (!searchEngineItem) {
    searchEngineItem = window.location.href
    sessionStorage.setItem('ngSearchEngineItem', searchEngineItem)
  }

  return searchEngineItem
}

export const getReferrerUrl = (): string => {
  if (sessionStorage.getItem('ngReferrerUrl') !== null) {
    return sessionStorage.getItem('ngReferrerUrl')!
  }

  if (!document.referrer) {
    sessionStorage.setItem('ngReferrerUrl', 'DIRECT')
    return 'DIRECT'
  }

  const referrerDomain: string = new URL(document.referrer).origin

  if (document.location.origin === referrerDomain) {
    sessionStorage.setItem('ngReferrerUrl', 'DIRECT')
    return 'DIRECT'
  }

  sessionStorage.setItem('ngReferrerUrl', referrerDomain)
  return referrerDomain
}

export const getOutfitData = (components: Array<any>) => {
  if (components) {
    const component = components.find(
      (component) =>
        component.builderComponent?.technicalName ===
          Component.TechnicalName.CREATIVE_DETAIL_OUTFIT &&
        component.config?.outfit
    )

    if (component) {
      return {
        id: component.config.outfit.id,
        userId: component.config.outfit.creator.id,
      }
    }
  }

  return false
}

export const getProductData = (components: Array<any>) => {
  if (components) {
    const component = components.find(
      (component) =>
        component.builderComponent?.technicalName ===
          Component.TechnicalName.CREATIVE_DETAIL_PRODUCT &&
        component.config?.product
    )

    if (component) {
      return {
        productId: component.config.product.id,
        shopId: component.config.product.shopId,
      }
    }
  }

  return false
}

export const ParseToUrlParamsString = (params: RequestBody): string => {
  return (
    '&additional_data[channelPageId]=' +
    params.channelPageId +
    '&additional_data[channelId]=' +
    params.channelId +
    '&additional_data[countryId]=' +
    params.countryId +
    '&additional_data[searchEngineItem]=' +
    params.searchEngineItem +
    '&additional_data[url]=' +
    window.location.href +
    '&additional_data[replaceParam][pattern]=ngo-cr'
  )
}

export const MakeReplaceParamPostfix = (params: RequestBody): string => {
  return (
    '&additional_data[replaceParam][postfix]=---cp' +
    params.channelPageId +
    '--ch' +
    params.channelId +
    (params.campaignId ? `--c${params.campaignId}` : '') +
    (params.postAdId ? `--pa${params.postAdId}` : '')
  )
}

const escapeSeparator = (str: string): string =>
  str.startsWith('&') ? str.substring(1) : str

export const BuildHref = (
  href: string,
  trackingParams: string = '',
  productParams: string = ''
): string => {
  const params = escapeSeparator(
    `${trackingParams}${productParams ? `&${escapeSeparator(productParams)}` : ''}`
  )
  return `${href}${params ? `?${params}` : ''}`
}

export const GetComponentTrackingSpecificData = (
  outfitId: number | string | undefined = '',
  was: number | string | undefined = ''
): TrackingExternalUrlSpecificData => {
  return { outfitId, was }
}
