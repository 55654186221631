'use client'

import React, { useState } from 'react'
import { Button } from '@material-tailwind/react'
import Alert from '@/components/Alert'
import { useDictionary } from '@/providers'
import FormProvider, {
  PASSWORD_REG_EXP,
  RHFPasswordField,
  useForm,
  Yup,
  yupResolver,
} from '@/components/HookForm'
import { useParams } from '@/hooks'
import { resetPassword } from '@/actions/auth'

type Props = {
  setSuccess: (success: boolean) => void
}

export default function ResetPasswordForm({ setSuccess }: Props) {
  const d = useDictionary('auth')
  const { email: emailParam = '' } = useParams()

  const [errorMsg, setErrorMsg] = useState('')

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string(),
    password: Yup.string()
      .required(d('password_required'))
      .matches(PASSWORD_REG_EXP, d('invalid_password')),
    confirmPassword: Yup.string()
      .required(d('confirm_password_required'))
      .oneOf([Yup.ref('password')], d('invalid_confirm_password')),
  })

  const defaultValues = {
    email: emailParam || '',
    password: '',
    confirmPassword: '',
  }

  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = handleSubmit(async (data) => {
    const res = await resetPassword(data)

    if (res?.success) {
      reset()
      setSuccess(true)
      setErrorMsg('')
    } else {
      setErrorMsg(res?.data?.errors[0])
      setSuccess(false)
    }
  })

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {errorMsg && (
        <Alert state='error' className='mb-4'>
          {errorMsg}
        </Alert>
      )}
      <div className='grid gap-4'>
        <RHFPasswordField
          name='password'
          variant='static'
          placeholder='********'
          label={d('password_input')}
        />

        <RHFPasswordField
          name='confirmPassword'
          variant='static'
          placeholder='********'
          label={d('confirm_password_input')}
        />
      </div>

      <Button
        fullWidth
        type='submit'
        loading={isSubmitting}
        className='mb-2 mt-4 flex w-full items-center justify-center bg-primary p-2 text-lg font-medium capitalize text-white !shadow-none'
      >
        {d('sign_in_btn')}
      </Button>
    </FormProvider>
  )
}
