'use client'

import { Filters } from '@/types'
import { useState, useEffect, useRef } from 'react'
import { Switch } from '@material-tailwind/react'
import HorizontalSelect from './HorizontalSelect'

type Props = {
  title: string
  name: string
  onFilter: Filters.onFilter
  onResetFilter: Filters.onResetFilter
  filter: Filters.SelectedData
  mobile?: boolean
  urlPosition?: number | null
  selected?: number
  group?: Filters.Group
}

export default function Checkbox({
  title,
  name,
  mobile = false,
  onFilter,
  onResetFilter,
  urlPosition = null,
  filter,
  group,
  selected = 0,
}: Props) {
  const [checked, setChecked] = useState(!!selected || false)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!Object.keys(filter)?.length && checked && inputRef.current) {
      inputRef.current.checked = false
      setChecked(false)
    }
  }, [filter?.selected])

  useEffect(() => {
    if (selected && inputRef.current) {
      inputRef.current.checked = true
    }
  }, [])

  const handleChange = (value: boolean) => {
    setChecked(value)

    if (value) {
      onFilter(
        name,
        {
          urlPosition: urlPosition || null,
          title,
          selected: value,
        },
        mobile,
        group
      )
    } else {
      onResetFilter(name, !mobile, group)
    }
  }

  const renderContent = (
    <Switch
      defaultChecked={checked}
      inputRef={inputRef}
      onChange={(e) => handleChange(e.target.checked)}
      ripple={false}
      className='right-0 h-full w-full checked:bg-tertiary-green'
      containerProps={{
        className: `${mobile ? 'w-10' : 'w-8'} h-4 right-1.5`,
      }}
      circleProps={{
        className: 'left-0 border-none',
      }}
      aria-label={title}
    />
  )

  if (mobile) {
    return (
      <div className='mb-4 mt-2 flex items-center justify-between'>
        <div
          className={`mb-2 mt-5 text-left text-base font-medium ${checked ? 'text-primary' : 'text-secondary'}`}
        >
          {title}
        </div>
        {renderContent}
      </div>
    )
  }

  return (
    <HorizontalSelect
      title={title}
      noDropdown={name === 'hasSale'}
      isSelected={checked}
    >
      {renderContent}
    </HorizontalSelect>
  )
}
