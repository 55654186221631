import { SitemapComponent } from '@/types'
import {
  SitemapHeader,
  SitemapContent,
  SitemapFooter,
} from '@/components/Sitemap'

export default function Sitemap({ pages, footer }: SitemapComponent.Config) {
  return (
    <>
      <SitemapHeader />
      <SitemapContent pages={pages} />
      <SitemapFooter
        links={footer.links}
        copyrightText={footer.copyrightText}
      />
    </>
  )
}
