import { Badge, Media, Product } from '@/types'

export type Outfit = {
  generatedDescription: string
  generatedDescriptionUpdatedByConfig: boolean
  id: number
  media: Media
  name: string
  subType: number
  outfitCategory: string
  productAttributes: string
  products: Array<Product.Product>
  badges?: Array<Badge.Badge>
  slug: string
  favorite: boolean
}

export type SimilarOutfits = {
  outfits: Array<Outfit>
  count: number
}

export enum RelatedOutfits {
  SIMILAR,
  PRODUCT,
}

export type InspirationOutfit = {
  name: string
  link: string
}

export const InspirationOutfitType = 10
