import Icon from '@/components/Icon'

const colors: { [type: number]: string } = {
  1: 'border-t-tertiary-red text-white',
  2: 'border-t-tertiary-red text-white',
  3: 'border-t-tertiary-red text-white',
}

const icons: { [type: number]: string } = {
  1: 'power',
}

type BadgeProps = {
  type: number
}

export default function BadgeIconItem({ type }: BadgeProps) {
  if (!icons[type]) return

  return (
    <div
      className={`relative flex h-0 w-0 flex-row items-center justify-center gap-1 border-r-[1.563rem] border-t-[2rem] border-r-transparent text-sm ${colors[type]}`}
    >
      <Icon
        icon={icons[type]}
        className='absolute -top-[1.625rem] left-[0.125rem] z-10 block'
        size='12px'
      />
    </div>
  )
}
