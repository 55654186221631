import { ReactNode } from 'react'
import { UseFormReturn, FormProvider as Form } from 'react-hook-form'

type Props = {
  children: ReactNode
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: UseFormReturn<any>
  onSubmit?: VoidFunction
}
export default function FormProvider({ children, onSubmit, methods }: Props) {
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit}>{children}</form>
    </Form>
  )
}
