import { Properties as PropertiesType } from '@/types'

type Props = {
  options: Array<PropertiesType.PropertyOptions>
}

export default function Size({ options }: Props) {
  return (
    <div className='flex flex-wrap gap-3'>
      {options.map((option, index) => (
        <div
          key={index}
          className='flex h-6 w-auto min-w-6 justify-center border border-light-gray-color p-1 text-left text-sm font-normal text-primary'
        >
          {option.value}
        </div>
      ))}
    </div>
  )
}
