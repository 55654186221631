import { Typography, Button } from '@material-tailwind/react'
import Icon from '@/components/Icon'
import Link from '@/components/Link'

type Props = {
  icon: string
  title: string
  description: string
  btn_text?: string
  link_to?: string
  className?: string
}

export default function SuccessMessage({
  icon,
  title,
  description,
  btn_text,
  link_to,
  className = '',
}: Props) {
  return (
    <section className={`bg-white px-4 py-8 md:px-0 md:py-16 ${className}`}>
      <div className='sm:m-auto sm:w-3/4 md:w-2/5 lg:w-1/3'>
        <Icon icon={icon} className='mb-10 block' size='120px' />

        <Typography as='p' variant='h2' className='mb-8'>
          {title}
        </Typography>

        <p
          className='mb-10 block text-xl font-normal leading-relaxed text-inherit antialiased'
          dangerouslySetInnerHTML={{ __html: description }}
        />

        {link_to && (
          <Link
            as={Button}
            href={link_to}
            className='m-auto mt-4 flex w-full max-w-sm items-center justify-center bg-primary p-2 text-lg font-medium capitalize text-white !shadow-none'
          >
            {btn_text}
          </Link>
        )}
      </div>
    </section>
  )
}
