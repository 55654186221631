import { Button, Typography } from '@material-tailwind/react'
import Icon from '@/components/Icon'
import { HeaderComponent } from '@/types'
import { useDictionary } from '@/providers'

type Props = {
  searchSuggestion: HeaderComponent.Search
  onClickSuggestion: (item: string) => void
}

export default function Suggestion({
  searchSuggestion,
  onClickSuggestion,
}: Props) {
  const d = useDictionary('search')

  if (!searchSuggestion?.topSearches?.length) {
    return (
      <div className='items-center p-5 text-center'>{d('resultNotFound')}</div>
    )
  }

  return (
    <>
      <Typography as='p' variant='h3' className='mb-2 px-2 font-medium'>
        {d('suggestionHeadline')}
      </Typography>
      <div className='flex flex-wrap items-start gap-2'>
        {searchSuggestion.topSearches.map((item, key) => (
          <Button
            onClick={() => {
              onClickSuggestion(item)
            }}
            key={key}
            className='align-center flex flex-nowrap justify-between gap-x-2 border-light-silver-color p-2 text-base font-normal capitalize text-black hover:opacity-100 md:hover:border-black'
            aria-label={item}
            variant='outlined'
            color='black'
          >
            <Icon size='20px' icon='fire' className='mr-1' />
            {item}
          </Button>
        ))}
      </div>
    </>
  )
}
