import { Error404Component } from '@/types'
import Error404 from '@/containers/sections/error404'

export default function generate({
  component,
}: {
  component: Error404Component.Component
}) {
  return (
    <Error404 text={component.config.text} title={component.config.title} />
  )
}
