'use client'

import { useState, useRef } from 'react'
import { Typography, IconButton } from '@material-tailwind/react'
import { Navigation } from 'swiper/modules'
import { SwiperSlide, Swiper, SwiperRef } from 'swiper/react'
import { HeaderComponent } from '@/types'
import Icon from '@/components/Icon'
import NavParents from './NavParents'
import NavChildren from './NavChildren'
import { useDictionary } from '@/providers'

type Props = {
  menuItems: Array<HeaderComponent.MenuItem>
  closeOffCanvas: () => void
}

export default function OffCanvas({ menuItems, closeOffCanvas }: Props) {
  const d = useDictionary('header')
  const [selectedMenuItem, setSelectedMenuItem] =
    useState<HeaderComponent.MenuItem | null>(null)
  const swiperRef = useRef<SwiperRef | null>(null)

  const sliderProps = {
    slidesPerView: 1,
    modules: [Navigation],
    centerInsufficientSlides: true,
    scrollbar: { draggable: false },
    allowTouchMove: false,
    navigation: {
      nextEl: '.nav-manu-offcanvas-open-child',
      enabled: true,
      draggable: false,
      disabledClass: 'hidden',
    },
  }

  const onClickBackButton = () => {
    swiperRef.current?.swiper.slidePrev()
    setSelectedMenuItem(null)
  }

  return (
    <>
      <div className='flex items-center justify-between p-2'>
        <div className='flex items-center'>
          {selectedMenuItem && (
            <IconButton variant='text' onClick={onClickBackButton}>
              <Icon
                className='block text-black'
                size='15px'
                icon='arrow-left'
              />
            </IconButton>
          )}
          <Typography
            as='p'
            variant='h5'
            className={selectedMenuItem ? 'pl-1' : 'pl-5'}
          >
            {selectedMenuItem ? selectedMenuItem.label : d('menuTitle')}
          </Typography>
        </div>
        <IconButton variant='text' onClick={closeOffCanvas}>
          <Icon className='h-5 w-5 text-black' size='15px' icon='cross' />
        </IconButton>
      </div>
      <Swiper className='relative' {...sliderProps} ref={swiperRef}>
        <SwiperSlide>
          <NavParents
            menuItems={menuItems}
            selectItem={(item) => setSelectedMenuItem(item)}
          />
        </SwiperSlide>
        <SwiperSlide>
          {selectedMenuItem && <NavChildren menuItem={selectedMenuItem} />}
        </SwiperSlide>
      </Swiper>
    </>
  )
}
