import { ProfileComponent } from '@/types'
import Profile from '@/containers/sections/profile'

export default function generate({
  component,
}: {
  component: ProfileComponent.Component
}) {
  const { firstName, lastName, email, faq, profileImage, provider } =
    component.config
  /* provider code meanings
    1 = internal
    2 = google
    3 = facebook
  */
  return (
    <Profile
      firstName={firstName}
      lastName={lastName}
      profileImage={profileImage}
      email={email}
      faq={faq}
      provider={provider}
    />
  )
}
