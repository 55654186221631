import { useState } from 'react'
import Icon from '@/components/Icon'

type FakeCheckboxProps = {
  checked?: boolean
  ripple?: boolean
  className?: string
  label?: string
  onChange: (checked: boolean) => void
  labelProps?: any
  inputProps?: any
  iconProps?: any
}

const FakeCheckbox: React.FC<FakeCheckboxProps> = ({
  checked = false,
  ripple = false,
  className = '',
  label = '',
  onChange,
  inputProps = {},
  labelProps = {},
  iconProps = {},
}) => {
  const [isChecked, setIsChecked] = useState(checked)

  const handleClick = () => {
    const newChecked = !isChecked
    setIsChecked(newChecked)
    onChange(newChecked)
  }

  const { className: inputClassName = '', ...restInputProps } = inputProps
  const { className: labelClassName = '', ...restLabelProps } = labelProps
  const { className: iconClassName = '', ...restIconProps } = iconProps

  return (
    <div
      className={`flex w-full cursor-pointer items-center px-0.5 py-2 ${className}`}
      onClick={handleClick}
    >
      <div
        className={`mr-3 flex h-5 w-5 cursor-pointer items-center justify-center rounded-none border border-solid border-primary ${isChecked ? 'bg-primary text-white' : 'bg-transparent'} ${inputClassName} ${ripple ? 'ripple-effect' : ''}`}
        {...restInputProps}
      >
        {isChecked && (
          <Icon
            className={`block ${iconClassName}`}
            icon='check'
            size='11px'
            {...restIconProps}
          />
        )}
      </div>
      {label && (
        <div
          className={`break-all text-primary ${labelClassName}`}
          {...restLabelProps}
        >
          {label}
        </div>
      )}
    </div>
  )
}

export default FakeCheckbox
