import { StaticPageComponent } from '@/types'
import StaticPage from '@/containers/sections/static-page'

export default function generate({
  component,
}: {
  component: StaticPageComponent.Component
}) {
  return (
    <StaticPage
      content={component.config.content}
      sidebar={component.config.sidebar}
    />
  )
}
