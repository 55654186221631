import { Filters } from '@/types'
import Order from '@/components/Filters/components/order'

export default function generate({
  component,
  filter,
  group,
  onFilter,
  onResetFilter,
  onShowMoreFilters,
  mobile = false,
  horizontal = false,
}: {
  component: Filters.FilterItemOrder
  filter: Filters.SelectedData
  group?: Filters.Group
  onFilter: Filters.onFilter
  onResetFilter: Filters.onResetFilter
  onShowMoreFilters: Filters.onShowMoreFilters
  mobile?: boolean
  horizontal?: boolean
}) {
  return (
    <Order
      title={component.title}
      name={component.name}
      items={component.values}
      mainContentFilter={component.mainContentFilter}
      onFilter={onFilter}
      onResetFilter={onResetFilter}
      onShowMoreFilters={onShowMoreFilters}
      mobile={mobile}
      horizontal={horizontal}
      selected={component.selected}
      filter={filter}
      group={group}
    />
  )
}
