import { useState } from 'react'
import { Typography, Button } from '@material-tailwind/react'
import { useDictionary } from '@/providers'

export type Props = {
  description: string
}

export default function ProductCard({ description }: Props) {
  const d = useDictionary('product')
  const [isOpen, setIsOpen] = useState(false)

  if (description === '') {
    return <></>
  }

  return (
    <Typography
      variant='h5'
      className={`flex ${
        !isOpen && 'line-clamp-[2]'
      } gap-2 overflow-hidden text-ellipsis px-2 pb-4 pt-0 text-left text-sm font-normal text-secondary`}
    >
      <span className={`${!isOpen && 'line-clamp-[2]'} leading-normal`}>
        {description}
      </span>
      {!isOpen && (
        <Button
          onClick={() => {
            setIsOpen(true)
          }}
          className='m-0 !overflow-visible p-0 text-left text-xs font-normal lowercase text-secondary underline !shadow-none'
        >
          {d('see_more')}
        </Button>
      )}
    </Typography>
  )
}
