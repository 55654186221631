import Image from '@/components/Image'
import { Pagination } from 'swiper/modules'
import { Media } from '@/types'
import Slider, {
  SliderItem,
  SliderProps,
  breakpoints,
} from '@/components/Slider'

type Props = {
  medias: Array<Media>
  alt: string
}

export function ImageGallery({ medias, alt }: Props) {
  const sliderProps: SliderProps = {
    modules: [Pagination],
    slidesPerView: 1,
    navigation: {
      enabled: false,
    },
    pagination: {
      enabled: true,
      clickable: true,
    },
    enabled: true,
    breakpoints: {
      [breakpoints.lg]: {
        enabled: false,
        slidesPerView: 'auto' as const,
        pagination: {
          enabled: false,
        },
      },
    },
  }

  const singleImage = medias.length < 2

  return (
    <Slider
      className={`relative overflow-visible lg:[&>*]:grid ${singleImage ? 'lg:[&>*]:grid-cols-1' : 'lg:[&>*]:grid-cols-2'} lg:[&>*]:!transform lg:[&>*]:gap-1`}
      {...sliderProps}
    >
      {medias.map((media, index) => (
        <SliderItem key={index}>
          <div
            className={`relative ${singleImage ? 'pt-[126%] md:pt-[111%]' : 'pt-[133%] md:pt-[110%] lg:pt-[144%]'}`}
          >
            <Image
              key={index}
              className='creative-img-box absolute inset-x-0 inset-y-0 h-full w-full object-scale-down'
              src={media?.imageUrl}
              data-key={media?.key}
              data-bucket={media?.bucket}
              data-id={media?.id}
              alt={alt}
              width={360}
              height={480}
            />
          </div>
        </SliderItem>
      ))}
    </Slider>
  )
}
