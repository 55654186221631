import { StaticTextComponent } from '@/types'

export default function StaticText({ content }: StaticTextComponent.Config) {
  return (
    <section
      className='container mb-12 text-left'
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}
