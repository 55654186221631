import { SitemapComponent } from '@/types'
import { Typography } from '@material-tailwind/react'
import Link from '@/components/Link'
import { useDictionary } from '@/providers'

type Props = {
  pages: SitemapComponent.Pages
}

export function SitemapContent({ pages }: Props) {
  const d = useDictionary('sitemap')

  return (
    <div className='container'>
      <div className='pt-2 text-left text-lg'>
        <Link href='/' className='font-medium'>
          {d('homePageLabel')}
        </Link>
        <span> &gt; </span>
        <span>{d('pageName')}</span>
        <span className='px-1 text-sm font-normal'>/</span>
        <span>{d('allCategories')}</span>
      </div>
      {Object.keys(pages).map((title: string) => (
        <div key={title}>
          <Typography
            as='p'
            variant='h2'
            className='mt-4 border-b border-gray-600 py-2 text-left text-2xl font-semibold text-primary'
          >
            <Link href={pages[title].url}>{pages[title].name}</Link>
          </Typography>
          <div className='mt-2 grid grid-cols-2 gap-x-4 text-start md:grid-cols-3'>
            {pages[title].subs?.map((pageLink, index) => (
              <div key={index}>
                <Link
                  href={pageLink.url}
                  className='text-xs font-medium text-black'
                >
                  {pageLink.name}
                </Link>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
