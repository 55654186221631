import {
  request,
  HttpRequestParams,
  API_SIMILAR_PRODUCTS,
  API_PRODUCT_OUTFITS,
} from '@/utils/http-request'
import { Product, Outfit } from '@/types'

interface inspirationParams extends HttpRequestParams {
  subType: number | undefined
  tagKey: string
  propertyValues: number[] | undefined
}

interface SimilarProductsRequestParams extends HttpRequestParams {
  id: number
  limit?: number
  offset?: number
  inspirationParams?: inspirationParams
}

export const fetchSimilarProducts = (
  id: number,
  offset?: number,
  limit?: number,
  inspiration?: inspirationParams
) => {
  const requestParams: SimilarProductsRequestParams = {
    id,
  }

  if (offset !== undefined) {
    requestParams.offset = offset
  }
  if (limit !== undefined) {
    requestParams.limit = limit
  }

  if (inspiration !== undefined) {
    requestParams.inspirationParams = inspiration
  }

  return request<Product.SimilarProducts>(API_SIMILAR_PRODUCTS, requestParams)
}

interface ProductOutfitsRequestParams extends HttpRequestParams {
  id: number
  offset: number
  limit?: number
}

export const fetchProductOutfits = (
  id: number,
  offset: number,
  limit: number
) => {
  const requestParams: ProductOutfitsRequestParams = {
    id,
    offset,
  }

  if (limit !== undefined) {
    requestParams.limit = limit
  }

  return request<Outfit.SimilarOutfits>(API_PRODUCT_OUTFITS, requestParams)
}
