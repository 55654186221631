export const NG_PAGE_VIEW_ACTIVITY_TYPE = '5'
export const NG_WAS_OUTFIT_LISTING_PRODUCT_BOX = '1'
export const NG_WAS_OUTFIT_LISTING_PRODUCT_SIMILARITY_BOX = '2'
export const NG_WAS_OUTFIT_DETAIL_PRODUCT_BOX = '3'
export const NG_WAS_OUTFIT_DETAIL_PRODUCT_SIMILARITY_BOX = '4'
export const NG_WAS_OUTFIT_DETAIL_SIMILAR_PRODUCT_BOX = '5'
export const NG_WAS_OUTFIT_DETAIL_SIMILAR_PRODUCT_SIMILARITY_BOX = '6'
export const NG_WAS_PRODUCT_DETAIL_SIMILAR_PRODUCTS_LISTING = '7'
export const NG_WAS_PRODUCT_DETAIL = '8'
export const NG_WAS_PRODUCT_LISTING = '9'
export const NG_WAS_COMBINATION_OUTFIT_PRODUCT_BOX = '10'
export const NG_WAS_COMBINATION_OUTFIT_PRODUCT_SIMILARITY_BOX = '12'
export const NG_WAS_SEARCH = '12'
