import { Typography } from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import Accordion from '@/components/Accordion'
import Link from '@/components/Link'
import ShareButtons from '@/components/ShareButtons'
import { Brand, ProductCategory } from '@/types/Components/OutfitDetail'

type Props = {
  description: string
  categories: Array<ProductCategory>
  brands: Array<Brand>
  shareDescription: string
  shareMediaUrl: string
}

export function OutfitTabs({
  description,
  categories,
  brands,
  shareDescription,
  shareMediaUrl,
}: Props) {
  const d = useDictionary('outfit_detail')

  return (
    <div className='mt-6'>
      {description && (
        <Accordion title={d('tabs.description')}>{description}</Accordion>
      )}

      {!!(categories && categories?.length) && (
        <Accordion title={d('tabs.category')}>
          <div className='flex flex-wrap gap-1'>
            {categories.map((item, index) => (
              <Link
                key={index}
                href={item.url}
                title={item.name}
                className='font-base flex items-center justify-center border border-solid border-light-gray-color bg-white px-2 py-1.5'
              >
                {item.name}
              </Link>
            ))}
          </div>
        </Accordion>
      )}

      {!!(brands && brands?.length) && (
        <Accordion title={d('tabs.brand')}>
          <div className='flex flex-wrap gap-1'>
            {brands.map((item, index) => (
              <Link
                key={index}
                href={item.url}
                title={item.name}
                className='font-base flex items-center justify-center border border-solid border-light-gray-color bg-white px-2 py-1.5'
              >
                {item.name}
              </Link>
            ))}
          </div>
        </Accordion>
      )}

      <div className='flex items-center justify-between gap-3 bg-body-bg-secondary px-4 py-2'>
        <Typography
          variant='lead'
          className='text-left text-sm font-normal text-primary'
        >
          {d('tabs.share')}
        </Typography>

        <div className='flex gap-2.5'>
          <ShareButtons
            description={shareDescription}
            mediaUrl={shareMediaUrl}
          />
        </div>
      </div>
    </div>
  )
}
