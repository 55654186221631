import { Fragment } from 'react'
import { preload } from 'react-dom'
import { Typography } from '@material-tailwind/react'
import {
  Product,
  Breadcrumb,
  Filters as Facets,
  ProductListingComponent as Listing,
  Suggestions as SuggestionsType,
} from '@/types'
import { usePage, useDictionary } from '@/providers'
import Breadcrumbs from '@/components/Breadcrumbs'
import ProductCard from '@/components/ProductCard'
import Filters, {
  generateFilterInitialState,
  useFilters,
} from '@/components/Filters'
import Suggestions from '@/components/Suggestions'
import SuggestedCategories from '@/components/SuggestedCategories'
import Pagination from '@/components/Pagination'
import StaticDescription from '@/components/StaticDescription'
import {
  GetComponentTrackingSpecificData,
  NG_WAS_PRODUCT_LISTING,
} from '@/components/NgTracker'

type Props = {
  componentId: number
  products: Array<Product.Product>
  count: number
  limit: number
  displayFilter: boolean
  displayPagination: boolean
  breadcrumb: Array<Breadcrumb>
  facets: Facets.Facets
  mainContentSuggestions: Array<SuggestionsType.Suggestions>
  suggestions: SuggestionsType.Suggestions
  mainContentOnTop: Array<Listing.Category>
  page?: number
  description?: string
}

export default function ProductListing({
  componentId,
  products,
  count,
  limit,
  page,
  displayPagination,
  displayFilter,
  breadcrumb,
  facets,
  suggestions,
  mainContentSuggestions,
  mainContentOnTop,
  description,
}: Props) {
  const d = useDictionary('product_listing')
  const { pages, metaInformation } = usePage()

  const initialFilterState = generateFilterInitialState([
    ...(facets?.top || []),
    ...(facets?.left || []),
  ])

  const {
    filters,
    onFilter,
    resetFilter,
    resetAllFilters,
    applyFilters,
    filterCount,
    showMoreFilters,
  } = useFilters(initialFilterState, componentId, pages)

  const getSuggestionIndex = (i: number): number => Math.ceil((i + 1) / 8) - 1
  const componentTrackingSpecificData = GetComponentTrackingSpecificData(
    '',
    NG_WAS_PRODUCT_LISTING
  )

  if (products?.length > 0) {
    const firstImageUrl = products[0].media?.imageUrl
    if (firstImageUrl !== '') {
      preload(firstImageUrl, {
        as: 'image',
        fetchPriority: 'high',
      })
    }
  }

  return (
    <section className='container mb-8 overflow-hidden'>
      <Breadcrumbs breadcrumbs={breadcrumb} />
      <Typography
        variant='h1'
        className='mb-4 mt-2 text-left text-2xl font-semibold text-primary md:mb-8 md:mt-4'
      >
        {metaInformation?.h1 || d('title')}
      </Typography>
      {mainContentOnTop.length > 0 && (
        <SuggestedCategories array={mainContentOnTop} />
      )}
      <div className='mb-2 flex items-center justify-between'>
        <Typography
          id='listing-result-count'
          data-value={count}
          variant='paragraph'
          className='py-1.5 text-left text-sm opacity-80'
        >
          {d('items', { count: `${count}` })}
        </Typography>

        {displayFilter && (
          <Filters
            variant='mobile'
            components={[...(facets?.top || []), ...(facets?.left || [])]}
            filters={filters}
            filterCount={filterCount.total}
            onFilter={onFilter}
            onApplyFilters={applyFilters}
            onResetFilter={resetFilter}
            onResetAllFilter={resetAllFilters}
            onShowMoreFilters={showMoreFilters}
            className='lg:hidden'
          />
        )}
      </div>
      {suggestions.links && suggestions.links.length > 0 && (
        <Suggestions list={suggestions.links} title={suggestions.groupTitle} />
      )}
      <div className='lg:grid lg:grid-cols-4 lg:gap-6'>
        {displayFilter && (
          <Filters
            variant='vertical'
            components={facets?.left}
            filters={filters}
            filterCount={filterCount.total}
            onFilter={onFilter}
            onResetFilter={resetFilter}
            onResetAllFilter={resetAllFilters}
            onShowMoreFilters={showMoreFilters}
            className='hidden lg:flex'
          />
        )}
        <div className={`${displayFilter ? 'lg:col-span-3' : 'lg:col-span-4'}`}>
          {displayFilter && (
            <Filters
              variant='horizontal'
              components={facets?.top}
              filters={filters}
              filterCount={filterCount.total}
              onFilter={onFilter}
              onResetFilter={resetFilter}
              onResetAllFilter={resetAllFilters}
              onShowMoreFilters={showMoreFilters}
              className='hidden lg:flex'
            />
          )}
          <div className='mt-4 grid grid-cols-2 gap-4 md:basis-2/4 md:grid-cols-4'>
            {(products.length > 0 &&
              products.map((product: Product.Product, index: number) => (
                <Fragment key={index}>
                  <ProductCard
                    id={product.id}
                    href={product.link}
                    slug={product.slug}
                    mediaUrl={product.media?.imageUrl}
                    mediaAlt={product.name}
                    mediaBucket={product.media?.bucket}
                    mediaKey={product.media?.key}
                    title={product.name}
                    generatedDescription={product.generatedDescription}
                    shop={product.shop}
                    price={product.price}
                    hasSale={product.hasSale}
                    previousPrice={product.previousPrice}
                    available={product.available}
                    badges={product?.badges}
                    shopId={product.shopId}
                    className={`max-w-full border border-transparent hover:border-light-silver-color`}
                    imageProps={{
                      priority: index === 0,
                      loading: index === 0 ? 'eager' : 'lazy',
                    }}
                    componentTrackingSpecificData={
                      componentTrackingSpecificData
                    }
                  />
                  {((index + 1) % 8 === 0 || index === products.length - 1) &&
                    mainContentSuggestions[getSuggestionIndex(index)] !==
                      undefined && (
                      <Suggestions
                        mainContent={true}
                        list={
                          mainContentSuggestions[getSuggestionIndex(index)]
                            ?.links
                        }
                        title={
                          mainContentSuggestions[getSuggestionIndex(index)]
                            ?.groupTitle
                        }
                        className={`col-span-2 md:col-span-4`}
                      />
                    )}
                </Fragment>
              ))) ||
              d('no_products_found')}
          </div>
          {displayPagination && (
            <Pagination
              activePage={page ?? 1}
              totalItemCount={count}
              itemsPerPage={limit}
            />
          )}
        </div>
      </div>
      {description && <StaticDescription content={description} />}
    </section>
  )
}
