'use client'
import React, { useState } from 'react'

import { Menu, MenuHandler, MenuList, Button } from '@material-tailwind/react'
import Icon from '@/components/Icon'
import IconSvg from '@/components/IconSvg'

type Props = {
  title: string
  children: React.ReactNode
  filterCount?: number
  className?: string
  noDropdown?: boolean
  isSelected?: boolean
  handleScroll?: (e: any) => void
  onClick?: () => void
  loading?: boolean
}

export default function HorizontalSelect({
  title,
  children,
  filterCount = 0,
  className = '',
  noDropdown = false,
  isSelected = false,
  loading = false,
  handleScroll = () => {},
  onClick = () => {},
}: Props) {
  const [open, setOpen] = useState<boolean>(false)

  const getLabel = (title: string) => {
    return (
      <div className='mr-4 flex items-center justify-between'>
        <div
          className={`text-sm ${filterCount > 0 || isSelected ? 'text-primary' : 'text-secondary'}`}
        >
          {title}
        </div>
        {filterCount > 0 && (
          <div className='ml-2 flex h-4 w-4 items-center justify-center rounded-full bg-primary text-xs text-white'>
            {filterCount}
          </div>
        )}
      </div>
    )
  }

  const toggleOpenMenu = async () => {
    await onClick()
    setOpen(!open)
  }

  return noDropdown ? (
    <div
      className={`flex h-8 min-w-24 items-center justify-between border text-sm font-medium ${isSelected ? 'border-primary' : 'border-border-color'} px-2 py-1`}
    >
      <div>{getLabel(title)}</div>
      {children}
    </div>
  ) : (
    <div className={`flex gap-2 ${className}`}>
      <Menu
        placement='bottom-start'
        open={open}
        handler={toggleOpenMenu}
        dismiss={{
          itemPress: false,
          bubbles: false,
          escapeKey: true,
        }}
      >
        <MenuHandler>
          <Button
            className={`flex h-8 items-center justify-between border text-sm font-medium ${filterCount > 0 || isSelected ? 'border-primary' : 'border-border-color'} px-2 py-1 text-left capitalize !shadow-none`}
            aria-label={title}
          >
            {getLabel(title)}
            {loading ? (
              <IconSvg className='size-4' icon='loading' />
            ) : (
              <Icon
                className={`block ${filterCount > 0 || isSelected ? 'text-primary' : 'text-border-color'}`}
                size='12px'
                icon='arrow-down'
              />
            )}
          </Button>
        </MenuHandler>
        <MenuList
          className='max-h-64 rounded-none focus-visible:outline-none'
          onScroll={handleScroll}
        >
          <>{children}</>
        </MenuList>
      </Menu>
    </div>
  )
}
