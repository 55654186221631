import React, { HTMLAttributeAnchorTarget } from 'react'
import Image from '@/components/Image'
import Link from '@/components/Link'
import FakeLink from '@/components/FakeLink'
import { Card, CardHeader, CardBody } from '@material-tailwind/react'
import { Badge } from '@/types'
import { Badges } from '@/components/Badges'

type Props = {
  id: number
  href?: string
  title: string
  hasSale: boolean
  previousPrice: string
  target: HTMLAttributeAnchorTarget
  mediaUrl: string
  mediaAlt: string
  mediaKey: string
  mediaBucket: string
  isActive: boolean
  fakeLink: boolean
  badges?: Array<Badge.Badge>
  className?: string
}

export default function VariantItem({
  id,
  href,
  title,
  hasSale,
  previousPrice,
  target,
  mediaUrl,
  mediaAlt,
  mediaKey,
  mediaBucket,
  isActive,
  badges = [],
  fakeLink = false,
  className = '',
}: Props) {
  const LinkComponent = fakeLink ? FakeLink : Link

  return (
    <Card
      color='transparent'
      shadow={false}
      className={`w-full max-w-[6rem] cursor-pointer rounded-none border border-transparent hover:border-light-silver-color ${
        isActive ? 'is-active border-light-silver-color' : ''
      } ${className}`}
    >
      <CardHeader
        floated={false}
        shadow={false}
        color='transparent'
        className='m-0 rounded-none'
      >
        <LinkComponent
          href={href}
          target={target}
          nofollow
          noopener
          className='block'
        >
          {mediaUrl && (
            <Image
              data-key={mediaKey}
              data-bucket={mediaBucket}
              data-id={id}
              className='creative-img-box h-20 w-full object-contain object-center'
              src={mediaUrl}
              title={mediaAlt}
              alt={mediaAlt}
              width={80}
              height={80}
            />
          )}
          {!!(badges && badges?.length) && <Badges badges={badges} isIcon />}
        </LinkComponent>
      </CardHeader>
      {title && (
        <CardBody className='mt-2 p-0'>
          <LinkComponent
            href={href}
            target={target}
            nofollow
            noopener
            title={title}
            className={`${hasSale ? 'text-tertiary-red' : 'text-primary'} block text-center text-[10px] font-normal leading-4`}
          >
            {hasSale && (
              <span className='block font-light text-primary line-through opacity-90'>
                {previousPrice}
              </span>
            )}
            {title}
          </LinkComponent>
        </CardBody>
      )}
    </Card>
  )
}
