'use client'

import React, { useState, useEffect } from 'react'
import { Typography } from '@material-tailwind/react'
import Slider, { SliderItem, breakpoints } from '@/components/Slider'
import { LastSeenComponent, Outfit, Product } from '@/types'
import { LastSeenRequestParams } from '@/actions/last-seen'
import OutfitCard from '@/components/OutfitCard'
import ProductCard from '@/components/ProductCard'
import { fetchLastSeen } from '@/actions/last-seen'
import { useDictionary } from '@/providers'
import Cookies from 'js-cookie'

export default function LastSeen() {
  const d = useDictionary('last_seen')
  const [outfits, setOutfits] = useState<Array<Outfit.Outfit>>([])
  const [products, setProducts] = useState<Array<Product.Product>>([])
  const getLastSeenCookies = (): Array<LastSeenComponent.Item> => {
    const cookies = Cookies.get('lastSeen') ?? ''
    if (cookies) {
      try {
        return JSON.parse(cookies)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        /* empty */
      }
    }
    return []
  }
  const getLastSeen = async () => {
    const lastSeen = getLastSeenCookies()
    if (!lastSeen.length) {
      return
    }
    const params: LastSeenRequestParams = { outfit: [], product: [] }
    lastSeen.forEach((item: LastSeenComponent.Item) => {
      if (item.type === 'outfit') {
        params.outfit.push(item.slug)
        return
      }
      params.product.push(item.slug)
    })

    try {
      const { data } = await fetchLastSeen(params)

      setOutfits(data.outfit)
      setProducts(data.product)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      /* empty */
    }
  }

  useEffect(() => {
    getLastSeen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sliderProps = {
    slidesPerView: 1,
    spaceBetween: 20,
    grabCursor: true,
    centerInsufficientSlides: true,
    noSwipingSelector:
      '.card-outfits-similar-products-slider, .card-outfits-variant-slider',
    breakpoints: {
      [breakpoints.sm]: {
        slidesPerView: 2.2,
      },
      [breakpoints.md]: {
        slidesPerView: 3.3,
      },
      [breakpoints.lg]: {
        slidesPerView: 4.4,
      },
      [breakpoints.xl]: {
        slidesPerView: 4.4,
      },
      [breakpoints.xxl]: {
        slidesPerView: 4,
      },
    },
  }

  return (
    (outfits?.length > 0 || products?.length > 0) && (
      <section className={`overflow-hidden py-16`}>
        <div className='container'>
          <Typography
            as='p'
            variant='h2'
            className='outfit-slider-headline mb-6 text-left text-3xl font-semibold text-primary underline underline-offset-2'
          >
            {d('title')}
          </Typography>
          <Slider
            className='!overflow-visible'
            navNextClassName='sm:right-[9.2%] 2xl:right-0'
            {...sliderProps}
          >
            {outfits?.map((outfit, index) => (
              <SliderItem key={index}>
                <OutfitCard
                  id={outfit.id}
                  slug={outfit.slug}
                  title={outfit.name}
                  mediaUrl={outfit.media?.imageUrl}
                  mediaAlt={outfit.generatedDescription ?? outfit.name}
                  mediaBucket={outfit.media?.bucket}
                  mediaKey={outfit.media?.key}
                  products={outfit.products}
                  badges={outfit?.badges}
                  favorite={outfit.favorite}
                />
              </SliderItem>
            ))}
            {products?.map((product, index) => (
              <SliderItem key={index}>
                <ProductCard
                  key={index}
                  id={product.id}
                  href={product.link}
                  slug={product.slug}
                  generatedDescription={product.generatedDescription}
                  shop={product.shop}
                  mediaUrl={product.media?.imageUrl}
                  mediaAlt={product.generatedDescription ?? product.name}
                  mediaBucket={product.media?.bucket}
                  mediaKey={product.media?.key}
                  title={product.name}
                  price={product.price}
                  hasSale={product.hasSale}
                  previousPrice={product.previousPrice}
                  available={product.available}
                  badges={product?.badges}
                  shopId={product.shopId}
                  inLastSeen
                  showSimilar
                  favorite={product.favorite}
                  className='max-w-full border border-transparent hover:border-light-silver-color'
                />
              </SliderItem>
            ))}
          </Slider>
        </div>
      </section>
    )
  )
}
