'use client'

import { useState, useEffect } from 'react'
import { Filters } from '@/types'

interface UseFilterSearchResult {
  filteredData: Filters.FilterItem[]
  applyFilterSearch: (search: string) => void
}

export const useFilterSearch = (
  inputData: Filters.FilterItem[]
): UseFilterSearchResult => {
  const [filteredData, setFilteredData] =
    useState<Filters.FilterItem[]>(inputData)

  useEffect(() => {
    setFilteredData(inputData)
  }, [inputData])

  const applyFilterSearch = (search: string) => {
    const searchRecursively = (
      data: Filters.FilterItem[],
      searchString: string
    ): Filters.FilterItem[] => {
      return data.reduce(
        (acc: Filters.FilterItem[], item: Filters.FilterItem) => {
          const itemName = item.hasOwnProperty('title')
            ? item.title
            : item.value
          const match = itemName
            .toLowerCase()
            .includes(searchString.toLowerCase())

          if (match && !item.children) {
            acc.push(item)
          } else if (item.children) {
            const filteredChildren = searchRecursively(
              item.children,
              searchString
            )

            if (match || filteredChildren.length > 0) {
              acc.push({
                ...item,
                children: filteredChildren,
              })
            }
          }

          return acc
        },
        []
      )
    }

    if (!search) {
      setFilteredData(inputData)
    } else {
      const filteredResult = searchRecursively(inputData, search)
      setFilteredData(filteredResult)
    }
  }

  return { filteredData, applyFilterSearch }
}
