import { FavoriteComponent } from '@/types'
import Favorite from '@/containers/sections/favorite'
import { FAVORITE_OUTFIT_CODE, FAVORITE_PRODUCT_CODE } from '@/utils/api'

export default function generate({
  component,
}: {
  component: FavoriteComponent.Component
}) {
  return (
    <Favorite
      outfits={component.config[FAVORITE_OUTFIT_CODE]}
      products={component.config[FAVORITE_PRODUCT_CODE]}
    />
  )
}
