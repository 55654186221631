'use client'

import React, { useState } from 'react'
import { Typography } from '@material-tailwind/react'
import IconSvg from '@/components/IconSvg'
import Icon from '@/components/Icon'
import UploadPhotoButton from '@/components/Profile/UploadPhotoButton'
import Image from '@/components/Image'

type Props = {
  profileImage: string
  firstName: string
  lastName: string
  classNameImage?: string
  classNameTitle?: string
  settingNewProfileImage: (picture: string) => void
}

export default function ProfileImageWithName({
  profileImage,
  firstName,
  lastName,
  settingNewProfileImage,
  classNameImage = '',
  classNameTitle = '',
}: Props) {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[]>([])

  return (
    <div className='px-4'>
      <div
        className={`relative flex h-32 w-32 items-center justify-center rounded-full bg-light-gray-color ${classNameImage}`}
      >
        {(profileImage && (
          <Image
            className='h-32 w-32 rounded-full object-cover object-center'
            src={profileImage}
            alt='profile-image'
            width={50}
            height={50}
          />
        )) || <Icon icon='user2' className='text-6xl' />}
        {loading && (
          <IconSvg className='absolute h-8 w-8 fill-white' icon='loading' />
        )}
        <UploadPhotoButton
          uploadLoading={(_loading: boolean) => setLoading(_loading)}
          settingErrors={(_errors: string[]) => setErrors(_errors)}
          settingProfileImage={(_picture: string) =>
            settingNewProfileImage(_picture)
          }
        />
      </div>
      <Typography
        as='p'
        variant='h5'
        className={`mb-6 mt-8 font-semibold text-primary ${classNameTitle}`}
      >
        {firstName} {lastName}
      </Typography>
      <ul className='mb-6 text-left text-sm font-normal text-tertiary-red'>
        {errors?.map((error, key) => (
          <li className='mt-1' key={key}>
            {error}
          </li>
        ))}
      </ul>
    </div>
  )
}
