import { useDictionary } from '@/providers'
import ProfileImageWithName from '@/components/Profile/ProfileImageWithName'
import { Typography } from '@material-tailwind/react'

type Props = {
  firstName: string
  lastName: string
  profileImage: string
  settingNewProfileImage: (picture: string) => void
}

export default function General({
  firstName,
  lastName,
  profileImage,
  settingNewProfileImage,
}: Props) {
  const d = useDictionary('profile.general')

  return (
    <div className='px-4 text-center'>
      <ProfileImageWithName
        firstName={firstName}
        lastName={lastName}
        profileImage={profileImage}
        classNameImage='m-auto'
        settingNewProfileImage={(_picture) => settingNewProfileImage(_picture)}
      />
      <Typography variant='paragraph' className='text-base font-normal'>
        {d('description1')}
      </Typography>
      <Typography variant='paragraph' className='text-base font-normal'>
        {d('description2')}
      </Typography>
    </div>
  )
}
