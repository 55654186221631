'use client'

import { useState, useEffect } from 'react'
import { Input, IconButton } from '@material-tailwind/react'
import Icon from '@/components/Icon'
import { useDictionary } from '@/providers'

type Props = {
  value?: string
  onChange?: (value: string) => void
  onFocus?: () => void
  className?: string
  iconSize?: string
  searchIconSize?: string
  searchIconClassName?: string
  cleaButtonClassName?: string
  inputClassName?: string
  ariaLabel?: string
  disabled?: boolean
  containerProps?: object
}

export default function SearchInput({
  value = '',
  onChange = () => {},
  className = '',
  searchIconSize = '18px',
  searchIconClassName = '',
  cleaButtonClassName = '',
  inputClassName = '',
  containerProps,
  disabled,
  ariaLabel,
}: Props) {
  const d = useDictionary('header')
  const [term, setTerm] = useState(value)
  const onChangeHandler = ({ target }: { target: HTMLInputElement }) => {
    setTerm(target.value)
    onChange?.(target.value)
  }
  const onClick = () => {
    setTerm('')
    onChange?.('')
  }

  useEffect(() => {
    setTerm(value)
  }, [value])

  return (
    <div className={`relative flex ${className}`}>
      <div
        className={`absolute left-2 top-1/2 z-10 grid size-5 -translate-y-1/2 place-items-center text-black ${searchIconClassName}`}
      >
        <Icon icon='search' size={searchIconSize} />
      </div>
      <Input
        className={`h-2 px-9 !font-light leading-5 tracking-wide ${inputClassName}`}
        placeholder={d('search_label')}
        aria-label={ariaLabel ?? d('search_label')}
        type='search'
        value={term}
        disabled={disabled}
        onChange={onChangeHandler}
        containerProps={
          containerProps || {
            className: 'h-11',
          }
        }
      />
      <IconButton
        className={`!absolute right-1 top-1/2 z-10 -translate-y-1/2 hover:bg-transparent active:bg-transparent ${cleaButtonClassName} ${
          !term ? 'hidden' : ''
        }`}
        aria-label={d('search_label')}
        size='sm'
        variant='text'
        color='black'
        onClick={onClick}
        ripple={false}
      >
        <Icon icon='cross' size='11px' className='block' />
      </IconButton>
    </div>
  )
}
