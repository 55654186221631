import Cookies from 'js-cookie'
import { LastSeenComponent } from '@/types'

export function setCookieForLastSeen(
  type: string,
  slug: string,
  limit: number
) {
  const cookieItem: LastSeenComponent.Item = { type, slug }
  const currentCookie = Cookies.get('lastSeen')

  if (currentCookie == null) {
    Cookies.set('lastSeen', JSON.stringify([cookieItem]), { expires: 1 })
    return
  }

  let cookieArray = JSON.parse(currentCookie)

  if (
    cookieArray.some(
      (item: LastSeenComponent.Item) => item.type === type && item.slug === slug
    )
  ) {
    return
  }

  if (cookieArray.length >= limit) {
    cookieArray = cookieArray.slice(limit - 1)
  }
  cookieArray.push(cookieItem)
  Cookies.set('lastSeen', JSON.stringify(cookieArray), { expires: 1 })
}
