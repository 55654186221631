'use client'

import React, { useState } from 'react'
import { Button, Typography } from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import FormProvider, {
  RHFTextField,
  useForm,
  Yup,
  yupResolver,
} from '@/components/HookForm'
import { forgotPassword } from '@/actions/auth'
import Alert from '@/components/Alert'
import Link from '@/components/Link'

type Props = {
  setSuccess: (success: boolean) => void
}

export default function ForgotPasswordForm({ setSuccess }: Props) {
  const d = useDictionary('auth')

  const [errorMsg, setErrorMsg] = useState('')

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required(d('email_required')).email(d('email_invalid')),
  })

  const defaultValues = {
    email: '',
  }

  const methods = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = handleSubmit(async (data) => {
    const res = await forgotPassword(data)

    if (res?.success) {
      reset()
      setSuccess(true)
      setErrorMsg('')
    } else {
      setErrorMsg(res?.data?.errors[0])
      setSuccess(false)
    }
  })

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {errorMsg && (
        <Alert state='error' className='mb-4'>
          {errorMsg}
        </Alert>
      )}
      <div className='grid gap-4'>
        <RHFTextField
          name='email'
          variant='static'
          placeholder='example@gmail.com'
          label={d('email_input')}
        />
      </div>
      <Button
        fullWidth
        type='submit'
        loading={isSubmitting}
        className='mb-2 mt-4 flex w-full items-center justify-center bg-primary p-2 text-lg font-medium capitalize text-white !shadow-none'
      >
        {d('forgot_password_btn')}
      </Button>

      <Typography
        variant='paragraph'
        className='mt-4 text-left text-base font-medium'
      >
        {d('account_text')}{' '}
        <Link href='/sign-in' className='font-normal underline'>
          {' '}
          {d('sign_in_link')}
        </Link>
      </Typography>
    </FormProvider>
  )
}
