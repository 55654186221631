import { HeaderComponent } from '@/types'
import { Button } from '@material-tailwind/react'
import Image from '@/components/Image'
import Link from '@/components/Link'
import Icon from '@/components/Icon'

type Props = {
  menuItems: Array<HeaderComponent.MenuItem>
  selectItem: (item: HeaderComponent.MenuItem) => void
}

export default function NavParents({ menuItems, selectItem }: Props) {
  return (
    <div className='mt-5 grid grid-cols-2 items-start gap-4'>
      {menuItems.map((item, key) => (
        <Button
          className={`p-0 text-lg font-medium capitalize shadow-none ${
            item.advancedMenu?.length ? 'nav-manu-offcanvas-open-child' : ''
          }`}
          key={key}
          onClick={() => item.advancedMenu?.length && selectItem(item)}
        >
          <Link
            href={item.advancedMenu?.length ? undefined : item.url}
            className='text-lg font-medium'
          >
            {(item.image?.url && (
              <Image
                className='h-24 w-full object-cover object-center'
                src={item.image?.url}
                alt={item.label}
                height={95}
                width={95}
              />
            )) || (
              <div className='flex h-24 w-full bg-body-bg-secondary p-4'>
                <Icon
                  size='30px'
                  icon='diamond'
                  className='m-auto text-border-color'
                />
              </div>
            )}
            {item.label}
          </Link>
        </Button>
      ))}
    </div>
  )
}
