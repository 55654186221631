import { HeaderComponent } from '@/types'
import { MenuList, MenuItem, Typography } from '@material-tailwind/react'
import Link from '@/components/Link'

type Props = {
  items: Array<HeaderComponent.AdvancedMenuColumn>
}

export default function NavMenu({ items }: Props) {
  const columnClasses: { [key: number]: string } = {
    1: 'grid-cols-1',
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4',
  }

  return (
    <MenuList className='hidden w-full border-none bg-transparent py-0 shadow-none outline-none md:block'>
      <div
        className={`container grid ${columnClasses[items.length]} border-gray-30 gap-4 border bg-white p-5 shadow outline-none`}
      >
        {items.map((column, columnKey) => (
          <div key={columnKey}>
            {column.headerTitle && (
              <Typography
                as='p'
                variant='h3'
                className='font-medium text-black'
              >
                {column.headerTitle}
              </Typography>
            )}
            <div
              className={`mt-4${column.showMode === 'bordered' ? 'grid grid-cols-3 gap-2' : ''}`}
            >
              {column.items.map((item, columnItemKey) => (
                <Link
                  key={columnItemKey}
                  href={item.link}
                  className='focus-visible:outline-none'
                >
                  <MenuItem
                    className={`items-center gap-3 rounded-lg text-base font-normal text-black${
                      column.showMode === 'bordered'
                        ? 'content-center border border-gray-400 py-8 text-center'
                        : ''
                    }`}
                  >
                    {item.label}
                  </MenuItem>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </MenuList>
  )
}
