import { Typography, Button } from '@material-tailwind/react'
import Icon from '@/components/Icon'
import React from 'react'

type Props = {
  icon: string
  title: string
  errors: Array<string>
  btn_text: string
  onClickBtn: () => void
  loading?: boolean
  className?: string
}

export default function ErrorMessage({
  icon,
  title,
  errors,
  btn_text,
  onClickBtn,
  loading = false,
  className = '',
}: Props) {
  return (
    <section className={`bg-body-bg-secondary py-8 md:py-16 ${className}`}>
      <div className='sm:m-auto sm:w-3/4 md:w-2/5 lg:w-1/3'>
        <Icon icon={icon} className='mb-10 block' size='120px' />

        <Typography as='p' variant='h2' className='mb-8'>
          {title}
        </Typography>

        <div className='mb-10 flex flex-col gap-2'>
          {errors?.map((el, index) => (
            <Typography key={index} variant='lead'>
              {el}
            </Typography>
          ))}
        </div>

        <Button
          onClick={onClickBtn}
          loading={loading}
          className='m-auto mb-2 mt-4 flex w-full max-w-sm items-center justify-center bg-primary p-2 text-lg font-medium capitalize text-white !shadow-none'
        >
          {btn_text}
        </Button>
      </div>
    </section>
  )
}
