'use client'

import { ElementType, forwardRef, ComponentProps, FC } from 'react'

interface Props extends ComponentProps<'a'> {
  as?: ElementType | FC
  nofollow?: boolean
  noopener?: boolean

  // eslint-disable-next-line
  [key: string]: any
}

export default forwardRef<HTMLElement, Props>(function FakeLink(
  {
    as: FallbackComponent,
    href,
    children,
    target = '_self',
    className = '',
    // eslint-disable-next-line
    nofollow = false,
    // eslint-disable-next-line
    noopener = false,
    ...props
  },
  ref
) {
  const FakeLinkComponent = FallbackComponent || 'div'

  if (target === '') {
    target = '_self'
  }

  const onClick = () => {
    if (!href) {
      return
    }
    window.open(href, target as string)
  }

  return (
    <FakeLinkComponent
      ref={ref}
      onClick={onClick}
      className={`cursor-pointer ${className}`}
      {...props}
    >
      {children}
    </FakeLinkComponent>
  )
})
