import { HTMLAttributeAnchorTarget } from 'react'
import { Typography } from '@material-tailwind/react'
import Slider, { SliderItem, breakpoints } from '@/components/Slider'
import CategoryCard from '@/components/CategoryCard'

export type ImageFile = {
  url: string
}

type SliderItemProps = {
  url: string
  label: string
  target: HTMLAttributeAnchorTarget
  imageFile: ImageFile
}

type Props = {
  title: string
  sliderItems: Array<SliderItemProps>
  className?: string
  isFullWidth?: boolean
  overlap?: boolean
  withoutText?: boolean
  withButton?: boolean
}

export default function BaseSlider({
  title,
  sliderItems = [],
  className = '',
  isFullWidth,
  overlap = false,
  withoutText = false,
  withButton = false,
}: Props) {
  if (!sliderItems.length) {
    return <></>
  }

  const sliderProps = {
    slidesPerView: withoutText ? 1.6 : 1.5,
    spaceBetween: 20,
    centerInsufficientSlides: true,
    grabCursor: true,
    breakpoints: {
      [breakpoints.sm]: {
        slidesPerView: withoutText ? 1.6 : 1.5,
      },
      [breakpoints.md]: {
        slidesPerView: withoutText ? 2.8 : 3.3,
      },
      [breakpoints.lg]: {
        slidesPerView: withoutText ? 3.8 : 4.4,
      },
      [breakpoints.xl]: {
        slidesPerView: withoutText ? 4.3 : 4.4,
      },
      [breakpoints.xxl]: {
        slidesPerView: withoutText ? 6 : 4,
      },
    },
  }

  return (
    <section className={`overflow-hidden py-8 md:py-16 ${className ?? ''}`}>
      <div className={isFullWidth ? '' : 'container'}>
        {title !== '' && (
          <Typography
            variant='h2'
            className='base-slider-headline mb-6 text-left text-3xl font-semibold text-primary underline underline-offset-2'
          >
            {title}
          </Typography>
        )}
        <Slider
          className='!overflow-visible'
          imageEl='.category-card-image'
          navNextClassName='sm:right-[9.2%] 2xl:right-0'
          {...sliderProps}
        >
          {sliderItems?.map((sliderItem, index) => (
            <SliderItem key={index}>
              <CategoryCard
                href={sliderItem.url}
                title={sliderItem.label}
                target={sliderItem.target}
                mediaUrl={sliderItem.imageFile.url}
                mediaAlt={sliderItem.label}
                overlap={overlap ?? false}
                withoutText={withoutText ?? false}
                withButton={withButton ?? false}
              />
            </SliderItem>
          ))}
        </Slider>
      </div>
    </section>
  )
}
