import { Navigation } from 'swiper/modules'
import { SwiperSlide, Swiper } from 'swiper/react'
import NavButton from '@/components/Slider/NavButton'
import ImageTextCard from '@/components/ImageTextCard'
import { BannerSliderTwoColumnsComponent } from '@/types'
import { getSwiperSlideStyles } from '@/components/Slider'

type Props = {
  sliderItems: Array<BannerSliderTwoColumnsComponent.Item>
}

export default function TwoColumnSlider({ sliderItems = [] }: Props) {
  const sliderProps = {
    slidesPerView: 1,
    modules: [Navigation],
    speed: 500,
    centerInsufficientSlides: true,
    navigation: {
      prevEl: '.slider-nav-button-prev',
      nextEl: '.slider-nav-button-next',
      enabled: true,
      hiddenClass: 'hidden',
      disabledClass: 'hidden',
    },
  }

  const swiperStyles = getSwiperSlideStyles({}, 0, 1 as number)

  return (
    <section className='overflow-hidden'>
      <Swiper className='relative' {...sliderProps} style={swiperStyles}>
        <div className='absolute left-0 top-32 z-10 w-full -translate-y-1/2 md:top-1/2'>
          <div className='container flex flex-col md:flex-row'>
            <div className='relative basis-4/9'>
              <NavButton
                className='slider-nav-button-prev left-0 hidden'
                iconProps={{ icon: 'arrow-left2' }}
              />
              <NavButton
                className='slider-nav-button-next right-0 hidden md:right-4'
                iconProps={{ icon: 'arrow-right2' }}
              />
            </div>
          </div>
        </div>
        {sliderItems.map((sliderItem, index) => (
          <SwiperSlide key={index}>
            <ImageTextCard
              url={sliderItem.url}
              label={sliderItem.label}
              headline={sliderItem.headline}
              description={sliderItem.description}
              mediaUrl={sliderItem.imageFile.url}
              backgroundColor={sliderItem.backgroundColor}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}
