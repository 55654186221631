import { forwardRef } from 'react'
import NextImage, { type ImageProps, getImageProps } from 'next/image'
import type { PictureSources } from '@/types'

export type Props = ImageProps & {
  sources?: PictureSources
}

export default forwardRef<HTMLImageElement, Props>(function Image(
  { ...props },
  ref
) {
  if (props.src === null) {
    props.src = ''
  }

  const { sources, ...rest } = props
  if (sources !== undefined) {
    const attrs = getImageProps({
      ...rest,
      unoptimized: true,
    })

    const Sources = () => {
      const elements = []
      for (const viewportWidth in sources) {
        if (sources.hasOwnProperty(viewportWidth)) {
          elements.push(
            <source
              key={viewportWidth}
              media={`(max-width: ${viewportWidth}px)`}
              srcSet={sources[viewportWidth].src}
              width={sources[viewportWidth].size}
              height={sources[viewportWidth].size}
            />
          )
        }
      }
      return elements
    }

    return (
      <picture>
        <Sources />
        {
          // eslint-disable-next-line
          <img ref={ref} {...attrs.props} />
        }
      </picture>
    )
  }

  return <NextImage ref={ref} unoptimized {...rest} />
})
