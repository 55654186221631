'use client'

import { useState } from 'react'
import { Drawer, IconButton } from '@material-tailwind/react'
import { MenuItem } from '@/types/Components/Header'
import OffCanvas from '@/components/OffCanvasMenu/OffCanvas'
import Icon from '@/components/Icon'

type Props = {
  menuItems: Array<MenuItem>
}

export default function OffCanvasMenu({ menuItems }: Props) {
  const [mobilePopupOpen, setMobilePopupOpen] = useState(false)
  // using for offCanvas content hide after close canvas
  const [showNavigation, setShowNavigation] = useState(false)

  const toggleOffCanvas = () => {
    setMobilePopupOpen(!mobilePopupOpen)
    if (mobilePopupOpen) {
      setTimeout(() => setShowNavigation(false), 500)
      return
    }
    setShowNavigation(true)
  }

  return (
    <>
      <IconButton
        className='mt-4 shadow-none focus:shadow-none active:shadow-none md:hidden'
        onClick={toggleOffCanvas}
        aria-label='Menu'
      >
        <Icon className='h-5 w-5' size='16px' icon='menu' />
      </IconButton>
      {showNavigation && (
        <Drawer
          open={mobilePopupOpen}
          onClose={toggleOffCanvas}
          className='overflow-y-auto'
          overlayProps={{
            className: '!fixed',
          }}
        >
          <OffCanvas menuItems={menuItems} closeOffCanvas={toggleOffCanvas} />
        </Drawer>
      )}
    </>
  )
}
