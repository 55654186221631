'use client'

import React, { useState, ReactNode, useEffect } from 'react'
import Icon from '@/components/Icon'
import IconSvg from '@/components/IconSvg'

type Props = {
  children: ReactNode
  title: string | ReactNode
  active?: boolean
  className?: string
  icon?: string
  collapsedIcon?: string
  headerClassName?: string
  bodyClassName?: string
  onClick?: () => void
  loading?: boolean
}

export default function Accordion({
  children,
  title,
  active = false,
  className = 'mb-3 bg-body-bg-secondary px-4 text-left',
  collapsedIcon = 'arrow-up',
  icon = 'arrow-down',
  headerClassName = 'border-b-0 py-3 transition-colors',
  bodyClassName = 'py-1',
  onClick = () => {},
  loading = false,
}: Props) {
  const [open, setOpen] = useState<boolean>(active)
  const [bodyHeight, setBodyHeight] = useState<string>(active ? 'auto' : '0px')
  const bodyRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    setOpen(active)
  }, [active])

  useEffect(() => {
    if (bodyRef.current) {
      setBodyHeight(`${bodyRef.current.scrollHeight}px`)
    }
  }, [open, children])

  const handleOpenAccordion = async () => {
    if (onClick) {
      await onClick()
    }
    setOpen(!open)
  }

  return (
    <div className={`relative block w-full ${className}`}>
      <div
        className={`flex w-full cursor-pointer select-none items-center justify-between border-b-blue-gray-100 py-4 text-left font-poppins text-sm font-normal text-primary antialiased transition-colors hover:text-blue-gray-900 ${headerClassName}`}
        onClick={handleOpenAccordion}
      >
        <span>{title}</span>
        {loading ? (
          <IconSvg className='size-4 animate-spin' icon='loading' />
        ) : (
          <Icon
            className='ml-2'
            icon={(open ? collapsedIcon : icon) as string}
            size='14px'
          />
        )}
      </div>
      <div
        ref={bodyRef}
        className={`overflow-hidden transition-[height] duration-300`}
        style={{
          maxHeight: open ? `${bodyHeight}` : '0px',
          transition: 'max-height 0.3s ease-in-out',
        }}
      >
        <div
          className={`text-left text-base font-normal text-primary ${bodyClassName}`}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
