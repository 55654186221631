'use client'

import { Filters } from '@/types'
import { useEffect, useState } from 'react'
import { OffCanvas } from './OffCanvas'
import HorizontalSelect from './HorizontalSelect'
import Accordion from '@/components/Accordion'
import { Button } from '@material-tailwind/react'
import { useDictionary } from '@/providers'

type Props = {
  title: string
  name: string
  onFilter: Filters.onFilter
  onResetFilter: Filters.onResetFilter
  onShowMoreFilters: Filters.onShowMoreFilters
  items: Array<Filters.Order>
  mainContentFilter: boolean
  filter: Filters.SelectedData
  group?: Filters.Group
  mobile?: boolean
  horizontal?: boolean
  urlPosition?: number | null
  selected?: number
}

export default function Order({
  title,
  name,
  items,
  filter,
  group,
  mobile = false,
  horizontal = false,
  mainContentFilter = false,
  urlPosition = null,
  onFilter,
  onShowMoreFilters,
  selected = 0,
}: Props) {
  const d = useDictionary('filters')

  const [listItems, setListItems] = useState<Array<Filters.Order>>(items || [])
  const [loading, setLoading] = useState({
    button: false,
    loadMore: false,
  })
  const [getListItemsSuccess, setGetListItemsSuccess] = useState(false)

  const [selectedItem, setSelectedItem] = useState<number>(selected)

  useEffect(() => {
    if (!Object.keys(filter)?.length && selectedItem > 0) {
      setSelectedItem(0)
    } else if (Object.keys(filter)?.length) {
      setSelectedItem(filter.selected)
    }
  }, [filter?.selected])

  const handleSelectedItem = (item: Filters.Order) => {
    setSelectedItem(item?.value as number)
    onFilter(
      name,
      {
        urlPosition: urlPosition || null,
        title: item?.name,
        selected: item?.value,
      },
      mobile,
      group
    )
  }

  const handleShowMore = async (type: string) => {
    setLoading({
      ...loading,
      [type]: true,
    })

    const newListItems = await onShowMoreFilters(name)

    const filteredNewListItems = newListItems.filter(
      (item: Filters.Order) =>
        !listItems.some((listItem) => listItem.value === item.value)
    )

    const updatedListItems = [...listItems, ...filteredNewListItems]

    setListItems(updatedListItems)
    setGetListItemsSuccess(true)
    setLoading({
      ...loading,
      [type]: false,
    })
    return true
  }

  const showMore =
    mainContentFilter && listItems?.length <= 0 ? handleShowMore : () => {}

  const renderContent = (
    <>
      <div data-nosnippet className='w-auto focus-visible:outline-none'>
        {items.map((el, index) => (
          <div
            key={index}
            data-nosnippet
            className={`${selectedItem === index + 1 ? 'bg-body-bg-secondary font-semibold' : 'bg-white'} cursor-pointer p-0 px-1 py-2 text-left text-base font-normal text-primary hover:bg-body-bg-secondary`}
            onClick={() => handleSelectedItem(el)}
          >
            {el.name}
          </div>
        ))}
      </div>

      {mainContentFilter && !getListItemsSuccess && (
        <Button
          loading={loading.button}
          className='mt-4 flex h-8 w-full items-center justify-center border px-2 py-1 text-center text-sm font-medium capitalize text-secondary !shadow-none'
          onClick={() => handleShowMore('button')}
          aria-label={d('more_filters')}
        >
          {d('more_filters')}
        </Button>
      )}
    </>
  )

  if (mobile) {
    return (
      <OffCanvas
        title={title}
        onClick={() => showMore('loadMore')}
        loading={loading.loadMore}
      >
        {renderContent}
      </OffCanvas>
    )
  }

  if (horizontal) {
    return (
      <HorizontalSelect
        title={title}
        isSelected={selectedItem > 0}
        onClick={() => showMore('loadMore')}
        loading={loading.loadMore}
      >
        {renderContent}
      </HorizontalSelect>
    )
  }

  return (
    <Accordion
      title={title}
      active={selectedItem > 0 || !!items?.length}
      icon='plus'
      collapsedIcon='minus'
      className='m-0 border px-3'
      headerClassName='text-base border-0'
      onClick={() => showMore('loadMore')}
      loading={loading.loadMore}
    >
      {renderContent}
    </Accordion>
  )
}
