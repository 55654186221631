import { GenerateRandomString } from '@/utils/random-string'

export function GetSessionId(): string {
  let ngSessionId = sessionStorage.getItem('ngSessionId')
  if (ngSessionId !== null) {
    return ngSessionId
  }

  ngSessionId = GenerateRandomString()
  sessionStorage.setItem('ngSessionId', ngSessionId)

  return ngSessionId
}

export function GetLastSearchTerm(newTerm: string): string {
  const lastSearchTerm = sessionStorage.getItem('ngLastSearchTerm')
  sessionStorage.setItem('ngLastSearchTerm', newTerm)

  return lastSearchTerm ?? ''
}

export function GetLastSearchUid(): string {
  const lastSearchUid = sessionStorage.getItem('ngLastSearchUid')

  return lastSearchUid ?? ''
}

export function SetSearchUid(uid: string) {
  sessionStorage.setItem('ngLastSearchUid', uid)
}
