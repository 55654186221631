import Image from '@/components/Image'
import Icon from '@/components/Icon'
import Link from '@/components/Link'
import { Typography } from '@material-tailwind/react'

type Props = {
  url: string
  label: string
  headline: string
  description: string
  mediaUrl: string
  backgroundColor: string
  className?: string
}

export default function ImageTextCard({
  url,
  label,
  headline,
  mediaUrl,
  description,
  backgroundColor,
  className = '',
}: Props) {
  return (
    <div className={className} style={{ backgroundColor: backgroundColor }}>
      <div className='container flex flex-col items-center gap-8 pb-8 md:flex-row md:pb-0'>
        <div className='h-64 w-full md:h-120 md:basis-4/9'>
          <Image
            className='h-64 w-full object-cover object-center md:h-full'
            src={mediaUrl}
            width={600}
            height={480}
            alt={label}
          />
        </div>
        <div className='text-left md:basis-5/9'>
          <Typography as='p' variant='h2' className='text-3xl underline'>
            {headline}
          </Typography>
          <Typography variant='paragraph' className='mt-3 text-lg'>
            {description}
          </Typography>
          <Link
            className='mt-5 flex items-center gap-x-2 text-lg font-medium capitalize text-inherit'
            href={url}
          >
            {label} <Icon icon='arrow-right' size='14px' />
          </Link>
        </div>
      </div>
    </div>
  )
}
