import { StaticTextComponent } from '@/types'
import StaticText from '@/containers/sections/static-text'

export default function generate({
  component,
}: {
  component: StaticTextComponent.Component
}) {
  return <StaticText content={component.config.content} />
}
