import {
  Component,
  HeaderComponent,
  FooterComponent,
  Page as PageTypes,
} from '@/types'
import Header from '@/containers/layouts/Header'
import Main from '@/containers/layouts/Main'
import Footer from '@/containers/layouts/Footer'
import StructuredData from '@/components/StructuredData'
import ConsentCookieComponent from '@/components/ConsentCookie'
import NgTracker from '@/components/NgTracker'

type Props = {
  page: PageTypes.Page
}

export default function Page({ page }: Props) {
  const findComponent = function <T>(technicalName: string): T | undefined {
      return page.components?.find(
        (i) => i.builderComponent.technicalName === technicalName
      ) as T
    },
    header = findComponent<HeaderComponent.Component>(
      Component.TechnicalName.HEADER
    ),
    footer = findComponent<FooterComponent.Component>(
      Component.TechnicalName.FOOTER
    )

  return (
    <>
      {header && <Header config={header?.config} />}
      <Main components={page.components} />
      {footer && <Footer config={footer?.config} />}
      <StructuredData
        data={page.metaInformation?.structureData}
        logo={page.channel?.logo}
      />
      <ConsentCookieComponent href={`/${page.pages?.dataPolicy}`} />
      <NgTracker
        components={page.components}
        channelId={page.channelId}
        countryId={page.countryId}
        channelPageId={page.id}
      />
    </>
  )
}
