import { OutfitListingComponent } from '@/types'
import OutfitListing from '@/containers/sections/outfit-listing'

export default function generate({
  component,
}: {
  component: OutfitListingComponent.Component
}) {
  const {
    outfits,
    count,
    limit,
    page,
    displayFilter,
    displayPagination,
    breadcrumb,
    suggestions,
    mainContent,
    mainContentOnTop,
    facets,
    description,
  } = component.config

  return (
    <OutfitListing
      componentId={component.id}
      outfits={outfits}
      count={count}
      limit={limit}
      page={page}
      displayFilter={displayFilter}
      displayPagination={displayPagination}
      breadcrumb={breadcrumb}
      suggestions={suggestions}
      mainContentSuggestions={mainContent}
      mainContentOnTop={mainContentOnTop || []}
      facets={facets}
      description={description || ''}
    />
  )
}
