'use client'

import React, { useState } from 'react'
import { useDictionary } from '@/providers'
import ResetPasswordForm from '@/components/Auth/ResetPasswordForm'
import SuccessMessage from '@/components/Auth/SuccessMessage'
import ErrorMessage from '@/components/Auth/ErrorMessage'
import { Typography } from '@material-tailwind/react'
import { resendResetPasswordEmail } from '@/actions/auth'
import { useParams } from '@/hooks'

type Props = {
  errors: Array<string>
}

export default function ResetPassword({ errors }: Props) {
  const d = useDictionary('reset_password')

  const { email = '' } = useParams()

  const [success, setSuccess] = useState<boolean>(false)
  const [successResend, setSuccessResend] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState('')

  const resendVerifyEmail = async () => {
    setLoading(true)
    const res = await resendResetPasswordEmail({ email })
    if (res?.success) {
      setSuccessResend(true)
      setErrorMsg('')
    } else {
      setErrorMsg(res?.data?.errors[0])
      setSuccessResend(false)
    }
    setLoading(false)
  }

  if (successResend) {
    return (
      <SuccessMessage
        icon='envelope-open'
        title={d('resend_success_title')}
        description={d('resend_success_description')}
        btn_text={d('resend_success_btn')}
        link_to='/'
      />
    )
  }

  if (errors && errors.length && !success && !successResend) {
    return (
      <ErrorMessage
        icon='envelope-warning'
        title={d('error_title')}
        errors={[errorMsg, ...errors]}
        btn_text={d('resend_reset_password_email_btn')}
        onClickBtn={resendVerifyEmail}
        loading={loading}
      />
    )
  }

  if (success) {
    return (
      <SuccessMessage
        icon='check-circle2'
        title={d('success_title')}
        description={d('success_description')}
        btn_text={d('success_btn')}
        link_to='/sign-in'
      />
    )
  }

  return (
    <section className={`bg-white px-4 py-8 md:px-0 md:py-16`}>
      <div className='sm:m-auto sm:w-3/4 md:w-2/5 lg:w-1/4'>
        <Typography
          as='p'
          variant='h2'
          className='mb-16 text-2xl font-semibold'
        >
          {d('title')}
        </Typography>
        <ResetPasswordForm setSuccess={setSuccess} />
      </div>
    </section>
  )
}
