'use client'

import React, { useState } from 'react'
import { Button, Typography } from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import FormProvider, {
  Yup,
  yupResolver,
  useForm,
  RHFTextField,
  RHFPasswordField,
  PASSWORD_REG_EXP,
} from '@/components/HookForm'
import { signUpRequest } from '@/actions/auth'
import Alert from '@/components/Alert'
import Link from '@/components/Link'

type Props = {
  setSuccess: (success: boolean) => void
}

export default function RegisterForm({ setSuccess }: Props) {
  const d = useDictionary('auth')

  const [errorMsg, setErrorMsg] = useState('')

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required(d('first_name_required')),
    lastName: Yup.string().required(d('last_name_required')),
    email: Yup.string().required(d('email_required')).email(d('email_invalid')),
    password: Yup.string()
      .required(d('password_required'))
      .matches(PASSWORD_REG_EXP, d('invalid_password')),
  })

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = handleSubmit(async (data) => {
    const res = await signUpRequest(data)

    if (res?.success) {
      reset()
      setSuccess(true)
      setErrorMsg('')
    } else {
      setErrorMsg(res?.data?.errors[0])
      setSuccess(false)
    }
  })

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {errorMsg && (
        <Alert state='error' className='mb-4'>
          {errorMsg}
        </Alert>
      )}
      <div className='grid gap-4'>
        <RHFTextField
          name='firstName'
          variant='static'
          placeholder='Jane'
          label={d('first_name_input')}
        />
        <RHFTextField
          name='lastName'
          variant='static'
          placeholder='Parker'
          label={d('last_name_input')}
        />

        <RHFTextField
          name='email'
          variant='static'
          placeholder='example@gmail.com'
          label={d('email_input')}
        />

        <RHFPasswordField
          name='password'
          variant='static'
          showStrength
          label={d('password_input')}
          placeholder='********'
        />
      </div>

      <Button
        fullWidth
        type='submit'
        loading={isSubmitting}
        className='mt-4 flex w-full items-center justify-center bg-primary p-2 text-lg font-medium capitalize text-white !shadow-none'
      >
        {d('sign_up_btn')}
      </Button>

      <Typography
        variant='paragraph'
        className='mt-4 text-left text-base font-medium'
      >
        {d('account_text')}{' '}
        <Link href='/sign-in' className='font-normal underline'>
          {' '}
          {d('sign_in_link')}
        </Link>
      </Typography>
    </FormProvider>
  )
}
