'use client'

import { Controller, useFormContext } from 'react-hook-form'

import {
  Textarea,
  type TextareaProps,
  Typography,
} from '@material-tailwind/react'

type Props = TextareaProps & {
  name: string
  helperText?: string
}

export default function RHFTextarea({
  name,
  label,
  placeholder,
  variant,
  helperText,
  disabled = false,
}: Props) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <Textarea
            {...field}
            value={field.value}
            label={label}
            error={!!error}
            variant={variant}
            placeholder={placeholder}
            disabled={disabled}
            labelProps={{
              className: !error ? '!text-black' : '',
            }}
          />
          {(!!error || helperText) && (
            <Typography
              variant='small'
              color='gray'
              className={`mt-2 text-left font-normal ${error ? 'text-red-500' : ''}`}
            >
              {error ? error?.message : helperText}
            </Typography>
          )}
        </div>
      )}
    />
  )
}
