import { FavoriteComponent } from '@/types'
import FavoriteOutfitsListing from '@/containers/sections/favorite-outfits-listing'
import { FAVORITE_OUTFIT_CODE } from '@/utils/api'

export default function generate({
  component,
}: {
  component: FavoriteComponent.OutfitsComponent
}) {
  return (
    <FavoriteOutfitsListing
      outfits={component.config.entries}
      count={component.config.count}
      typeCode={FAVORITE_OUTFIT_CODE}
    />
  )
}
