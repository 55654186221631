import Icon from '@/components/Icon'
import { Typography } from '@material-tailwind/react'

type Props = {
  text: string
  title: string
}

export default function Error500({ text, title }: Props) {
  return (
    <section className='container my-12'>
      <Icon className='block' size='120px' icon='server-error' />
      <div className='my-4'>
        <Typography as='p' variant='h1'>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </section>
  )
}
