import { useMemo } from 'react'
import { useSearchParams } from 'next/navigation'

type Params = {
  [key: string]: string
}

export function useParams(): Params {
  const searchParams = useSearchParams()

  return useMemo(() => {
    return Object.fromEntries(searchParams.entries())
  }, [searchParams])
}
