import BrandListing from '@/components/BrandListing'
import { BrandListingComponent } from '@/types'

type Props = {
  count: number
  brands: BrandListingComponent.Brands
  symbols: Array<string>
  className?: string
}

export default function BrandListingSection({
  count,
  brands,
  symbols,
  className = '',
}: Props) {
  return (
    <section className={`overflow-hidden bg-white py-8 md:py-16 ${className}`}>
      <div className='container'>
        <BrandListing count={count} brands={brands} symbols={symbols} />
      </div>
    </section>
  )
}
