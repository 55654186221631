import { Component } from '@/types'
import generators from '@/containers/generators'

type Props = {
  components: Array<Component.Component>
}

export default function Main({ components }: Props) {
  return (
    <main className='text-center'>
      {components?.map((component, key) => {
        const Generator = generators[component.builderComponent.technicalName]
        if (!Generator) {
          return null
        }
        return <Generator key={key} component={component} />
      })}
    </main>
  )
}
