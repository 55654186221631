import { ContactPageComponent } from '@/types'
import ContactPage from '@/containers/sections/contact-page/'

export default function generate({
  component,
}: {
  component: ContactPageComponent.Component
}) {
  return (
    <ContactPage
      content={component.config.content}
      sidebar={component.config.sidebar}
    />
  )
}
