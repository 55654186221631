export interface StructuredDataProps {
  data?: object
  logo?: string
}

export default function StructuredData({ data, logo }: StructuredDataProps) {
  if (data === undefined) {
    return <></>
  }

  let dataJson = JSON.stringify(data)

  dataJson = dataJson.replace('{{logo}}', logo ?? '')

  return (
    <script
      type='application/ld+json'
      dangerouslySetInnerHTML={{ __html: dataJson }}
    />
  )
}
