'use client'

import React, { useState } from 'react'
import { useDictionary } from '@/providers'
import SuccessMessage from '@/components/Auth/SuccessMessage'
import ErrorMessage from '@/components/Auth/ErrorMessage'
import { resendVerificationEmail } from '@/actions/auth'
import { useParams } from '@/hooks'

type Props = {
  errors: Array<string>
}

export default function VerifyAccount({ errors }: Props) {
  const d = useDictionary('verify_account')

  const { email = '' } = useParams()

  const [success, setSuccess] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState('')

  const resendVerifyEmail = async () => {
    setLoading(true)
    const res = await resendVerificationEmail({ email })
    if (res?.success) {
      setSuccess(true)
      setErrorMsg('')
    } else {
      setErrorMsg(res?.data?.errors[0])
      setSuccess(false)
    }
    setLoading(false)
  }

  if (success) {
    return (
      <SuccessMessage
        icon='envelope-open'
        title={d('resend_success_title')}
        description={d('resend_success_description')}
        btn_text={d('resend_success_btn')}
        link_to='/'
      />
    )
  }

  if (errors && errors.length && !success) {
    return (
      <ErrorMessage
        icon='envelope-warning'
        title={d('error_title')}
        errors={[errorMsg, ...errors]}
        btn_text={d('resend_verify_email_btn')}
        onClickBtn={resendVerifyEmail}
        loading={loading}
      />
    )
  }

  return (
    <SuccessMessage
      icon='check-circle2'
      title={d('success_title')}
      description={d('success_description')}
      btn_text={d('success_btn')}
      link_to='/sign-in'
    />
  )
}
