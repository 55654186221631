'use client'

import React, { useRef, ChangeEvent } from 'react'
import { Button } from '@material-tailwind/react'
import { editProfilePicture } from '@/actions/profile'
import Icon from '@/components/Icon'

type Props = {
  uploadLoading: (loading: boolean) => void
  settingErrors: (errors: string[]) => void
  settingProfileImage: (picture: string) => void
}

export default function UploadPhotoButton({
  uploadLoading,
  settingErrors,
  settingProfileImage,
}: Props) {
  const fileInput = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    fileInput.current?.click()
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files?.[0]
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    fileUploaded && uploadPhoto(fileUploaded)
  }

  const uploadPhoto = async (fileUploaded: File) => {
    const photo = new FormData()
    photo.append('profileImage', fileUploaded)

    uploadLoading(true)
    const { data, success } = await editProfilePicture(photo)
    uploadLoading(false)

    if (!success) {
      settingErrors(data.errors)
      return
    }
    settingProfileImage(data.profileImage)
    return
  }
  return (
    <Button
      className='!absolute bottom-0 right-[5%] flex h-7 w-7 items-center justify-center rounded-full border border-primary bg-white p-0 shadow-none'
      onClick={handleClick}
    >
      <input
        type='file'
        className='hidden'
        ref={fileInput}
        onChange={handleChange}
        aria-label='Upload photo'
      />
      <Icon icon='camera' className='text-base' />
    </Button>
  )
}
