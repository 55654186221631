import React, { useState, ReactNode } from 'react'

import { Drawer, Button } from '@material-tailwind/react'
import Icon from '@/components/Icon'
import { useDictionary } from '@/providers'
import IconSvg from '@/components/IconSvg'

type Props = {
  title: string
  children: ReactNode
  className?: string
  isSelected?: boolean
  filterCount?: number
  handleScroll?: (e: any) => void
  onClick?: () => void
  loading?: boolean
}

export function OffCanvas({
  title,
  children,
  isSelected = false,
  filterCount = 0,
  className = '',
  loading = false,
  handleScroll = () => {},
  onClick = () => {},
}: Props) {
  const d = useDictionary('filters')

  const [mobilePopupOpen, setMobilePopupOpen] = useState(false)
  // using for offCanvas content hide after close canvas
  const [showFilters, setShowFilters] = useState(false)

  const toggleOffCanvas = async () => {
    await onClick()
    setMobilePopupOpen(!mobilePopupOpen)
    setShowFilters(!mobilePopupOpen)
  }

  return (
    <>
      <Button
        aria-label={title}
        size='sm'
        color='black'
        variant='outlined'
        onClick={toggleOffCanvas}
        className={`font-lg my-2 flex h-9 w-full items-center justify-between gap-x-2 border-0 px-0 py-2 text-left text-base font-medium capitalize leading-8 ${className} !shadow-none`}
      >
        <span
          className={`flex flex-row items-center ${filterCount > 0 || isSelected ? 'text-primary' : 'text-secondary'}`}
        >
          <span className='flex-shrink'>{title}</span>
          {filterCount > 0 && (
            <span className='ml-2 flex h-4 w-4 items-center justify-center rounded-full bg-primary text-xs text-white'>
              {filterCount}
            </span>
          )}
        </span>
        {loading ? (
          <IconSvg className='size-4' icon='loading' />
        ) : (
          <Icon className='block' size='10px' icon='arrow-right' />
        )}
      </Button>

      {showFilters && (
        <Drawer
          open={mobilePopupOpen}
          onClose={toggleOffCanvas}
          className='z-40 pb-20'
          overlay={false}
          overlayProps={{
            className: '!fixed',
          }}
        >
          <div
            className='max-h-full overflow-y-auto p-2'
            onScroll={handleScroll}
          >
            <Button
              className='mb-3 flex w-full items-center justify-start gap-4 border-0 border-b px-0 py-3 text-left text-lg font-normal capitalize !shadow-none'
              onClick={toggleOffCanvas}
              aria-label={d('filter_mobile_btn_close_aria_label')}
            >
              <Icon
                className='block text-black'
                size='15px'
                icon='arrow-left'
              />
              {title}
            </Button>

            {mobilePopupOpen && children}
          </div>
        </Drawer>
      )}
    </>
  )
}
