import { BannerSliderTwoColumnsComponent } from '@/types'
import TwoColumnSlider from '@/containers/sections/two-column-slider/'

export default function generate({
  component,
}: {
  component: BannerSliderTwoColumnsComponent.Component
}) {
  return <TwoColumnSlider sliderItems={component.config.items} />
}
