'use client'

import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import OutfitCard from '@/components/OutfitCard'
import { Outfit } from '@/types'
import { getFavorite } from '@/actions/favorite'
import emitter, { DeleteFavoriteOutfitEvent } from '@/utils/events'

type Props = {
  outfits: Array<Outfit.Outfit>
  count: number
  typeCode: number
}

export default function Favorite({ outfits, count, typeCode }: Props) {
  const d = useDictionary('favorite')

  const [outfitsList, setOutfitsList] = useState<Array<Outfit.Outfit>>(outfits)
  const [outfitCount, setOutfitCount] = useState<number>(count)
  const [loading, setLoading] = useState<boolean>(false)

  const handleShowMore = async () => {
    setLoading(true)

    const { data, success } = await getFavorite({
      type: typeCode,
      offset: outfitsList.length,
    })

    if (success) {
      setOutfitsList(outfitsList.concat(data.entries))
      setOutfitCount(data.count)
    }

    setLoading(false)
  }

  useEffect(() => {
    const handleRemove = (id: number) => {
      setOutfitsList((prevList: Array<Outfit.Outfit>) =>
        prevList.filter((i) => i.id !== id)
      )
      setOutfitCount((prevCount) => prevCount - 1)
    }

    emitter.on(DeleteFavoriteOutfitEvent, handleRemove)

    return () => {
      emitter.off(DeleteFavoriteOutfitEvent, handleRemove)
    }
  }, [])

  return (
    <section className='container py-4 2xl:py-8'>
      <Typography
        variant='paragraph'
        className='pb-4 text-left text-2xl font-semibold md:pb-6'
      >
        {d('outfit_card.name')}
      </Typography>
      <Typography
        variant='paragraph'
        className='py-4 text-left text-sm font-normal text-secondary md:py-6'
      >
        {outfitCount} {d('saved_items')}
      </Typography>
      <div className='w-full'>
        <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
          {outfitsList.map((outfit: Outfit.Outfit) => (
            <OutfitCard
              key={outfit.id}
              id={outfit.id}
              mediaUrl={outfit.media?.imageUrl}
              mediaAlt={outfit.generatedDescription ?? outfit.name}
              mediaBucket={outfit.media?.bucket}
              mediaKey={outfit.media?.key}
              title={outfit.name}
              slug={outfit.slug}
              className='max-w-full'
              products={outfit.products}
              badges={outfit?.badges}
              favorite={outfit.favorite}
            />
          ))}
        </div>
        {outfitsList.length < outfitCount && (
          <Button
            loading={loading}
            variant='outlined'
            className='mx-auto mt-6 border border-secondary leading-[150%] text-primary'
            onClick={handleShowMore}
          >
            {d('show_more_btn')}
          </Button>
        )}
      </div>
    </section>
  )
}
