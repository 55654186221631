'use client'

import { useEffect, useState } from 'react'
import Icon from '@/components/Icon'
import { searchRequest } from '@/actions/search'
import { IconButton } from '@material-tailwind/react'
import IconSvg from '@/components/IconSvg'
import SearchInput from '@/components/SearchInput'
import { useDebounce, useEventListener } from '@/hooks'
import { HeaderComponent, Search } from '@/types'
import SearchResult from './SearchResult'
import Suggestion from './Suggestion'
import {
  GetLastSearchTerm,
  GetSessionId,
  GetLastSearchUid,
  SetSearchUid,
} from '@/utils/storage/helpers'

type Props = {
  searchSuggestion: HeaderComponent.Search
  className?: string
}

export default function AjaxSearch({
  searchSuggestion,
  className = '',
}: Props) {
  const [searchResult, setSearchResult] = useState<Array<Search.SearchEntry>>(
    []
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const searchTerm = useDebounce<string>(searchValue)

  const searchContentClassName = 'ajax-search-content'
  const mobileSearchPopupCloseClassName = 'ajax-search-popup-close'

  const doSearch = async (searchVal: string) => {
    setLoading(true)

    try {
      const { data, success } = await searchRequest(
        searchVal,
        GetSessionId(),
        window.location.pathname,
        GetLastSearchUid(),
        GetLastSearchTerm(searchVal)
      )

      if (success) {
        if (data.uniqueId) {
          SetSearchUid(data.uniqueId)
        }
        if (data.data.length) {
          setSearchResult(data.data)
          setLoading(false)
          return
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      /* empty */
    }

    setSearchResult([])
    setLoading(false)
  }

  const changeSearchValue = (value: string) => {
    setLoading(value?.length > 2)
    setSearchValue(value ?? '')
  }

  const handleWindowClick = (e: WindowEventMap['click']) => {
    const target = e.target as HTMLElement
    if (
      (target.classList.contains(searchContentClassName) ||
        target.closest('.' + searchContentClassName) ||
        loading) &&
      !target.classList.contains(mobileSearchPopupCloseClassName) &&
      !target.closest('.' + mobileSearchPopupCloseClassName)
    ) {
      setIsOpenPopup(true)
      return
    }
    setIsOpenPopup(false)
  }

  useEventListener<'click'>('click', handleWindowClick)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    searchTerm?.length > 2 ? doSearch(searchTerm) : setSearchResult([])
  }, [searchTerm])

  const Content = () => {
    if (loading) {
      return (
        <div className='items-center p-5 text-center'>
          <IconSvg className='inline-block h-6 w-6' icon={'loading'} />
        </div>
      )
    }

    if (searchValue.length > 2) {
      return <SearchResult searchResult={searchResult} />
    }

    return (
      <Suggestion
        searchSuggestion={searchSuggestion}
        onClickSuggestion={changeSearchValue}
      />
    )
  }

  return (
    <div
      className={`${searchContentClassName} ${className} ${
        isOpenPopup
          ? 'fixed left-0 top-0 z-50 h-full w-full overflow-y-auto bg-white p-4 md:relative md:overflow-y-visible md:p-0'
          : 'relative'
      }`}
    >
      <div className='flex'>
        <IconButton
          className={`${!isOpenPopup && 'hidden'} ${mobileSearchPopupCloseClassName} h-11 md:hidden`}
          size='lg'
          variant='text'
          color='black'
        >
          <Icon icon='arrow-left' size='13px' className='block' />
        </IconButton>
        <div className='w-full'>
          <SearchInput
            onChange={(value: string) => {
              changeSearchValue(value)
            }}
            value={searchValue}
          />
        </div>
      </div>
      <div
        className={`z-50 w-full border-light-silver-color bg-white px-0 py-5 md:absolute md:left-[1.5%] md:top-full md:mt-1 md:w-[97%] md:border md:px-5 ${
          !isOpenPopup ? 'hidden' : ''
        }`}
      >
        <Content />
      </div>
    </div>
  )
}
