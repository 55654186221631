import { HeaderComponent } from '@/types'
import { MenuItem, Typography } from '@material-tailwind/react'
import Image from '@/components/Image'
import Link from '@/components/Link'
import Icon from '@/components/Icon'
import { useDictionary } from '@/providers'

type Props = {
  menuItem: HeaderComponent.MenuItem
}

export default function NavChildren({ menuItem }: Props) {
  const d = useDictionary('header')

  return (
    <div>
      {(menuItem.image?.url && (
        <Image
          className='h-24 w-full object-cover object-center'
          src={menuItem.image?.url}
          alt={menuItem.label}
          height={100}
          width={100}
        />
      )) || (
        <div className='flex h-24 w-full bg-body-bg-secondary'>
          <Icon
            size='30px'
            icon='diamond'
            className='m-auto text-border-color'
          />
        </div>
      )}
      <div className='px-4'>
        {menuItem.advancedMenu.map((column, key) => (
          <div key={key}>
            {column.headerTitle && (
              <Typography
                as='p'
                variant='h3'
                className='mt-3 font-medium text-black'
              >
                {column.headerTitle}
              </Typography>
            )}
            <div
              className={`mt-2 lg:mt-5 ${
                column.showMode === 'bordered'
                  ? 'grid grid-cols-2 gap-2 lg:grid-cols-3'
                  : ''
              }`}
            >
              {column.items.map((item, index) => {
                return (
                  <>
                    {key === 0 && index === 0 && (
                      <Link
                        href={menuItem.url}
                        key='nav-children-all'
                        className={`focus-visible:outline-none ${
                          column.showMode === 'bordered'
                            ? 'content-center border border-gray-400'
                            : ''
                        }`}
                      >
                        <MenuItem
                          className={`items-center gap-3 rounded-lg ${
                            column.showMode === 'bordered'
                              ? 'py-8 text-center'
                              : ''
                          }`}
                        >
                          <div className='text-base font-normal text-black'>
                            {d('show_all_submenus_mobile')}
                          </div>
                        </MenuItem>
                      </Link>
                    )}
                    <Link
                      href={item.link}
                      key={`nav-children-${key}${index}`}
                      className={`focus-visible:outline-none ${
                        column.showMode === 'bordered'
                          ? 'content-center border border-gray-400'
                          : ''
                      }`}
                    >
                      <MenuItem
                        className={`items-center gap-3 rounded-lg ${
                          column.showMode === 'bordered'
                            ? 'py-8 text-center'
                            : ''
                        }`}
                      >
                        <div className='text-base font-normal text-black'>
                          {item.label}
                        </div>
                      </MenuItem>
                    </Link>
                  </>
                )
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
