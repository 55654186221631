import Link from '@/components/Link'
import { Footer as FooterConfig } from '@/types/Components/Sitemap'

export function SitemapFooter({ copyrightText, links }: FooterConfig) {
  return (
    <div className='mt-6 flex flex-row bg-gray-200 py-5 font-medium'>
      <div className='basis-1/2'>{copyrightText}</div>
      <div className='basis-1/2'>
        {links.map((item, i) => (
          <span key={i}>
            {i !== 0 && <span className='px-1 text-sm font-normal'>/</span>}
            <Link href={item.url}>{item.name}</Link>
          </span>
        ))}
      </div>
    </div>
  )
}
