'use client'

import React, { useState } from 'react'
import { Typography, Button } from '@material-tailwind/react'
import {
  editProfileInfo,
  changePassword,
  deleteProfile,
} from '@/actions/profile'
import { useDictionary } from '@/providers'
import Alert from '@/components/Alert'
import ProfileImageWithName from '@/components/Profile/ProfileImageWithName'
import Dialog from '@/components/Dialog'
import EditProfileForm from '@/components/Profile/EditProfileForm'
import EditPasswordForm from '@/components/Profile/EditPasswordForm'
import { signOut } from 'next-auth/react'

type Props = {
  firstName: string
  lastName: string
  email: string
  profileImage: string
  settingNewProfileImage: (picture: string) => void
  settingFirstName: (name: string) => void
  settingLastName: (name: string) => void
  provider: number
}

type EditProfileParams = {
  firstName: string
  lastName: string
}

type EditPasswordParams = {
  password: string
  newPassword: string
  confirmNewPassword: string
}

export default function Settings({
  firstName,
  lastName,
  email,
  profileImage,
  settingNewProfileImage,
  settingFirstName,
  settingLastName,
  provider,
}: Props) {
  const d = useDictionary('profile.settings')
  const [editProfileLoading, setEditProfileLoading] = useState<boolean>(false)
  const [editPasswordLoading, setEditPasswordLoading] = useState<boolean>(false)
  const [loadingDeleteAccount, setLoadingDeleteAccount] =
    useState<boolean>(false)
  const [successName, setSuccessName] = useState<boolean>(false)
  const [successPass, setSuccessPass] = useState<boolean>(false)
  const [successDelete, setSuccessDelete] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [editProfileErrors, setEditProfileErrors] = useState<string>('')
  const [editPasswordErrors, setEditPasswordErrors] = useState<string>('')
  const [errorsDelete, setErrorsDelete] = useState<string>('')

  const internalProviderCode = 1
  const eraseTime = 6000

  const deleteAccount = async () => {
    setLoadingDeleteAccount(true)
    const { data, success } = await deleteProfile()
    setLoadingDeleteAccount(false)
    if (success) {
      setSuccessDelete(true)
      setTimeout(() => {
        signOut({ callbackUrl: '/sign-in' })
      }, 1000)
      setErrorsDelete('')
      return
    }

    setErrorsDelete(data.message)
    return
  }

  const editProfile = async (params: EditProfileParams) => {
    setEditProfileLoading(true)
    const { data, success } = await editProfileInfo(params)
    setEditProfileLoading(false)
    if (success) {
      setSuccessName(true)
      settingFirstName(data.firstName)
      settingLastName(data.lastName)
      setEditProfileErrors('')
      setTimeout(() => {
        setSuccessName(false)
      }, eraseTime)
      return
    }

    setEditProfileErrors(data.errors[0])
    return
  }

  const editPassword = async (params: EditPasswordParams) => {
    setEditPasswordLoading(true)
    const { data, success } = await changePassword(params)
    setEditPasswordLoading(false)
    if (success) {
      setSuccessPass(true)
      setTimeout(() => {
        setSuccessPass(false)
      }, eraseTime)
      setEditPasswordErrors('')
      return
    }

    setEditPasswordErrors(data.errors[0])
    return
  }

  const ConfirmationText = ({
    title,
    description,
  }: {
    title: string
    description: string
  }) => {
    return (
      <Alert state='success' className='mb-6'>
        <Typography variant='paragraph' className='text-lg font-medium'>
          {title}
        </Typography>
        <Typography variant='paragraph' className='mt-2 text-sm font-normal'>
          {description}
        </Typography>
      </Alert>
    )
  }

  return (
    <div className='px-4'>
      <Typography
        as='p'
        variant='h5'
        className='mb-6 text-left font-semibold text-primary'
      >
        {d('title')}
      </Typography>
      <Typography
        variant='paragraph'
        className='mb-16 text-left text-base font-normal'
      >
        {d('description')}
      </Typography>
      <ProfileImageWithName
        firstName={firstName}
        lastName={lastName}
        profileImage={profileImage}
        classNameTitle='text-left'
        settingNewProfileImage={(_picture) => settingNewProfileImage(_picture)}
      />
      <div className='w-full lg:w-1/2'>
        <EditProfileForm
          firstName={firstName}
          lastName={lastName}
          email={email}
          formFunction={editProfile}
          loading={editProfileLoading}
          error={editProfileErrors}
        />
        {successName && (
          <ConfirmationText
            title={d('success_title')}
            description={d('success_description')}
          />
        )}
        {provider === internalProviderCode && (
          <EditPasswordForm
            formFunction={editPassword}
            loading={editPasswordLoading}
            error={editPasswordErrors}
          />
        )}
        {successPass && (
          <ConfirmationText
            title={d('success_pass_title')}
            description={d('success_pass_description')}
          />
        )}
      </div>
      <div className='mb-2 mt-16'>
        <Typography
          variant='paragraph'
          className='mb-4 text-left text-base font-normal'
        >
          {d('delete_account_text')}
        </Typography>
        <Button
          aria-label={d('delete_account_button')}
          disabled={loadingDeleteAccount}
          loading={loadingDeleteAccount}
          onClick={() => setOpen(true)}
          className='flex px-6 py-2 text-base font-medium capitalize text-tertiary-red shadow-none'
        >
          {d('delete_account_button')}
        </Button>
        <Dialog
          open={open}
          handleOpen={() => setOpen(!open)}
          title={d('delete_account_title')}
          description={d('delete_account_text')}
          confirmButtonLabel={d('delete_account_button')}
          cancelButtonLabel={d('cancel_button')}
          deleteAccount={deleteAccount}
          confirmationText={d('confirm_deleted_text')}
          loading={loadingDeleteAccount}
          success={successDelete}
          errors={errorsDelete}
        />
      </div>
    </div>
  )
}
