import { forwardRef } from 'react'
import {
  type AlertProps,
  Alert as TailwindAlert,
} from '@material-tailwind/react'
import type { color } from '@material-tailwind/react/types/components/alert'
import Icon from '@/components/Icon'

export type AlertState = 'success' | 'error' | 'warning' | 'info'

const icons: { [state in AlertState]: string } = {
  success: 'check-circle',
  error: 'forbidden',
  warning: 'warning',
  info: 'info',
}

const colors: { [state in AlertState]: color } = {
  success: 'green',
  error: 'red',
  warning: 'amber',
  info: 'blue',
}

interface Props extends AlertProps {
  state: AlertState
}

export default forwardRef<HTMLDivElement, Props>(function Image(
  { state, children, ...props },
  ref
) {
  return (
    <TailwindAlert
      ref={ref}
      className='text-left leading-6'
      icon={<Icon icon={icons[state]} className='block' size='24px' />}
      color={colors[state]}
      {...props}
    >
      {children}
    </TailwindAlert>
  )
})
