import Icon from '@/components/Icon'
import Link from '@/components/Link'
import { Button, Typography } from '@material-tailwind/react'
import { BannerTextLinksComponent } from '@/types'

type Props = {
  text: string
  image: BannerTextLinksComponent.DeviceDepended
  urls: Array<BannerTextLinksComponent.Url>
  className?: string
}

export default function BannerTextLinks({
  text,
  image,
  urls = [],
  className = '',
}: Props) {
  if (!urls.length) {
    return <></>
  }

  return (
    <section className={`relative ${className}`} style={{ height: '350px' }}>
      <picture>
        <source media='(min-width: 1280px)' srcSet={image.l.image.path} />
        <source media='(min-width: 768px)' srcSet={image.m.image.path} />
        <img
          src={image.s.image.path}
          className='h-full w-full object-cover'
          alt={text}
        />
      </picture>
      <div className='container absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
        <Typography
          variant='h1'
          className='text-left text-4xl font-bold text-white md:text-5xl xl:text-6xl'
        >
          {text}
        </Typography>
        {urls.length > 0 && (
          <div className='mt-4 flex flex-wrap items-center justify-items-start gap-2.5 md:mt-5 md:w-4/5 lg:w-3/5 xl:mt-6'>
            {urls.map((item, key) => (
              <Link
                key={key}
                className='block'
                href={item.url}
                target={item.target}
              >
                <Button
                  className='flex flex-nowrap items-center justify-between gap-x-2 px-4 py-1.5 text-lg font-medium'
                  aria-label={item.label}
                >
                  {item.label}
                  <Icon size='13px' icon='arrow-right' />
                </Button>
              </Link>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}
