import { useDictionary } from '@/providers'
import { Button, IconButton } from '@material-tailwind/react'
import Icon from '@/components/Icon'
import { Filters } from '@/types'
import React from 'react'

type Props = {
  selectedItems: Record<string, any>
  onFilter: Filters.onFilter
  onResetAllFilter: Filters.onResetAllFilter
  onResetFilter: Filters.onResetFilter
  mobile?: boolean
  group?: Filters.Group
}

export default function SelectedFilters({
  onFilter,
  onResetFilter,
  onResetAllFilter,
  selectedItems,
  mobile = false,
  group,
}: Props) {
  const d = useDictionary('filters')

  const handleResetFilter = (name: string, slug: string | null) => {
    if (slug) {
      const newSelected = selectedItems[name].selected?.filter(
        (i: Filters.FilterItem) => {
          return i.slug !== slug
        }
      )

      if (newSelected?.length) {
        onFilter(
          name,
          {
            ...selectedItems[name],
            selected: newSelected,
          },
          mobile,
          group
        )
      } else {
        onResetFilter(name, !mobile, group)
      }
    } else {
      onResetFilter(name, !mobile, group)
    }
  }

  const selectedItem = (name: string, title: string, slug: string | null) => {
    return (
      <div
        key={`${name}-${slug || title}`}
        className={`flex items-center justify-center gap-1 border bg-light-cyan-color px-2 py-1 text-sm font-normal capitalize text-primary !shadow-none`}
      >
        {title}
        <IconButton
          aria-label={d('filter_mobile_btn_remove')}
          size='sm'
          color='white'
          onClick={() => {
            handleResetFilter(name, slug)
          }}
          ripple={false}
          className='h-4 max-h-none w-4 max-w-none rounded-none bg-transparent !shadow-none'
        >
          <Icon size='10px' icon='cross' className='block' />
        </IconButton>
      </div>
    )
  }

  const renderContent = (
    <div className='flex flex-row flex-wrap gap-1'>
      {Object.keys(selectedItems)?.map((item) => {
        if (Array.isArray(selectedItems[item].selected)) {
          return selectedItems[item]?.selected.map((el: Filters.FilterItem) =>
            selectedItem(item, el?.value || el?.title, el?.slug)
          )
        }

        if (item === Filters.Fields.hasPrice) {
          return selectedItem(
            item,
            `${selectedItems[item]?.selected?.minPrice ? `${selectedItems[item]?.selected?.minPrice} - ` : '0 - '}${selectedItems[item]?.selected.maxPrice}`,
            null
          )
        }

        if (!selectedItems[item]?.title) {
          return
        }

        return selectedItem(item, selectedItems[item]?.title, null)
      })}
      {!mobile && (
        <Button
          color='white'
          variant='filled'
          onClick={() => {
            onResetAllFilter(group)
          }}
          className='ml-1.5 flex items-center justify-center border-l-2 px-2 py-1 text-sm font-normal capitalize text-primary !shadow-none'
          aria-label={d('filter_mobile_btn_reset_all_aria_label')}
        >
          <Icon size='13px' icon='reset' className='mr-2.5 block' />
          {d('filter_mobile_btn_reset_all')}
        </Button>
      )}
    </div>
  )

  if (mobile) {
    return (
      <div className='mb-4 mt-2 flex items-center justify-between'>
        {renderContent}
      </div>
    )
  }

  return <div className='mt-4 flex flex-col gap-1'>{renderContent}</div>
}
