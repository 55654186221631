'use client'

import { useState, MouseEvent } from 'react'
import { IconButton, Button, Tooltip } from '@material-tailwind/react'
import Icon from '@/components/Icon'
import IconSvg from '@/components/IconSvg'
import { useDictionary } from '@/providers'
import { FAVORITE_OUTFIT_CODE, FAVORITE_PRODUCT_CODE } from '@/utils/api'
import { addFavorite, deleteFavorite } from '@/actions/favorite'

type Props = {
  id: number
  isIconOnly?: boolean
  isChecked?: boolean
  type: 'outfit' | 'product' | 'brand' | 'person'
  className?: string
  iconSize?: string
  onDelete?: () => void
}

export default function WishlistButton({
  id,
  type,
  isIconOnly = true,
  className = '',
  iconSize = '14px',
  isChecked = false,
  onDelete = () => {},
}: Props) {
  const d = useDictionary('favorite')
  const g = useDictionary()
  const [checked, setChecked] = useState<boolean>(isChecked)
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<Array<string>>([])

  const getTypeCode = (): number => {
    if (type === 'product') {
      return FAVORITE_PRODUCT_CODE
    }
    if (type === 'outfit') {
      return FAVORITE_OUTFIT_CODE
    }

    return 0
  }

  const add = async (typeCode: number) => {
    const { data, success } = await addFavorite({
      type: typeCode,
      entry: id,
    })
    if (success) {
      setChecked(true)
      return
    }
    const _errors = data.errors || []
    if (data.code === 401) _errors.push(d('add_to_list'))
    setErrors(_errors)
    setTimeout(() => {
      setErrors([])
    }, 3000)
  }

  const remove = async (typeCode: number) => {
    const { data, success } = await deleteFavorite({
      type: typeCode,
      entry: id,
    })
    if (success) {
      setChecked(false)
      onDelete()
      return
    }
    const _errors = data.errors || []
    setErrors(_errors)
    setTimeout(() => {
      setErrors([])
    }, 3000)
  }

  const onClick = async (event: MouseEvent) => {
    event.preventDefault()
    const typeCode = getTypeCode()

    if (typeCode === 0) {
      return
    }

    setLoading(true)
    await (checked ? remove : add)(typeCode)
    setLoading(false)
  }

  const LoadingIcon = () => (
    <IconSvg
      style={{ width: `${iconSize}`, height: `${iconSize}` }}
      icon='loading'
    />
  )

  const TooltipContent = () => {
    return (
      <ul className='text-left text-sm font-normal text-tertiary-red'>
        {errors.map((error, key) => (
          <li key={key}>{error}</li>
        ))}
      </ul>
    )
  }

  return (
    <>
      {isIconOnly ? (
        <Tooltip
          open={errors.length !== 0}
          placement='bottom'
          content={errors.length === 0 ? '' : <TooltipContent />}
          className='rounded-none border border-border-color bg-white'
        >
          <IconButton
            aria-label={d('wishlist_btn')}
            size='sm'
            color='white'
            onClick={onClick}
            ripple={false}
            className={`!absolute right-3.5 top-2 z-20 h-6 max-h-none w-6 max-w-none rounded-full ${className}`}
          >
            {loading ? (
              <LoadingIcon />
            ) : (
              <Icon
                className='mt-px block'
                icon={checked ? 'heart' : 'heart2'}
                size={iconSize!}
              />
            )}
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          aria-label={g('follow_btn_aria_label')}
          size='sm'
          color='black'
          variant='outlined'
          onClick={onClick}
          className={`font-base flex h-8 items-center justify-center gap-x-1 border border-solid border-light-gray-color px-2 py-1.5 font-medium leading-[150%] ${className}`}
        >
          <span>{checked ? g('unfollow_btn') : g('follow_btn')}</span>
          {loading && <LoadingIcon />}
        </Button>
      )}
    </>
  )
}
