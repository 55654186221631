'use client'

import React, {
  useState,
  CSSProperties,
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
} from 'react'
import Image from '@/components/Image'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from '@material-tailwind/react'
import { fetchSimilarProducts } from '@/utils/actions/product'
import Link from '@/components/Link'
import WishlistButton from '@/components/WishlistButton'
import { usePage } from '@/providers'
import SimilarProducts from '@/components/SimilarProducts'
import ProductVariantSlider from '@/components/ProductVariantSlider'
import { Badge, Outfit, Product } from '@/types'
import emitter, { DeleteFavoriteOutfitEvent } from '@/utils/events'
import { Badges } from '@/components/Badges'
import htmlToText from '@/utils/html-to-text'
import { usePathname } from 'next/navigation'
import {
  GetComponentTrackingSpecificData,
  NG_WAS_COMBINATION_OUTFIT_PRODUCT_BOX,
  NG_WAS_COMBINATION_OUTFIT_PRODUCT_SIMILARITY_BOX,
  NG_WAS_OUTFIT_DETAIL_SIMILAR_PRODUCT_BOX,
  NG_WAS_OUTFIT_DETAIL_SIMILAR_PRODUCT_SIMILARITY_BOX,
  NG_WAS_OUTFIT_LISTING_PRODUCT_BOX,
  NG_WAS_OUTFIT_LISTING_PRODUCT_SIMILARITY_BOX,
} from '@/components/NgTracker'

export type Props = {
  id: number
  slug: string
  title: string
  mediaUrl: string
  mediaAlt: string
  mediaBucket: string
  mediaKey: string
  products?: Array<Product.Product>
  badges?: Array<Badge.Badge>
  className?: string
  style?: CSSProperties
  subType?: number
  favorite?: boolean
  imageProps?: object
  urlRef?: boolean
}

export default function OutfitCard({
  id,
  slug,
  title,
  mediaAlt,
  mediaUrl,
  mediaBucket,
  mediaKey,
  subType,
  products,
  badges = [],
  className = '',
  style = {},
  favorite = false,
  imageProps = {},
  urlRef = false,
}: Props) {
  const { pages } = usePage()

  const [similarProducts, setSimilarProducts] =
    useState<Product.SimilarProducts | null>(null)
  const [activeId, setActiveId] = useState<number>(0)
  const [inspirationOutfit, setInspirationOutfit] =
    useState<Outfit.InspirationOutfit | null>(null)
  const [loading, setLoading] = useState(false)

  const pathname = usePathname()
  const url = `/${pages.outfit}/${slug}${urlRef ? `?ref=${pathname.slice(1)}` : ''}`

  const onProductClick = (
    event: ReactMouseEvent<HTMLElement> | ReactTouchEvent<HTMLElement>,
    productId: number,
    productName: string,
    productLink: string
  ) => {
    if (subType === Outfit.InspirationOutfitType) {
      event.preventDefault()
    }

    setInspirationOutfit(
      subType === Outfit.InspirationOutfitType
        ? {
            name: productName,
            link: productLink,
          }
        : null
    )

    getSimilarProducts(productId)
  }

  const getSimilarProducts = async (productId: number) => {
    let inspirationParams
    setLoading(true)
    setActiveId(productId)

    const filteredProducts = products?.filter(
      (product) => product.id === productId
    )

    if (filteredProducts && subType === Outfit.InspirationOutfitType) {
      inspirationParams = {
        subType: filteredProducts[0]?.subType,
        tagKey: filteredProducts[0]?.media?.key,
        propertyValues: filteredProducts[0]?.propertyValues,
      }
    }

    try {
      const { data } = await fetchSimilarProducts(
        productId,
        undefined,
        undefined,
        inspirationParams
      )
      setSimilarProducts(data)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      /* empty */
    }
    setLoading(false)
  }

  const getProductVariantsWas = (path: string): string => {
    switch (true) {
      case path.startsWith(`/${pages.outfit}/`):
        return NG_WAS_OUTFIT_DETAIL_SIMILAR_PRODUCT_BOX
      case path.startsWith(`/${pages.outfits}`):
        return NG_WAS_OUTFIT_LISTING_PRODUCT_BOX
      case path.startsWith(`/${pages.combinations}`):
        return NG_WAS_COMBINATION_OUTFIT_PRODUCT_BOX
    }

    return ''
  }

  const getSimilarWas = (path: string): string => {
    switch (true) {
      case path.startsWith(`/${pages.outfit}/`):
        return NG_WAS_OUTFIT_DETAIL_SIMILAR_PRODUCT_SIMILARITY_BOX
      case path.startsWith(`/${pages.outfits}`):
        return NG_WAS_OUTFIT_LISTING_PRODUCT_SIMILARITY_BOX
      case path.startsWith(`/${pages.combinations}`):
        return NG_WAS_COMBINATION_OUTFIT_PRODUCT_SIMILARITY_BOX
    }

    return ''
  }

  const productVariantsSpecificData = GetComponentTrackingSpecificData(
    id,
    getProductVariantsWas(pathname)
  )
  const similarSpecificData = GetComponentTrackingSpecificData(
    id,
    getSimilarWas(pathname)
  )

  return (
    <Card
      color='transparent'
      shadow={false}
      className={`w-full max-w-[26rem] rounded-none ${className}`}
      style={style}
    >
      <CardHeader
        floated={false}
        shadow={false}
        color='transparent'
        className='m-0 rounded-none'
      >
        <Link
          href={url}
          className='relative block pt-[100%]'
          title={htmlToText(title)}
          nofollow
          noopener
        >
          <Image
            data-key={mediaKey}
            data-bucket={mediaBucket}
            data-id={id}
            className='outfit-card-image creative-img-box absolute left-0 top-0 size-full object-contain object-center'
            src={mediaUrl}
            alt={htmlToText(mediaAlt)}
            width={330}
            height={330}
            {...imageProps}
          />
          {!!(badges && badges?.length) && <Badges badges={badges} />}
        </Link>
        <WishlistButton
          id={id}
          type='outfit'
          isChecked={favorite}
          onDelete={() => emitter.emit(DeleteFavoriteOutfitEvent, id)}
        />
      </CardHeader>
      <CardBody className='mt-2 p-0'>
        {title && (
          <Typography variant='h3'>
            <Link
              href={url}
              nofollow
              noopener
              title={htmlToText(title)}
              className='line-clamp-[2] block overflow-hidden text-ellipsis px-2 pt-0 text-left text-md font-normal text-primary'
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Typography>
        )}

        {products && products.length > 0 && (
          <div className='px-2 pt-2'>
            <ProductVariantSlider
              variants={products}
              className='card-outfit-variant-slider'
              onClick={onProductClick}
              activeId={activeId}
              isLoading={loading}
              componentTrackingSpecificData={productVariantsSpecificData}
            />
          </div>
        )}
      </CardBody>

      {similarProducts && !loading && (
        <CardFooter className='p-0'>
          <SimilarProducts
            inspiration={inspirationOutfit}
            count={similarProducts.count}
            products={similarProducts.products}
            componentTrackingSpecificData={similarSpecificData}
          />
        </CardFooter>
      )}
    </Card>
  )
}
