import { Typography } from '@material-tailwind/react'

export default function Skeleton() {
  return (
    <div className='mt-2 max-w-full animate-pulse border-t border-t-light-silver-color'>
      <div className='p-2.5'>
        <Typography
          as='p'
          variant='h1'
          className='h-3 w-4/5 rounded-full bg-gray-300'
        >
          &nbsp;
        </Typography>
      </div>
      <div className='px-2'>
        <div className='grid grid-flow-col grid-cols-[repeat(auto-fit,minmax(50px,1fr))] gap-1 overflow-hidden'>
          {[...Array(7)].map((i, index) => (
            <Typography
              key={index}
              className='h-[92px] min-w-[42px] bg-gray-300'
            >
              &nbsp;
            </Typography>
          ))}
        </div>
      </div>
    </div>
  )
}
