import { Filters } from '@/types'
import Color from '@/components/Filters/components/color'

type Props = {
  component: Filters.FilterItemColor
  filter: Filters.SelectedData
  group?: Filters.Group
  onFilter: Filters.onFilter
  onResetFilter: Filters.onResetFilter
  onShowMoreFilters: Filters.onShowMoreFilters
  mobile?: boolean
  vertical?: boolean
}

export default function generate({
  component,
  filter,
  onFilter,
  onResetFilter,
  onShowMoreFilters,
  mobile = false,
  group,
  vertical = false,
}: Props) {
  return (
    <Color
      title={component.title}
      name={component.name}
      items={component.values}
      selected={component.selected}
      mainContentFilter={component.mainContentFilter}
      filter={filter}
      onFilter={onFilter}
      onResetFilter={onResetFilter}
      onShowMoreFilters={onShowMoreFilters}
      urlPosition={component.urlPosition}
      mobile={mobile}
      group={group}
      vertical={vertical}
    />
  )
}
