'use client'

import { useState } from 'react'
import { ProductListingComponent } from '@/types'
import { useDictionary } from '@/providers'
import { Card, CardBody, CardHeader, Button } from '@material-tailwind/react'
import Link from '@/components/Link'
import Image from '@/components/Image'
import Icon from '@/components/Icon'

type Props = {
  array: Array<ProductListingComponent.Category>
  className?: string
}

export default function SuggestedCategories({ array, className = '' }: Props) {
  const d = useDictionary('suggested_categories')
  const [content, setContent] = useState<
    Array<ProductListingComponent.Category>
  >(array.slice(0, 8))
  const [loading, setLoading] = useState<boolean>(false)
  const [offset, setOffset] = useState<number>(2)

  const onShowMore = () => {
    setLoading(true)

    setContent(array.slice(0, 8 * offset))
    setOffset(offset + 1)

    setLoading(false)
  }

  const getItemCard = (
    item: ProductListingComponent.Category,
    index: number
  ) => (
    <Card key={index} className='rounded-none shadow-none md:w-full'>
      <CardHeader
        floated={false}
        className='mx-0 mt-0 flex h-20 flex-row items-center justify-center rounded-none border shadow-none md:h-auto md:w-full md:flex-col md:border-0'
      >
        <Image
          className='h-20 w-20 object-contain object-center p-1 md:h-40 md:w-full md:p-0'
          src={item.image}
          alt={item.title}
          width={160}
          height={160}
          loading={index === 0 ? 'eager' : 'lazy'}
        />
        <Link
          href={item.url}
          className='flex items-center text-base font-semibold capitalize text-primary md:mt-3 md:w-full md:justify-between md:transition-all md:hover:bg-blue-gray-50 md:hover:bg-opacity-80'
        >
          {item.title}
          <Icon className='mx-2 block' size='12px' icon='arrow-right' />
        </Link>
      </CardHeader>
      <CardBody className='px-2 pb-3 pt-2 text-left text-primary md:py-1'>
        <div className='grid grid-cols-2 gap-1 md:grid-cols-1'>
          {item.links.length > 0 &&
            item.links.map((link: ProductListingComponent.Link, i: number) => (
              <Link
                key={i}
                href={link.url}
                className='bg-body-bg-secondary px-4 py-1 text-md font-normal capitalize md:bg-white md:px-2 md:transition-all md:hover:bg-blue-gray-50 md:hover:bg-opacity-80'
              >
                {link.title}
              </Link>
            ))}
        </div>
      </CardBody>
    </Card>
  )

  return (
    <div className='mb-4 w-full'>
      <div
        className={`my-4 grid grid-cols-1 gap-3 md:mx-8 md:grid-cols-3 md:gap-6 lg:mx-10 lg:basis-2/4 lg:grid-cols-4 ${className}`}
      >
        {content.length > 0 &&
          content.map((item: ProductListingComponent.Category, index: number) =>
            getItemCard(item, index)
          )}
      </div>
      {content.length < array.length && (
        <Button
          loading={loading}
          variant='outlined'
          className='mx-auto mt-6 block border border-secondary leading-[150%] text-primary'
          onClick={onShowMore}
        >
          {d('show_more_btn')}
        </Button>
      )}
    </div>
  )
}
