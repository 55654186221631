import { Typography } from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import Accordion from '@/components/Accordion'
import ContactUs from '@/components/ContactUs'
import { ProfileComponent } from '@/types'

type Props = {
  faq: Array<ProfileComponent.FAQ>
}

export default function Help({ faq }: Props) {
  const d = useDictionary('profile.help')

  /* in  <ContactUs /> after having auth check if token is in session then isUser={true} else isUser={false} */
  return (
    <div className='px-4'>
      <Typography
        as='p'
        variant='h5'
        className='mb-6 text-left font-semibold text-primary'
      >
        {d('title')}
      </Typography>
      <Typography
        variant='paragraph'
        className='mb-12 text-left text-base font-normal'
      >
        {d('description')}
      </Typography>
      <Typography
        as='p'
        variant='h5'
        className='mb-6 text-left font-semibold text-primary'
      >
        {d('faq')}
      </Typography>
      <div className='mb-12'>
        {faq?.length > 0 &&
          faq.map((item) => {
            return (
              <Accordion
                key={item.id}
                title={item.question}
                className='mb-3 border border-primary bg-white px-3 text-left'
                headerClassName='text-lg font-semibold py-3 border-b-0'
                bodyClassName='pt-3 border-t border-border-color'
              >
                {item.answer}
              </Accordion>
            )
          })}
      </div>
      <ContactUs isUser={true} />
    </div>
  )
}
