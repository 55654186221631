'use client'

import React, { useState, ChangeEvent } from 'react'
import { Typography, Input, Textarea, Button } from '@material-tailwind/react'
import { sendContactUs } from '@/actions/contact-us'
import { useDictionary } from '@/providers'
import IconSvg from '@/components/IconSvg'
import Alert from '@/components/Alert'

type Props = {
  isUser: boolean
}

export default function ContactUS({ isUser }: Props) {
  const d = useDictionary('contact_us')
  const [subject, setSubject] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[]>([])

  const onChangeSubject = (e: ChangeEvent<HTMLInputElement>) =>
    setSubject(e.target.value)

  const onChangeMessage = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setMessage(e.target.value)

  const sendQuestion = async () => {
    setLoading(true)
    if (!subject || !message) {
      setLoading(false)
      setErrors([d('empty_error')])
      return
    }
    let params = {
      subject,
      message,
    }
    if (!isUser) {
      params = {
        subject,
        message,
        // add name and email if there is no user
      }
    }
    const { data, success } = await sendContactUs(params)

    setLoading(false)
    if (success) {
      setSuccess(success)
      setErrors([])
      return
    }

    setErrors(data.errors)
  }

  const ConfirmationText = () => {
    return (
      <Alert state='success'>
        <Typography variant='paragraph' className='text-lg font-medium'>
          {d('success_title')}
        </Typography>
        <Typography variant='paragraph' className='mt-2 text-sm font-normal'>
          {d('success_description')}
        </Typography>
      </Alert>
    )
  }

  return success ? (
    <ConfirmationText />
  ) : (
    <div>
      <Typography
        as='p'
        variant='h5'
        className='mb-6 text-left font-semibold text-primary'
      >
        {d('title')}
      </Typography>
      <div>
        <Input
          placeholder={d('title_input_placeholder')}
          aria-label={d('title_input_placeholder')}
          onChange={onChangeSubject}
          error={errors.length > 0}
        />
        <Textarea
          variant='outlined'
          onChange={onChangeMessage}
          placeholder={d('message_input_placeholder')}
          aria-label={d('message_input_placeholder')}
          className='mt-3'
          error={errors.length > 0}
        />
        {errors.length > 0 && (
          <ul className='text-left text-sm font-normal text-tertiary-red'>
            {errors.map((error, key) => (
              <li className='mt-1' key={key}>
                {error}
              </li>
            ))}
          </ul>
        )}
        <div className='mt-5 flex justify-end'>
          <Button
            aria-label={d('send_btn')}
            disabled={loading}
            color='black'
            className='flex h-10 flex-nowrap items-center justify-between gap-x-2 px-6 py-3 text-lg font-medium capitalize'
            onClick={sendQuestion}
          >
            {d('send_btn')}
            {loading && (
              <IconSvg className='h-4 w-4 fill-white' icon='loading' />
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}
