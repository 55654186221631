const API_URL = process?.env?.API_URL

const buildUrl = (endpoint: string): string => API_URL + '/api' + endpoint
const buildUrlAdmin = (endpoint: string): string =>
  API_URL + '/admin/api' + endpoint

export const API_CHANNEL_PAGE = buildUrl('/channel-page')
export const API_BRAND = buildUrl('/brand')
export const API_NEWSLETTER_SUBSCRIBE = buildUrl('/newsletter-subscribe')
export const API_SIGN_UP = buildUrl('/register')
export const API_SIGN_IN = buildUrl('/login-token')
export const API_USER_EXIST = buildUrl('/user/exist')
export const API_FORGOT_PASSWORD = buildUrl('/user/forgot-password')
export const API_RESET_PASSWORD = buildUrl('/user/reset-forgotten-password')
export const API_RESEND_VERIFICATION_EMAIL = buildUrl(
  '/user/resend-verification'
)
export const API_RESEND_RESET_PASSWORD_EMAIL = buildUrl(
  '/user/resend-reset-password'
)
export const API_SEARCH = buildUrl('/search')
export const API_SIMILAR_PRODUCTS = buildUrl('/product/similar')
export const API_PRODUCT_OUTFITS = buildUrl('/product/outfits')
export const API_SIMILAR_OUTFITS = buildUrl('/outfit/similar')
export const API_CONTACT_US = buildUrl('/user/contact-us')
export const API_EDIT_PROFILE = buildUrlAdmin('/user/save')
export const API_EDIT_PASSWORD = buildUrlAdmin('/user/reset-password')
export const API_DELETE_PROFILE = buildUrlAdmin('/user/delete')
export const API_LAST_SEEN = buildUrl('/creative/get-by-slugs')
export const API_ADD_FAVORITE = buildUrlAdmin('/user/add-favorite')
export const API_DELETE_FAVORITE = buildUrlAdmin('/user/delete-favorite')
export const API_GET_FAVORITE = buildUrlAdmin('/user/get-favorite')
export const API_GET_COMBINATION_CATEGORIES = buildUrl('/combination/category')
export const API_GET_COMBINATION_CATEGORIES_CHILDREN = buildUrl(
  '/combination/category/children'
)
export const API_GET_FACET = buildUrl('/creative/facet')
