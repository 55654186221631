'use client'

import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
  Input,
  type InputProps,
  IconButton,
  Typography,
  Tooltip,
} from '@material-tailwind/react'
import Icon from '@/components/Icon'
import { useDictionary } from '@/providers'

type Props = InputProps & {
  name: string
  helperText?: string
  showStrength?: boolean
  showStrengthClassName?: string
}

export default function RHFPasswordField({
  name,
  label,
  placeholder,
  variant,
  helperText,
  showStrength = false,
  showStrengthClassName = '',
}: Props) {
  const { control } = useFormContext()
  const [clicked, setClicked] = useState<boolean>(false)
  const d = useDictionary()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const passwordStrengthProps = getPasswordStrengthProps(field.value)
        const color = passwordStrengthProps?.color || ''
        const text = passwordStrengthProps?.text || ''

        return (
          <div>
            <Input
              {...field}
              label={label}
              value={field.value}
              error={!!error}
              type={clicked ? 'text' : 'password'}
              variant={variant}
              placeholder={placeholder}
              icon={
                <IconButton
                  onClick={() => setClicked((prevState) => !prevState)}
                  className='h-8 w-8 bg-transparent p-0 !shadow-none'
                >
                  <Icon
                    className={`block ${error ? 'text-red-500' : ''}`}
                    size='24px'
                    icon='eye-slash'
                  />
                </IconButton>
              }
            />
            {(!!error || helperText) && (
              <Typography
                variant='small'
                color='gray'
                className={`mt-2 text-left font-normal ${error ? 'text-red-500' : ''}`}
              >
                {error ? error?.message : helperText}
              </Typography>
            )}

            {showStrength && (
              <>
                <div className='my-1 flex items-center'>
                  <div className={`mr-2.5 flex-1 border-t-2 ${color}`} />
                  <div className={`mr-2.5 flex-1 border-t-2 ${color}`} />
                  <div className={`flex-1 border-t-2 ${color}`} />
                </div>
                <div
                  className={` ${showStrengthClassName || 'mb-8'} flex items-center justify-end text-xs`}
                >
                  {text}
                  <Tooltip content={d('password_info')} placement='top'>
                    <span className='cursor-pointer'>
                      <Icon className='ml-1 fill-white' icon='info' />
                    </span>
                  </Tooltip>
                </div>
              </>
            )}
          </div>
        )
      }}
    />
  )
}

const getPasswordStrengthProps = (value: string) => {
  if (value === '') {
    return { color: 'border-light-gray-color', text: '' }
  }
  if (value.length < 8) {
    return { color: 'border-tertiary-red', text: 'weak' }
  }
  if (
    value.length >= 8 &&
    (value === value.toLowerCase() || !/\d/.test(value))
  ) {
    return { color: 'border-tertiary-orange', text: 'medium' }
  }
  if (value.length >= 8 && value !== value.toLowerCase() && /\d/.test(value)) {
    return { color: 'border-tertiary-green', text: 'strong' }
  }

  return { color: '', text: '' }
}
