import { Typography } from '@material-tailwind/react'
import ProductVariantSlider from '@/components/ProductVariantSlider'
import { SliderProps } from '@/components/Slider'
import { useDictionary } from '@/providers'
import { Product, Outfit } from '@/types'
import Skeleton from './Skeleton'
import Link from '@/components/Link'
import { TrackingExternalUrlSpecificData } from '@/components/NgTracker'

type SimilarProductsProps = {
  count?: number
  products?: Array<Product.Product>
  loading?: boolean
  sliderProps?: SliderProps
  inspiration?: Outfit.InspirationOutfit | null
  componentTrackingSpecificData?: TrackingExternalUrlSpecificData | undefined
}

export default function SimilarProducts({
  // count,
  products,
  loading = false,
  sliderProps = {},
  inspiration,
  componentTrackingSpecificData = {},
}: SimilarProductsProps) {
  const d = useDictionary('product')
  if (!products?.length && !loading) {
    return
  }

  if (loading) {
    return <Skeleton />
  }

  return (
    <div className='border-t border-t-light-silver-color md:mt-2'>
      <Typography
        variant='paragraph'
        className='px-1 pb-1 pt-2 text-left text-[10px] font-normal leading-[150%] text-secondary md:p-2 md:text-sm'
      >
        {/*<span className='text-tertiary-orange'>({count})</span>*/}
        {d('similar_products_title')}
        {inspiration && (
          <Link
            className='block cursor-pointer hover:underline'
            href={inspiration.link}
            title={inspiration.name}
          >
            {d('similar_products_in') + ' ' + inspiration.name}
          </Link>
        )}
      </Typography>
      <div className='px-2'>
        <ProductVariantSlider
          variants={products!}
          className='card-outfit-similar-product-slider'
          sliderProps={sliderProps!}
          inspiration={inspiration}
          componentTrackingSpecificData={componentTrackingSpecificData}
        />
      </div>
    </div>
  )
}
