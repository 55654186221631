'use client'

import React, { useState } from 'react'
import { Input, Button, Typography } from '@material-tailwind/react'
import { newsletterSubscribe } from '@/actions/newsletter-subscribe'
import { useDictionary } from '@/providers'
import IconSvg from '@/components/IconSvg'
import Alert from '@/components/Alert'

type Props = {
  text: string
}

export default function Newsletter({ text }: Props) {
  const d = useDictionary('newsletter')
  const defaultError = d('invalid_email_message')
  const emailPattern =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/g
  const [value, setValue] = useState<string>('')
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true)
  const [errors, setErrors] = useState<string[]>([defaultError])
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  const sendEmail = async () => {
    if (!value.match(emailPattern)) {
      setIsValidEmail(false)
      setErrors([defaultError])
      return
    }
    setLoading(true)
    const { data, success } = await newsletterSubscribe({ email: value })
    setLoading(false)
    if (success) {
      setSuccess(success)
      setIsValidEmail(true)
      return
    }

    setIsValidEmail(false)
    setErrors(data.errors ?? [])
  }

  const onChange = ({ target }: { target: HTMLInputElement }) => {
    if (!isValidEmail) {
      setIsValidEmail(true)
    }
    setValue(target.value)
  }

  const ConfirmationText = () => {
    return (
      <Alert state='success'>
        <Typography variant='paragraph' className='text-lg font-medium'>
          {d('success_title')}
        </Typography>
        <Typography variant='paragraph' className='mt-2 text-sm font-normal'>
          {d('success_description')}
        </Typography>
      </Alert>
    )
  }

  return (
    <section className='bg-body-bg-secondary py-8 md:py-16'>
      <div className='container flex flex-col items-center justify-between gap-6 md:flex-row'>
        {success ? (
          <ConfirmationText />
        ) : (
          <>
            <Typography
              variant='paragraph'
              className='text-center text-2xl font-semibold md:text-left'
            >
              {text}
            </Typography>
            <div className='relative flex flex-col md:basis-96'>
              <Input
                autoComplete='off'
                error={!isValidEmail}
                type='email'
                placeholder={d('email_label')}
                aria-label={d('email_label')}
                size='md'
                value={value}
                onChange={onChange}
                className='h-12 pr-36'
                containerProps={{
                  className: 'h-14 min-w-0',
                }}
              />
              <Button
                aria-label={d('subscribe_btn')}
                disabled={loading}
                color='black'
                className='!absolute right-1 top-1 flex h-12 flex-nowrap items-center justify-between gap-x-2 px-6 py-3 text-lg font-medium capitalize'
                onClick={sendEmail}
              >
                {d('subscribe_btn')}
                {loading && (
                  <IconSvg className='size-4 fill-white' icon='loading' />
                )}
              </Button>
              {!isValidEmail && (
                <ul className='text-left text-sm font-normal text-tertiary-red md:absolute md:left-0 md:top-full'>
                  {errors.map((error, key) => (
                    <li className='mt-1' key={key}>
                      {error}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  )
}
