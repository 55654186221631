import { Filters } from '@/types'
import filterGenerators from '../generators'

type Props = {
  components: Array<Filters.FilterItem>
  filters: Record<string, any>
  onFilter?: Filters.onFilter
  onResetFilter?: Filters.onResetFilter
  onShowMoreFilters?: Filters.onShowMoreFilters
  className?: string
  group?: Filters.Group
}

export function Vertical({
  components,
  filters,
  onFilter,
  onResetFilter,
  onShowMoreFilters,
  className = '',
  group,
}: Props) {
  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      {components?.map((component, key) => {
        const Generator = filterGenerators[component.type]
        if (!Generator) {
          return null
        }
        return (
          <Generator
            key={key}
            component={component}
            onFilter={onFilter}
            onShowMoreFilters={onShowMoreFilters}
            filter={
              filters?.hasOwnProperty(component?.name)
                ? filters[component?.name]
                : {}
            }
            onResetFilter={onResetFilter}
            group={group}
            vertical
          />
        )
      })}
    </div>
  )
}
