import { ComponentProps, ReactNode, ComponentType } from 'react'

interface Props extends ComponentProps<'svg'> {
  icon: string
  fallback?: ReactNode
}

const svgs = require.context('./icons', false, /\.svg$/)
const Icons: { [key: string]: ComponentType<Props> } = {}

svgs.keys().forEach((path: string) => {
  const key = path.substring(path.lastIndexOf('/') + 1, path.lastIndexOf('.'))
  Icons[key] = svgs(path).default
})

export default function IconSvg({ icon, fallback, ...props }: Props) {
  if (!(icon in Icons)) {
    if (!fallback) {
      return <></>
    }
    return <>{fallback}</>
  }

  const IconComponent = Icons[icon]
  return <IconComponent icon={icon} {...props} />
}
