import React from 'react'
import { Breadcrumbs as MaterialBreadcrumbs } from '@material-tailwind/react'
import { Breadcrumb } from '@/types'
import Icon from '@/components/Icon'
import Link from '@/components/Link'

type Props = {
  breadcrumbs?: Array<Breadcrumb>
  className?: string
}

export default function Breadcrumbs({
  breadcrumbs = [],
  className = '',
}: Props) {
  const length = breadcrumbs.length
  return (
    <MaterialBreadcrumbs
      separator={<Icon className='block' icon='arrow-right' size='10px' />}
      className={className! ?? undefined}
    >
      {breadcrumbs?.map((breadcrumb, index) => (
        <Link
          key={index}
          as='span'
          className={
            index + 1 === length ? 'pointer-events-none opacity-80' : undefined
          }
          title={breadcrumb.title}
          href={breadcrumb.url}
        >
          {breadcrumb.title}
        </Link>
      ))}
    </MaterialBreadcrumbs>
  )
}
