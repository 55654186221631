import { Button } from '@material-tailwind/react'
import Alert from '@/components/Alert'
import { useDictionary } from '@/providers'
import FormProvider, {
  RHFTextField,
  useForm,
  Yup,
  yupResolver,
} from '@/components/HookForm'

type Params = {
  firstName: string
  lastName: string
}

type Props = {
  firstName: string
  lastName: string
  email: string
  loading: boolean
  error: string
  formFunction: (params: Params) => void
}

export default function EditProfileForm({
  firstName,
  lastName,
  email,
  formFunction,
  loading,
  error,
}: Props) {
  const d = useDictionary('profile.settings')

  const EditProfileSchema = Yup.object().shape({
    firstName: Yup.string().required(d('firstName_required')),
    lastName: Yup.string().required(d('lastName_required')),
    email: Yup.string().email(d('email_invalid')),
  })

  const defaultValues = {
    firstName,
    lastName,
    email: '',
  }

  const methods = useForm({
    resolver: yupResolver(EditProfileSchema),
    defaultValues,
  })

  const { handleSubmit } = methods

  const onSubmit = handleSubmit((data) => {
    if (data.firstName !== firstName || data.lastName !== lastName) {
      const params = {
        firstName: data.firstName,
        lastName: data.lastName,
      }
      formFunction(params)
      return
    }
  })

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {error && (
        <Alert state='error' className='mb-4'>
          {error}
        </Alert>
      )}
      <div className='grid gap-4'>
        <RHFTextField
          name='firstName'
          variant='static'
          placeholder={firstName || d('first_name_placeholder')}
          label={d('first_name_label')}
        />
        <RHFTextField
          name='lastName'
          variant='static'
          placeholder={lastName || d('last_name_placeholder')}
          label={d('last_name_label')}
        />

        <RHFTextField
          name='email'
          variant='static'
          placeholder={email || d('email_placeholder')}
          label={d('email_label')}
          disabled
        />
      </div>

      <Button
        fullWidth
        type='submit'
        loading={loading}
        className='mb-2 mt-4 flex w-full items-center justify-center bg-primary p-2 text-lg font-medium capitalize text-white !shadow-none'
      >
        {d('save_changes')}
      </Button>
    </FormProvider>
  )
}
