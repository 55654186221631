import Icon from '@/components/Icon'
import { useDictionary } from '@/providers'

const colors: { [type: number]: string } = {
  1: 'bg-tertiary-red text-white',
  2: 'bg-tertiary-red text-white',
  3: 'bg-tertiary-red text-white',
}

const icons: { [type: number]: string } = {
  3: 'badge-percent',
}

const text: { [type: number]: string } = {
  1: 'most_click',
  2: 'most_click',
  3: 'sale',
}

type BadgeProps = {
  type: number
}

export default function BadgeItem({ type }: BadgeProps) {
  const d = useDictionary('badges')

  return (
    <div
      className={`flex flex-row items-center justify-center gap-1 px-1.5 py-0.5 text-sm ${colors[type]}`}
    >
      {icons[type] && <Icon icon={icons[type]} className='block' size='12px' />}
      <span className='uppercase'>{d(text[type])}</span>
    </div>
  )
}
