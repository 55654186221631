'use client'

import { useState, useEffect } from 'react'
import Link from '@/components/Link'
import Cookies from 'js-cookie'
import { useDictionary } from '@/providers'
import { Drawer, Button } from '@material-tailwind/react'

type Props = {
  href: string
}

export default function CookieConsent({ href }: Props) {
  const d = useDictionary('consent_cookie')
  const [open, setOpen] = useState(true)
  const [consentCookie, setConsentCookie] = useState('')

  const getConsentCookies = () => {
    const cookie = Cookies.get('consent-gdpr') ?? ''
    if (cookie) {
      setConsentCookie(cookie)
      return
    }
    setConsentCookie('')
    return
  }

  useEffect(() => {
    getConsentCookies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = () => {
    Cookies.set('consent-gdpr', 'accepted', { expires: 15 })
    setOpen(false)
  }

  if (consentCookie) {
    return <></>
  }

  return (
    <Drawer
      placement='bottom'
      open={open}
      onClose={() => setOpen(!open)}
      className='!max-h-40 border border-primary p-2 shadow-[0px_-10px_10px_5px_black] md:!max-h-32'
      overlay={false}
      dismiss={{
        escapeKey: false,
        referencePress: false,
        outsidePress: false,
      }}
    >
      <div className='m-auto flex h-full items-center justify-between gap-2 border border-primary p-2'>
        <div>
          <div className='pb-2 text-base font-semibold'>{d('title')}</div>
          <div className='text-md font-normal'>{d('description')}</div>
        </div>
        <div className='flex w-1/3 flex-col items-center'>
          <Button
            className='mx-auto mb-4 bg-primary px-2 py-2 text-base font-medium capitalize text-white hover:bg-primary hover:text-white active:bg-primary active:text-white'
            variant='text'
            aria-label={d('accept_button')}
            onClick={handleClick}
          >
            {d('accept_button')}
          </Button>
          <Link
            className='block text-center text-sm text-primary underline'
            href={href}
          >
            {d('data_privacy')}
          </Link>
        </div>
      </div>
    </Drawer>
  )
}
