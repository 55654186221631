import { Properties as PropertiesType } from '@/types'
import Color from './color'
import Size from './size'

type Props = {
  options: Array<PropertiesType.PropertyOptions>
  type: PropertiesType.Types
}

export default function Properties({ options, type }: Props) {
  switch (type) {
    case PropertiesType.Types.COLOR:
      return <Color options={options} />
    case PropertiesType.Types.SIZE:
      return <Size options={options} />
  }
}
