import { Typography } from '@material-tailwind/react'
import { InternalLinkComponent } from '@/types'
import Link from '@/components/Link'

type Props = {
  title: string
  links: Array<InternalLinkComponent.Link>
}

export default function InternalLink({ title, links = [] }: Props) {
  if (!links.length) {
    return <></>
  }

  return (
    <div className='container my-12 text-left'>
      <Typography variant='h2' className='mb-5 rounded-full text-black'>
        {title}
      </Typography>
      <div className='grid grid-cols-2 gap-x-3 p-0 md:grid-cols-3 md:gap-x-5'>
        {links?.map((item, key) => (
          <Link
            key={key}
            href={item.url}
            className='p-1 text-sm leading-[150%] text-primary'
          >
            {item.title}
          </Link>
        ))}
      </div>
    </div>
  )
}
