'use client'

import { Page as PageTypes } from '@/types'
import Content from '@/containers/layouts/Page/Content'
import { PageProvider } from '@/providers'

type Props = {
  page: PageTypes.Page
}

export default function Page({ page }: Props) {
  return (
    <PageProvider page={page}>
      <Content page={page} />
    </PageProvider>
  )
}
