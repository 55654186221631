import { Typography } from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import WishlistButton from '@/components/WishlistButton'
import Avatar from '@/components/Avatar'

type Props = {
  code: string
  created: string
  von: string
  userFullName: string
  userAvatarUrl: string
  userId: number
  showCreator: number
}

export function OutfitInfo({
  code,
  created,
  von,
  userFullName,
  userAvatarUrl,
  userId,
  showCreator,
}: Props) {
  const d = useDictionary('outfit_detail')

  return (
    <div className='mt-2.5 flex flex-row items-start justify-between gap-8'>
      <ul className='flex flex-col gap-1 text-left'>
        <li className='text-sm text-primary'>
          <span>{d('name_label')}</span> {code}
        </li>
        <li className='text-sm text-primary'>
          <span>{d('date_label')}</span> {created}
        </li>
        {von && (
          <li className='text-sm text-primary'>
            <span>{d('von_label')}</span> {von}
          </li>
        )}
      </ul>

      {showCreator && (
        <div className='flex flex-col gap-2.5 text-right'>
          {userFullName && (
            <Typography variant='lead' className='text-sm text-primary'>
              {d('created_by')} {userFullName}
            </Typography>
          )}
          <div className='flex flex-row items-center justify-end gap-3'>
            <Avatar url={userAvatarUrl} size='sm' alt='avatar' />
            <WishlistButton id={userId} isIconOnly={false} type='person' />
          </div>
        </div>
      )}
    </div>
  )
}
