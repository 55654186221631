import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Typography,
} from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import Icon from '@/components/Icon'
import Image from '@/components/Image'
import Link from '@/components/Link'
import FakeLink from '@/components/FakeLink'
import { FavoriteComponent } from '@/types'

type Props = {
  name: string
  title: string
  description: string
  emptyDescription: string
  date: string
  headlineImage: string
  headlineImageAlt: string
  emptyUrl?: string
  url?: string
  sliderItems: Array<FavoriteComponent.Item>
  className?: string
}

export default function Favorite({
  name,
  title,
  description,
  emptyUrl = '/',
  url = '/',
  emptyDescription,
  date,
  headlineImage,
  headlineImageAlt,
  sliderItems,
  className = '',
}: Props) {
  const d = useDictionary('favorite')

  const getEmptyCardView = () => {
    return (
      <Card className='h-48 w-full rounded-none border border-border-color md:h-64'>
        <CardBody className='flex h-64 items-center justify-between'>
          <Image
            className='max-h-40 w-1/2 object-contain object-center'
            src={headlineImage}
            alt={headlineImageAlt}
            width={150}
            height={150}
          />
          <div className='ml-4'>
            <Typography
              variant='paragraph'
              className='mb-1 text-left text-lg font-semibold'
            >
              {name}
            </Typography>
            <Typography
              variant='paragraph'
              className='mb-6 text-left text-sm font-normal'
            >
              {emptyDescription}
            </Typography>
            <Link className='block' href={emptyUrl} target='_self'>
              <Button
                aria-label={d('get_inspired_button')}
                className='flex items-center border border-border-color px-8 py-1 text-base font-semibold capitalize text-primary'
              >
                {d('get_inspired_button')}
              </Button>
            </Link>
          </div>
        </CardBody>
      </Card>
    )
  }

  return (
    <div className={`mt-4 w-full ${className}`}>
      {sliderItems.length === 0 ? (
        getEmptyCardView()
      ) : (
        <>
          <div className='mb-4 flex items-center justify-between'>
            <Typography
              variant='paragraph'
              className='text-left text-base font-semibold'
            >
              {name}
            </Typography>
            <Typography
              variant='paragraph'
              className='text-left text-sm font-normal text-secondary'
            >
              {d('updated_at')} {date}
            </Typography>
          </div>
          <FakeLink
            as={Card}
            href={url}
            className='favorite-card cursor-pointer rounded-none'
          >
            <CardBody className='flex items-center justify-between'>
              <div className='mr-4'>
                <Typography
                  variant='paragraph'
                  className='mb-1 text-left text-lg font-semibold'
                >
                  {title}
                </Typography>
                <Typography
                  variant='paragraph'
                  className='mb-6 text-left text-sm font-normal'
                >
                  {description}
                </Typography>
                <Link className='block' href={url} target='_self'>
                  <Button
                    variant='text'
                    aria-label={d('see_all_button')}
                    className='flex items-center px-0 py-1 text-base font-semibold capitalize text-primary'
                  >
                    {d('see_all_button')}
                    <Icon size='12px' icon='arrow-right' className='ml-2.5' />
                  </Button>
                </Link>
              </div>
              <Image
                className='max-h-40 w-1/2 object-contain object-center'
                src={headlineImage}
                alt={headlineImageAlt}
                width={350}
                height={220}
              />
            </CardBody>
            <CardFooter className='grid grid-cols-4 gap-2 overflow-hidden pt-0'>
              {sliderItems?.map((sliderItem, index) => (
                <Image
                  className='card-image size-full h-36 object-cover object-scale-down object-center'
                  src={sliderItem.media?.imageUrl}
                  alt={sliderItem.name}
                  width={350}
                  height={220}
                  key={index}
                />
              ))}
            </CardFooter>
          </FakeLink>
        </>
      )}
    </div>
  )
}
